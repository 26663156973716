import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { withFirebase } from "../../firebase";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { pink } from "@material-ui/core/colors";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
}));

const UserSignUp = ({ firebase, history }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    error: "",
    emailError: "",
    passwordError: "",
    fNameError: "",
    lNameError: "",
    OTPError: "",
  });
  const [signingIn, setSigningIn] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [isOTPSent, setIsOTPsent] = useState(false);
  const [OTP, setOTP] = useState("");

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth && Object.keys(errors).length === 0) {
        history.push("/editor");
      }
    });
  }, []);
  // useEffect(() => {
  //   if (!!firebase.user) {
  //     history.push("/");
  //   }
  // }, []);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    if (firstName.length === 0) {
      setErrors({ fNameError: "Please enter valid first name" });
      return true;
    }

    if (lastName.length === 0) {
      setErrors({ lNameError: "Please enter valid last name" });
      return true;
    }

    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      setErrors({ emailError: "Enter valid email" });
      return true;
    }

    if (password.length < 6) {
      setErrors({
        passwordError: "Password must be at least six characters",
      });
      return true;
    }

    if (password !== confirmPassword) {
      setErrors({ passwordError: "Password do not match." });
      return true;
    }

    if (!isValidPhoneNumber("+" + number)) {
      setErrors({ error: "Please enter a valid number" });
      return true;
    }
    setErrors({});
    return false;
  };

  const handleSignUpButton = async (e) => {
    e.preventDefault();
    let isValid = validateForm();
    if (!isValid) {
      setDisabled(true);
      setSigningIn(true);

      await firebase.captchaGeneraterforPhone();
      const res = firebase.onSignInSubmit(`+${number}`);

      res
        .then((success) => {
          setIsOTPsent(true);
          setDisabled(false);
          setSigningIn(false);
        })
        .catch((error) => {
          setErrors({ error: error.error });
          setIsOTPsent(false);
          setDisabled(false);
          setSigningIn(false);
        });
    }
  };

  const handleOTPVerify = async (e) => {
    e.preventDefault();
    if (OTP.length === 6) {
      setErrors({ OTPError: "" });
      const userRecord = {
        fName: firstName,
        lName: lastName,
        phone: "+" + number,
        email: email,
      };

      setDisabled(true);
      setSigningIn(true);
      const res = firebase.verifyOTP(userRecord, password, OTP.toString());
      res
        .then((success) => {
          firebase.addUserRecord(userRecord);
          history.push("/editor");
          setErrors({});
          setSigningIn(false);
          setDisabled(false);
        })
        .catch((error) => {
          setDisabled(false);
          setSigningIn(false);
          setErrors({ error: error.error });
        });
    } else {
      return setErrors({ OTPError: "Please enter valid OTP" });
    }
  };
  const handleCancleBtn = () => {
    setIsOTPsent(false);
    setOTP("");
  };
  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="loginGrid"
      >
        {!isOTPSent ? (
          <Paper elevation={10} className="loginForm">
            <Grid align="center">
              <Avatar className={classes.pink}>
                <LockOpenOutlinedIcon />
              </Avatar>
              <h1>Sign Up</h1>
            </Grid>
            {errors.error && (
              <Alert variant="filled" severity="error">
                {errors.error}
              </Alert>
            )}
            <div id="sign-up-button"></div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl
                id="signUpName"
                className={clsx(classes.margin, classes.withoutLabel)}
              >
                <TextField
                  error={errors?.fNameError?.length > 0}
                  helperText={errors?.fNameError}
                  autoFocus
                  id="first-name"
                  label="Enter First Name"
                  type="text"
                  variant="outlined"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl
                id="signUpName"
                className={clsx(classes.withoutLabel)}
              >
                <TextField
                  error={errors?.lNameError?.length > 0}
                  helperText={errors?.lNameError}
                  id="second-name"
                  label="Enter Last Name"
                  type="text"
                  variant="outlined"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </FormControl>
            </div>
            <FormControl className={clsx(classes.withoutLabel)} fullWidth>
              <TextField
                error={errors?.emailError?.length > 0}
                helperText={errors?.emailError}
                id="email"
                label="Enter Email"
                type="email"
                variant="outlined"
                placeholder="example@mail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>

            <FormControl className={clsx(classes.withoutLabel)} fullWidth>
              <PhoneInput
                enableSearch
                autocompleteSearch
                country={"in"}
                value={number}
                onChange={setNumber}
                required
              />
            </FormControl>
            <FormControl
              className={clsx(classes.withoutLabel)}
              variant="outlined"
              required
              fullWidth
            >
              <InputLabel htmlFor="password">Password</InputLabel>

              <OutlinedInput
                error={errors?.passwordError?.length > 0}
                id="password"
                placeholder="Enter Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={80}
              />
              {errors?.passwordError && (
                <FormHelperText style={{ color: "red" }}>
                  {errors?.passwordError}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={clsx(classes.withoutLabel)}
              variant="outlined"
              required
              fullWidth
            >
              <InputLabel htmlFor="confirm-password">
                Confirm Password
              </InputLabel>

              <OutlinedInput
                error={errors?.passwordError?.length > 0}
                id="confirm-password"
                placeholder="Enter Password"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyPress={(event) =>
                  event.key === "Enter" && handleSignUpButton()
                }
                labelWidth={150}
              />
              {errors?.passwordError && (
                <FormHelperText style={{ color: "red" }}>
                  {errors?.passwordError}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl className={clsx(classes.withoutLabel)} fullWidth>
              {signingIn ? (
                <Spinner color="success" />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                  onClick={handleSignUpButton}
                >
                  Create Account
                </Button>
              )}
            </FormControl>

            <Typography className={clsx(classes.withoutLabel)} align="right">
              Already have an Account ? <Link to="/login">Login</Link>
            </Typography>
          </Paper>
        ) : (
          <Paper elevation={10} className="loginForm">
            <Grid align="center">
              <Avatar className={classes.pink}>
                <LockOpenOutlinedIcon />
              </Avatar>
              <h2>Verify your phone number</h2>
              {errors.error && (
                <Alert
                  variant="filled"
                  severity="error"
                  style={{ marginBottom: "0.5rem" }}
                >
                  {errors.error}
                </Alert>
              )}
            </Grid>
            <h5>Enter the 6-digit code we sent to</h5>
            <h6 style={{ color: "orange" }}>{`+${number}`}</h6>
            <FormControl
              id="signUpName"
              fullWidth
              className={clsx(classes.margin, classes.withoutLabel)}
            >
              <TextField
                error={errors?.OTPError?.length > 0}
                helperText={errors?.OTPError}
                autoFocus
                id="otp"
                label="6-digit code"
                type="text"
                variant="outlined"
                placeholder="XXXXXX"
                value={OTP}
                onChange={(e) => setOTP(e.target.value)}
                required
              />
            </FormControl>
            <FormControl
              className={clsx(classes.withoutLabel)}
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                type="submit"
                variant="outlined"
                color="primary"
                disabled={false}
                onClick={() => handleCancleBtn()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={handleOTPVerify}
              >
                {signingIn ? "Verifying..." : "Verify"}
              </Button>
            </FormControl>
          </Paper>
        )}
      </Grid>
    </div>
  );
};
const Component = withFirebase(UserSignUp);

export default withRouter(Component);
