import {
  NAVBAR_EDIT_UPLOAD_SUCCESS,
  NAVBAR_EDIT_LIST_SUCCESS,
  NAVBAR_EDIT_DELETE_SUCCESS,
  NAVBAR_EDIT_MODIFY_SUCCESS,
  GET_NAVBAREDITS_LIST,
  NAVEDIT_LOADING_START,
  NAVEDIT_LOADING_ERROR,
} from "./actionTypes";
import { APIUrls } from "../Utils/urls";
import axios from "axios";



// get asset list meta data of user
export function getNavbarEditsList() {
  return (dispatch) => {
    dispatch(loadingStart());
    dispatch(getEditsList())
  };
}
//add assets
// export function DeleteDataFromNavbar(res) {
//   console.log(res);
//   return (dispatch) => {
//     dispatch(loadingStart());
//     dispatch(DeleteData(res));
//   };
// }

export function DeleteDataFromNavbar(id, NavbareditsId) {
  console.log('id = ', id);
  console.log("navbarid  =  ", NavbareditsId)
  return (dispatch) => {
    const url = APIUrls.DeleteDataFromNavbar(id);
    dispatch(loadingStart());
    let data = {
      NavbareditsId: NavbareditsId,
    }
    axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }, data,
    })
      .then((res) => {
        dispatch(DeleteData(NavbareditsId));
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadingError());
      });
  }
}


export function getNavbarEditsGobalList(id) {
  return (dispatch) => {
    const url = APIUrls.getNavbareditsGlobalList(id);
    dispatch(loadingStart());
    axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        // Authtoken: token,
      },
    })
      .then((res) => {
        console.log(res.data);
        dispatch(NavbarMetaList(res.data.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadingError());
      });
  }
}

export function AddNewDatatoNavbar(id, temp) {
  console.log(temp);
  return (dispatch) => {
    dispatch(loadingStart());
    // const id = "QsWdgIqwonNtWhrO1Qbq";
    const url = APIUrls.createNewNavbarEdit(id);
    console.log(url);
    let body = {
      Id: temp.Id,
      Name: temp.Name,
      Link: temp.Link,
      checkId:temp.checkId,
      level:temp.level,
      indexid:temp.indexid,
    }
    axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        dispatch(AddNewData(res.data.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadingError());
      });
  }
}

// export function ModifyDatatoNavbar(id, newdata) {
//   console.log(newdata);
//   console.log(id);
//   return (dispatch) => {
//     dispatch(loadingStart());
//     dispatch(ModifyData(id, newdata));
//   };
// }


export function ModifyDatatoNavbar(id, navid, newdata) {
  console.log("info#1",id,navid,newdata);
  return (dispatch) => {
    let data = {
      modifyid: navid,
      navdata: newdata,
    }
    const url = APIUrls.ModifyDatatoNavbar(id);
    dispatch(loadingStart());
    axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((res) => {
        dispatch(ModifyData(navid, newdata));
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadingError());
      });
  }
};


// // add assets
// export function addAssets(
//   id,
//   token,
//   file,
//   directory,
//   thumbnail = null,
//   cancel
// ) {
//   return (dispatch) => {
//     const url = APIUrls.getAssetUploadUrl(id);
//     console.log(url);
//     dispatch(loadingStart());
//     const fileMeta = {
//       fileName: file.name,
//       mimeType: file.type,
//     };
//     console.log(fileMeta);

//     axios // to avoid issue in url size, have made a POST request instead of GET
//       .post(url, fileMeta, {
//         // second arguement must be null or something
//         headers: {
//           "Content-Type": "application/json",
//           Authtoken: token,
//           "Access-Control-Allow-Headers": "*"
//         },
//         cancelToken: axios.CancelToken((c) => (cancel.cancel = c)),
//       })
//       .then((res) => {
//         console.log("Response from add assets", res);
//         // backend expects an array of asset, do sending single asset as an array
//         let filesMeta = [
//           {
//             docId: res.data.docId,
//             fileName: file.name,
//             mimeType: file.type,
//             fileSize: file.size,
//           },
//         ];

//         if (thumbnail && thumbnail.url)
//           filesMeta[0].thumbnailUrl = thumbnail.url;
//         if (thumbnail && thumbnail.duration)
//           filesMeta[0].duration = thumbnail.duration;
//         console.log("fileee", file);
//         console.log("res,data,url ", res.data.url);
//         axios // upload asset on the signed url
//           .post(res.data.url, file, {
//             headers: {

//               "Content-Type": "application/json",
//               Authtoken: token,
//               "Access-Control-Allow-Headers": "authtoken",
//               "Access-Control-Allow-Origin": "http://localhost:3000/"
//             },
//           })
//           .then((result) => {
//             console.log("upload asset on the signed url", result);
//             const postUrl = APIUrls.addAssets(id);
//             console.log("postUrl", postUrl);
//             axios // add asset meta data in the firestore database
//               .post(
//                 postUrl,
//                 {
//                   files: filesMeta,
//                   directory: directory,
//                   Authtoken: token
//                 },
//                 {
//                   headers: {
//                     "Content-Type": "application/json",
//                     Authtoken: token,
//                     "Access-Control-Allow-Headers": "*",
//                     "Access-Control-Allow-Origin": "*"
//                   },
//                 }
//               )
//               .then((res) => {
//                 console.log("upload asset on the signed url", res);
//                 // change asset Meta list state

//                 dispatch(newAssetsMeta(res.data.data));
//               })
//               .catch((err) => {
//                 console.log(err);

//                 dispatch(loadingError());
//               });
//           })
//           .catch((error) => {
//             console.log(error); // maybe dispatch an error action

//             // set error state to true
//             dispatch(loadingError());
//           });
//       })
//       .catch((error) => {
//         console.log(error); // maybe dispatch an error action

//         // set error state to true
//         dispatch(loadingError());
//       });
//   };
// }
// export function deleteAsset(id, token, assetId, fileName, directory) {
//   return (dispatch) => {
//     const url = APIUrls.deleteAsset(id);

//     // sets loading state to true
//     dispatch(loadingStart());

//     axios // made delete request into post request to avoid url size issue
//       .post(
//         url,
//         {
//           directory: directory,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authtoken: token,
//           },
//           params: {
//             assetId: assetId,
//             fileName: fileName,
//           },
//           // add cancel token later
//         }
//       )
//       .then((res) => {
//         // remove asset with id === assetId from AssetList
//         dispatch(removeAsset(assetId));
//       })
//       .catch((error) => {
//         console.log(error); // maybe dispatch an error action

//         // set error state to true
//         dispatch(loadingError());
//       });
//   };
// }

// // get download url of asset
// export function assetDownloadUrl(id, token, assetId, fileName) {
//   return (dispatch) => {
//     const url = APIUrls.downloadUrl(id);
//     console.log(url);

//     // sets loading state to true
//     // dispatch(loadingStart());

//     axios
//       .get(url, {
//         headers: {
//           "Content-Type": "application/json",
//           Authtoken: token,
//         },
//         params: {
//           assetId: assetId,
//           fileName: fileName,
//         },
//         // add cancel token later
//       })
//       .then((res) => {
//         // get download url of asset
//         dispatch(downloadURL(res.data.url));
//       })
//       .catch((error) => {
//         console.log(error); // maybe dispatch an error action

//         // set error state to true
//         dispatch(loadingError());
//       });
//   };
// }

export function DeleteData(editsid) {
  return {
    type: NAVBAR_EDIT_DELETE_SUCCESS,
    editsid,
  };
}

export function getEditsList(editsList) {
  return {
    type: NAVBAR_EDIT_LIST_SUCCESS,
    editsList,
  };
}



export function ModifyData(editsid, newdata) {
  return {
    type: NAVBAR_EDIT_MODIFY_SUCCESS,
    editsid,
    newdata,
  };
}

export function AddNewData(payload) {
  // backend returns an array of asset meta uploaded
  return {
    type: NAVBAR_EDIT_UPLOAD_SUCCESS,
    payload,
  };
}

// export function downloadURL(url) {
//   // backend returns an array of asset meta uploaded
//   return {
//     type: DOWNLOAD_ASSET_SUCCESS,
//     url,
//   };
// }
export function NavbarMetaList(data) {
  return {
    type: GET_NAVBAREDITS_LIST,
    data,
  }
}

export function loadingStart() {
  return {
    type: NAVEDIT_LOADING_START,
  };
}

export function loadingError() {
  return {
    type: NAVEDIT_LOADING_ERROR,
  };
}
