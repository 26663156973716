import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import "./VideoEditorPreview.css";
import ImgCropper from "../Cropper/ImgCropper";
import { assetDownloadUrl } from "../../actions/asset";

const VideoEditorPreview = ({ 
  asset, 
  dispatch, 
  previewFile,
  VideoEditorPreviewWidth,
  VideoEditorPreviewHeight,
  showDia,
  setShowDia,
}) => {
 
  useEffect(() => {
    // console.log(`VideoEditorPreviewWidth  =  ${VideoEditorPreviewWidth}`);
    // console.log(`VideoEditorPreviewHeight = ${VideoEditorPreviewHeight}`);
    if (previewFile) {
      dispatch(
        assetDownloadUrl(
          previewFile.id,
          previewFile.token,
          previewFile.assetId,
          previewFile.fileName,
          previewFile.cancel
        )
      );
    }
    return () => {
      // cleanup
    };
  }, [previewFile,VideoEditorPreviewWidth,VideoEditorPreviewHeight,showDia]);

  const handleDiaOpen = () => {
    setShowDia(true);
  };

  const handleDiaClose = () => {
    setShowDia(false);
  };

  return (
    <>
      <ImgCropper
        Img={asset.downloadUrl}
        showDia={showDia}
        setShowDia={setShowDia}
      />

      <div className="VideoEditor-preview"
        style={{
          height : `${VideoEditorPreviewHeight}%`,
          width : `${VideoEditorPreviewWidth}%`,
        }}
      >
        {previewFile?.type?.includes("image") ? (
          <img
            src={asset.downloadUrl}
            alt={previewFile?.n}
            height="100%"
            width="100%"
          />
        ) : previewFile?.type?.includes("audio") ? (
          <audio
            controls
            className="media-style"
            height="100%"
            width="100%"
            alt={previewFile?.n}
            src={asset.downloadUrl}
          />
        ) : (
          <video
            id="vid"
            controls
            poster={
              previewFile?.thumbnailUrl
                ? previewFile?.thumbnailUrl
                : {}
            }
            className="media-style"
            src={asset.downloadUrl}
            height="100%"
            width="100%"
            alt={previewFile?.n}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  asset: state.asset,
});
export default connect(mapStateToProps)(VideoEditorPreview);
