import React, { useState } from "react";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import VideoTrackAddDialog from "../VideoTrackAddDialog/VideoTrackAddDialog";
import "./VideoEditorTimelineSidebar.css";
const VideoEditorTimelineSidebar = ({
  audioTracksList,
  setAudioTracksList,
  videoTracksList,
  setVideoTracksList,
}) =>
{
  const [addTrackOpen, setAddTrackOpen] = useState(false);
  const [selectTrack, setSelectTrack] = useState("Video");
  const [trackName, setTrackName] = useState("Video");

  // console.log("audioTracksList", audioTracksList);
  // console.log("videoTracksList", videoTracksList);
  // console.log("selectTrack ", selectTrack);
  // console.log("trackName ", trackName);

  const handleRemoveTrack = (type, inde) =>
  {
    // for remove audio track
    if (type === "audio")
    {
      if (audioTracksList.length <= 1)
      {
        return;
      }
      let newList = audioTracksList.filter(
        (value, index) => index !== parseInt(inde)
      );
      setAudioTracksList(newList);
    }
    // for remove video track
    if (type === "video")
    {
      if (videoTracksList.length <= 1)
      {
        return;
      }
      let newList = videoTracksList.filter((value, index) => index !== inde);
      setVideoTracksList(newList);
    }
  };

  const ellipsize = (str, limit) =>
  {
    if (str?.length > limit)
    {
      return `${ str.slice(0, limit) }...`;
    }
    return str;
  };
  return (
    <div className="VideoEditor-timeline-sidebar">
      <VideoTrackAddDialog
        trackName={trackName}
        setTrackName={setTrackName}
        addTrackOpen={addTrackOpen}
        setAddTrackOpen={setAddTrackOpen}
        selectTrack={selectTrack}
        audioTracksList={audioTracksList}
        setAudioTracksList={setAudioTracksList}
        videoTracksList={videoTracksList}
        setSelectTrack={setSelectTrack}
        setVideoTracksList={setVideoTracksList}
      />
      <div id="track-header">
        <span title="Add track" onClick={() => setAddTrackOpen(true)}>
          <PlaylistAddIcon />
        </span>
      </div>
      <div className="track-list-container">
        <div className="track-list">
          <ul>
            {videoTracksList.map((video, index) => (
              <li key={index}>
                <div>
                  <VideoCallIcon />
                  <span style={{ padding: "3px", cursor: "pointer" }}>
                    {ellipsize(video?.name, 15)}
                    &nbsp;{index + 1}
                  </span>
                </div>
                <div>
                  <TracksMenu
                    style={{ cursor: "pointer" }}
                    type="video"
                    index={index}
                    deleteHandler={handleRemoveTrack}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* <hr /> */}
        <div className="track-list">
          <ul>
            {audioTracksList.map((audio, index) => (
              <li key={index}>
                <div>
                  <AudiotrackIcon />
                  <span style={{ paddingLeft: "5px", cursor: "pointer" }}>
                    {ellipsize(audio?.name, 15)}
                    &nbsp;{index + 1}
                  </span>
                </div>
                <div style={{ cursor: "pointer" }}>
                  <TracksMenu
                    type="audio"
                    index={index}
                    deleteHandler={handleRemoveTrack}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VideoEditorTimelineSidebar;

const TracksMenu = ({ index, type, deleteHandler }) =>
{
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) =>
  {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () =>
  {
    setAnchorEl(null);
  };
  return (
    <div>
      <MoreVertIcon onClick={handleClick} />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Hide levels</MenuItem>
        <MenuItem onClick={handleClose}>Mute Track</MenuItem>
        <MenuItem onClick={() => deleteHandler(type, index)}>
          Delete Track
        </MenuItem>
      </Menu>
    </div>
  );
};
