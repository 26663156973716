import {
  // UDATE_PROJECTS_START,
  // UPDATE_PROJECTS_SUCCESS,
  LOADING_START,
  // PROJECT_LOADING_END,
  PROJECT_META_SUCCESS,
  LOADING_ERROR,
  PROJECT_LIST_SUCCESS,
  PROJECT_STATE_SUCCESS,
  PROJECT_CREATE_SUCCESS,
  PROJECT_NAME_MODIFY_SUCCESS,
  PROJECT_STATE_MODIFY_SUCCESS,
  PROJECT_DELETE_SUCCESS,
  CLEAR_PROJECT_STATE,
  //   UPDATE_DRAFTS,
  //   UPDATE_SCHEDULE,
  //   // ADD_POST,
  //   POST_CREATE_SUCCESS,
  //   POST_UPDATE_SUCCESS,
  //   CLEAR_POST_STATE,
  //   // ADD_COMMENT,
  //   // UPDATE_POST_LIKE,
} from "./actionTypes";

import { APIUrls } from "../Utils/urls";
import axios from "axios";

// get default project meta data
export function getDefaultProject (id, token) {
  return (dispatch) => {
    const url = APIUrls.getDefaultProject(id);

    // sets loading state to true
    dispatch(loadingStart());

    axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authtoken: token,
      },
      // add cancel token later
    })
    .then((res) => {
      // sets projectMeta state to res.data.data
      dispatch(projectMeta(res.data.data));

    })
    .catch((error) => {
      console.log(error); // maybe dispatch an error action

      // set error state to true
      dispatch(loadingError());
    });
  }
}

// get project list meta data of user
export function getProjectList (id, token) {
  return (dispatch) => {
    const url = APIUrls.getProjectList(id);

    // sets loading state to true
    dispatch(loadingStart());

    axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authtoken: token,
      },
      // add cancel token later
    })
    .then((res) => {
      // sets projectList state to res.data.data
      dispatch(projectMetaList(res.data.data));
      
    })
    .catch((error) => {
      console.log(error); // maybe dispatch an error action

      // set error state to true
      dispatch(loadingError());
    });
  }
}

// get project state
export function getProjectState (id, token, projectId) {
  return (dispatch) => {
    const url = APIUrls.getProjectState(id);

    // sets loading state to true
    dispatch(loadingStart());

    axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authtoken: token,
      },
      params : {
        projectId : projectId,
      }
      // add cancel token later
    })
    .then((res) => {
      // sets project state to res.data.data
      dispatch(projectState(res.data.data));
      
    })
    .catch((error) => {
      console.log(error); // maybe dispatch an error action

      // set error state to true
      dispatch(loadingError());
    });
  }
}

// create new project
export function createNewProject (id, token, projectName) {
  return (dispatch) => {
    const url = APIUrls.createNewProject(id);

    // sets loading state to true
    dispatch(loadingStart());

    axios.post(url, null , {    // remember second arguement must be null or some data field 
      headers: {
        "Content-Type": "application/json",
        Authtoken: token,
      },
      params : {
        name : projectName,
      }
      // add cancel token later
    })
    .then((res) => {
      // sets projectMeta state to res.data.data
      dispatch(projectMeta(res.data.data));
      
    })
    .catch((error) => {
      console.log(error); // maybe dispatch an error action

      // set error state to true
      dispatch(loadingError());
    });
  }
}

// see into some logic, what happens if the current project is deleted
export function deleteProject (id, token, projectId) {
  return (dispatch) => {
    const url = APIUrls.deleteProject(id);

    // sets loading state to true
    dispatch(loadingStart());

    axios.delete(url, {    // remember second arguement must be null or some data field 
      headers: {
        "Content-Type": "application/json",
        Authtoken: token,
      },
      params : {
        projectId : projectId,
      }
      // add cancel token later
    })
    .then((res) => {
      // remove project with projectId === projectId from projectList
      dispatch(removeProject(projectId));
      
    })
    .catch((error) => {
      console.log(error); // maybe dispatch an error action

      // set error state to true
      dispatch(loadingError());
    });
  }
}

// modify project name
export function modifiyProjectName (id, token, newName) {
  return (dispatch) => {
    const url = APIUrls.modifyProjectName(id);

    // sets loading state to true
    dispatch(loadingStart());

    axios.put(url, null , {    // remember second arguement must be null or some data field 
      headers: {
        "Content-Type": "application/json",
        Authtoken: token,
      },
      params : {
        name : newName,
      }
      // add cancel token later
    })
    .then((res) => {
      // sets projectMeta : name property to new name
      dispatch(changeProjectName(newName));
      
    })
    .catch((error) => {
      console.log(error); // maybe dispatch an error action

      // set error state to true
      dispatch(loadingError());
    });
  }
};

// modify project state (basically timeline field)
export function modifiyProjectState (id, token, timeline) {
  return (dispatch) => {
    const url = APIUrls.modifyProjectState(id);

    // sets loading state to true
    dispatch(loadingStart());

    axios.put(url, timeline, {    // remember second arguement must be null or some data field 
      headers: {
        "Content-Type": "application/json",
        Authtoken: token,
      },
      // add cancel token later
    })
    .then((res) => {
      // sets timeline field of projectState to new timeline
      dispatch(changeProjectState(timeline));
    })
    .catch((error) => {
      console.log(error); // maybe dispatch an error action

      // set error state to true
      dispatch(loadingError());
    });
  }
}

/* for now removing it from the projectList simply
  (when project other than the current project gets deleted).
  Maybe we show a modal with projectList for the user 
  to open incase current project gets deleted
*/




export function removeProject (projectId) {
  return {
    type : PROJECT_DELETE_SUCCESS,
    projectId
  }
};

export function changeProjectName (newName) {
  return {
    type : PROJECT_NAME_MODIFY_SUCCESS,
    newName
  }
};

export function changeProjectState (timeline) {
  return {
    type : PROJECT_STATE_MODIFY_SUCCESS,
    timeline
  }
};

export function projectState (projectState) {
  return {
    type : PROJECT_STATE_SUCCESS,
    projectState
  }
};

export function projectMetaList (projectList) {
  return {
    type : PROJECT_LIST_SUCCESS,
    projectList
  }
};

export function projectMeta(projectMeta) {
  return {
    type: PROJECT_META_SUCCESS,
    projectMeta,
  };
};

export function newProjectMeta(projectMeta) {
  return {
    type: PROJECT_CREATE_SUCCESS,
    projectMeta,
  };
};

export function loadingStart() {
  return {
    type : LOADING_START
  }
};

export function loadingError() {
  return {
    type : LOADING_ERROR
  }
};

export function clearProjectState(){
  return {
    type: CLEAR_PROJECT_STATE,
  };
}



 // fetch projects
// export function getProjects(id, token) {
//   return (dispatch) => {
//     const url = APIUrls.getProjects(id);
//     dispatch(updateProjectsStart());
    
//     axios
//       .get(url, {
//         headers: {
//           "Content-Type": "application/json",
//           Authtoken: token,
//         },
//       })
//       .then((res) => {
//         dispatch(updateProjectsSuccess(res.data.data));
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
// }
// export function updateProjectsStart() {
//   return {
//     type: UDATE_PROJECTS_START,
//   };
// }
// export function updateProjectsSuccess(projects) {
//   return {
//     type: UPDATE_PROJECTS_SUCCESS,
//     projects,
//   };
// }
// export function updateDafts(posts) {
//   return {
//     type: UPDATE_DRAFTS,
//     posts,
//   };
// }
// export function updateSchedule(posts) {
//   return {
//     type: UPDATE_SCHEDULE,
//     posts,
//   };
// }
// export function updateSuccess(success) {
//   return {
//     type: POST_UPDATE_SUCCESS,
//     success,
//   };
// }
// export function createSuccess(success) {
//   return {
//     type: POST_CREATE_SUCCESS,
//     success,
//   };
// }
// // export function addPost(post) {
// //   return {
// //     type: ADD_POST,
// //     post,
// //   };
// // }
// // create post
// export function createPost(content) {
//   const formData = new FormData();
//   formData.append("image", content.image);
//   formData.append("post_scheduled", content.post_scheduled);
//   formData.append("status", content.status);
//   formData.append("title", content.title);
//   formData.append("caption", content.caption);
//   return (dispatch) => {
//     const url = APIUrls.createPost();
//     const response = fetch(url, {
//       method: "POST",
//       headers: {
//         // "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${getAuthAccessTokenFromCookie()}`,
//       },
//       body: formData,
//     });
//     response.then(function (data) {
//       if (!data.ok) {
//         data.json().then(function (error) {
//           console.log("error", error);
//           // dispatch(signupFailed({ error }));
//         });
//       } else {
//         data.json().then(function (data) {
//           dispatch(createSuccess("Post Created Successfully"));
//         });
//       }
//     });
//   };
// }

// // edit post
// export function editPost(content) {
//   const formData = new FormData();
//   if (typeof content.image !== "string") {
//     formData.append("image", content.image);
//   }
//   formData.append("post_scheduled", content.post_scheduled);
//   formData.append("status", content.status);
//   formData.append("title", content.title);
//   formData.append("caption", content.caption);
//   return (dispatch) => {
//     const url = APIUrls.editPost(content.u_id);
//     const response = fetch(url, {
//       method: typeof content.image === "string" ? "PATCH" : "PUT",
//       headers: {
//         // "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${getAuthAccessTokenFromCookie()}`,
//       },
//       body: formData,
//     });
//     response.then(function (data) {
//       // console.log("response", response);
//       if (!data.ok) {
//         data.json().then(function (error) {
//           console.log("error", error);
//         });
//       } else {
//         data.json().then(function (data) {
//           dispatch(updateSuccess("Post Updated Successfully"));
//         });
//       }
//     });
//   };
// }
// export function clearPostState() {
//   return {
//     type: CLEAR_POST_STATE,
//   };
// }
