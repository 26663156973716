import React, { useState, useEffect, useRef } from 'react'
import { Input, Paper } from "@material-ui/core";
import axios from "axios";
import { Button } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "./RightSideBar.css"

const RightSideBar = ({
  VideoEditorPreviewWidth,
  setVideoEditorPreviewWidth,
  VideoEditorPreviewHeight,
  setVideoEditorPreviewHeight,
}) => {
  const [hit, sethit] = useState(100);
  const [wid, setwid] = useState(100);
  const [Option, setOption] = useState("HD");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (hit > 100) sethit(100);
    if (wid > 100) setwid(100);
    setVideoEditorPreviewHeight(parseInt(hit));
    setVideoEditorPreviewWidth(parseInt(wid));
    // console.log(`VideoEditorPreviewWidth  =  ${VideoEditorPreviewWidth}`);
    // console.log(`VideoEditorPreviewHeight = ${VideoEditorPreviewHeight}`);
    // console.log(hit);
    // console.log(wid);
    // console.log(`'${VideoEditorPreviewHeight}%'`);
    // console.log(VideoEditorPreviewHeight);
    // // console.log(e.target.value);
  }
  const handleChange = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  return (
    <>
      <Paper style={{ paddingBottom: "10px", borderRadius: 0, paddingRight: 10, backgroundColor: "rgb(15,15,15)", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            fontSize: "15px",
            color: "wheat",
            paddingLeft: "20px",
            paddingBottom: "20px",
          }}
        >
          Scale :
        </div>
        <div
          style={{
            display: "flex", flexDirection: "row", Content: "center"
          }}>
          <div
            style={{
              fontSize: "15px",
              color: "wheat",
              paddingLeft: "20px",
              paddingTop: "10px",
            }}
          >
            Format
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingLeft: "40px"
            }}
          >
            <Select
              style={{
                width: "132px",
                backgroundColor: "white",
                marginLeft: "2px",
                textAlign: "center",
                padingLeft: "10px",
                height: "20px",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Option}
              onChange={handleChange}
            >
              <MenuItem value="HD">Full HD</MenuItem>
              <MenuItem value="ig_story">IG Story</MenuItem>
              <MenuItem value="ig_post">IG Post</MenuItem>
              <MenuItem value="2k_wide">2K Wide</MenuItem>
              <MenuItem value="4k_wide">4K Wide</MenuItem>
              <MenuItem value="4k_square">4K Square</MenuItem>
            </Select>
          </div>
        </div>
        <div
          style={{
            display: "flex", flexDirection: "row",
          }}
        >
          <div
            style={{
              fontSize: "15px",
              color: "wheat",
              paddingLeft: "20px",
              paddingTop: "5px",
            }}
          >
            Dimensions
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingTop: "5px",
            }}
          >
            <Input
              style={{
                width: "60px",
                backgroundColor: "white",
                marginLeft: "2px",
                textAlign: "center",
                padingLeft: "10px",
                height: "20px",
              }}
              type="number"
              placeholder="Height"
              onChange={(e) => { e.target.value ? sethit(parseInt(e.target.value)) : sethit(100) }}
            // onKeyDown={handleSubmit}
            />
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingTop: "5px",
            }}
          >
            <Input
              style={{
                width: "60px",
                backgroundColor: "white",
                marginLeft: "2px",
                textAlign: "center",
                padingLeft: "10px",
                height: "20px",
              }}
              type="number"
              placeholder="Width"
              onChange={(e) => { e.target.value ? setwid(parseInt(e.target.value)) : setwid(100) }}
            // onKeyDown={handleSubmit}
            />
          </div>
          <Button
            style={{
              float: "left",
              // height: "20px",
              // paddingTop: "40px",
              // magrin:"auto",
              // borderRadius: 0,
              backgroundColor: "rgb(15,15,15)",
              color: "white",
              // justifyContent:"centre",
              // paddingInline: 0,
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            <SearchIcon />
          </Button>
        </div>
      </Paper>
    </>
  )
}

export default RightSideBar