import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import VideoEditorFinish from '../VideoEditorFinish/VideoEditorFinish';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExportVideoDialog({showDialog,setShowDialog,videoTitle, setVideoTitle, quality, setQuality, value, setValue}) {
  
  const handleClickOpen = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };
  
  const handleVideoTitle = (e) => {
    e.preventDefault();
    setVideoTitle(e.target.value);
  }

  const [showDia, setShowDia] = React.useState(false);
  return (
    <>
    <VideoEditorFinish showDia={showDia} setShowDia={setShowDia} videoTitle={videoTitle} setVideoTitle={setVideoTitle} quality={quality} setQuality={setQuality} value={value} setValue={setValue}/>
    <div>
    <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle id="alert-dialog-slide-title">{"Name Your Export"}</DialogTitle>
        <DialogContent>
        <TextField style={{width:300}} id="filled-basic" label="Title" placeholder="My Video" value={videoTitle} variant="filled" onChange={handleVideoTitle}/>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary" >
            Back
        </Button>
        <Button onClick={() => {setShowDia(true);handleClose();}} variant='contained' color="primary">
            Set
        </Button>
        </DialogActions>
    </Dialog>
    </div>
    </>
  );
}