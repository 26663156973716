import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { withFirebase } from "../../firebase";
import { Input, Paper } from "@material-ui/core";
import axios from "axios";
import "./VideoEditorSideContainerStockMedia.css";
import { Button } from "@material-ui/core";
import { Select } from "@material-ui/core";
import VideoEditorStockMediaResultsVids from "../VideoEditorStockMediaResultsVids/VideoEditorStockMediaResultsVids";
import VideoEditorStockMediaResults from "../VideoEditorStockMediaResults/VideoEditorStockMediaResults";
import { MenuItem } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const VideoEditorSideContainerStockMedia = ({
  setPreviewFile,
  setVidUrl,
  setImgUrl,
}) => {
  const [search, setSearch] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [type, setType] = useState("Videos");
  const SearchImages = async (val) => {
    if (type === "Images") {
      if (val === "") {
        setImages([]);
      } else {
        await axios
          .get(
            `https://pixabay.com/api/?key=16508855-cc2735220759c3e0e54a8ff73&q=${search}
            &image_type=photo&per_page=30&safesearch=true`
          )
          .then((res) => setImages(res.data.hits))
          .catch((err) => console.log(err));
      }
    } else {
      if (val === "") {
        setVideos([]);
      } else {
        await axios
          .get(
            `https://pixabay.com/api/videos/?key=16508855-cc2735220759c3e0e54a8ff73&q=${search}
            &per_page=30&safesearch=true`
          )
          .then((res) => setVideos(res.data.hits))
          .catch((err) => console.log(err));
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  useEffect(() => {
    async function fetchFil() {
      if (search === "") {
        await axios
          .get(
            "https://pixabay.com/api/?key=16508855-cc2735220759c3e0e54a8ff73&q= &image_type=photo&per_page=30&safesearch=true"
          )
          .then((res) => setImages(res.data.hits))
          .catch((err) => console.log(err));
        await axios
          .get(
            "https://pixabay.com/api/videos/?key=16508855-cc2735220759c3e0e54a8ff73&q= &per_page=30&safesearch=true"
          )
          .then((res) => setVideos(res.data.hits))
          .catch((err) => console.log(err));
      }
    }
    fetchFil();
  }, []);

  const handleSubmit = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      SearchImages(search);
    }
  };
  console.log("videos",videos);
  return (
    <div className="VideoEditor-side-all-stock-media">
      <div style={{ width: "100%" ,backgroundColor: "rgb(15,15,15)"}} id="sidebar-weird-child-container">
        <Paper style={{paddingBottom:"10px",borderRadius:0,paddingRight:10,backgroundColor: "rgb(15,15,15)"}}>
        <Input
          style={{ backgroundColor: "white", width: "50%" }}
          type="search"
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleSubmit}
        />
        <Select
          style={{
            width: "80px",
            backgroundColor: "white",
            marginLeft: "2px",
            textAlign: "center",
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          onChange={handleChange}
        >
          <MenuItem value="Videos">Videos</MenuItem>
          <MenuItem value="Images">Images</MenuItem>
        </Select>
        <Button
          style={{
            float: "right",
            height: "30px",
            borderRadius: 0,
            paddingInline: 0,
          }}
          variant="contained"
          onClick={() => SearchImages(search)}
        >
          <SearchIcon />
        </Button></Paper>
        <div id="stock-media-sub-contianer-pics-videos">
        {images.length > 0 || videos.length > 0 ? (
          type === "Videos" ? (
            <VideoEditorStockMediaResultsVids
              videos={videos}
              setPreviewFile={setPreviewFile}
              setVidUrl={setVidUrl}
              setImgUrl={setImgUrl}
            />
          ) : (
            <VideoEditorStockMediaResults
              images={images}
              setPreviewFile={setPreviewFile}
              setImgUrl={setImgUrl}
              setVidUrl={setVidUrl}
            />
          )
        ) : (
          <div style={{ height: "100%", width: "100%", textAlign: "center" }}>
            <p style={{ marginTop: 50, color: "white" }}>
              Sorry! Could not find any related Media
            </p>
          </div>
        )}</div>
      </div>
    </div>
  );
};

export default VideoEditorSideContainerStockMedia;
