import React, { useRef, useState } from "react";
import { VideoEditorTimelineSidebar, VideoEditorTimelineFrames } from "../";
import framesReducer from "../../reducers/frames";
import { useDispatch } from "react-redux";
import "./VideoEditorTimeline.css";

// var layers = [
//   {
//     id: "3d1df1b4-4d9d-45a4-bf14-cb580ee74675",
//     name: "Left",
//   },
//   {
//     id: "7d8c4210-0cfa-4a10-8b21-01e6601e00bf",
//     name: "Top",
//   },
//   {
//     id: "65079f30-47a8-4469-833e-4f0eea04d233",
//     name: "Bottom",
//   },
// ];
var frames = {
  "3d1df1b4-4d9d-45a4-bf14-cb580ee74675": [
    {
      name: "Hello.png",
      second: 0,
      duration: 70,
    },
    {
      // name: "https://cdn.pixabay.com/photo/2020/01/31/19/26/vinyl-4808792_960_720.jpg",
      second: 130,
      duration: 200,
    },
  ],
  "7d8c4210-0cfa-4a10-8b21-01e6601e00bf": [
    {
      name: "Goodbye.png",
      second: 10,
      duration: 150,
    },
  ],
  "65079f30-47a8-4469-833e-4f0eea04d233": [],
};

const setBg = () =>
{
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  document.body.style.backgroundColor = "#" + randomColor;

}







const VideoEditorTimeline = () =>
{
  const [audioTracksList, setAudioTracksList] = useState([
    {
      name: "Audio",
      files: [],
    },
  ]);
  const [videoTracksList, setVideoTracksList] = useState([
    {
      name: "Video",
      files: [],
    },
  ]);

  const [frame, setFrames] = useState(setBg());

  const updateFrames = (framesUpdated) =>
  {
    setFrames(framesUpdated);

    localStorage.setItem("timelineFrames", JSON.stringify(framesUpdated));
    console.log("framesssss", framesUpdated);
    //TODO: deal with frames
  };

  // const onDrop = async (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   let media = e.dataTransfer.getData("media");
  //   let mediaType = e.dataTransfer.getData("mediaType");
  //   if (mediaType.includes("video") || mediaType.includes("image")) {
  //     let newV = [...videoTracksList];
  //     await newV.map((track, index) => {
  //       if (index === parseInt(e.target.id)) return track.files.push(media);
  //     });
  //     await setVideoTracksList(newV);
  //   } else {
  //     let newV = [...audioTracksList];
  //     await newV.map((track, index) => {
  //       if (index === parseInt(e.target.id)) return track.files.push(media);
  //     });
  //     await setAudioTracksList(newV);
  //   }
  // };
  // const onDragOver = (e) => {
  //   e.preventDefault();
  //   e.dataTransfer.dropEffect = "move";
  // };

  // const onDragStart = (e, file) => {
  //   e.dataTransfer.setData("media", URL.createObjectURL(file));
  //   e.dataTransfer.setData("mediaType", file.type);
  //   e.dataTransfer.effectAllowed = "move";
  // };
  return (
    <div className="VideoEditor-timeline">
      <VideoEditorTimelineSidebar
        audioTracksList={audioTracksList}
        setAudioTracksList={setAudioTracksList}
        videoTracksList={videoTracksList}
        setVideoTracksList={setVideoTracksList}
      />{" "}
      <div className="VideoEditor-timeline-main-container">
        <VideoEditorTimelineFrames
          frames={frame}
          audioTracksList={audioTracksList}
          setAudioTracksList={setAudioTracksList}
          videoTracksList={videoTracksList}
          setVideoTracksList={setVideoTracksList}
          seconds="1000"
          updateFrames={updateFrames}
        />{" "}
        {/* <div id="timeline-header">
                      
                      <VideoEditingTimeline config={config} />
                    </div>
                    <div className="track-list-container">
                      <div className="track-list">
                        <ul>
                          {videoTracksList.map((video, index) => (
                            <li
                              id={index}
                              key={index}
                              onDrop={(e) => onDrop(e)}
                              onDragOver={(e) => onDragOver(e)}
                            >
                              {video?.files?.map((vid, index) => (
                                <span
                                  key={index}
                                  draggable
                                  // onDragStart={(e) => onDragStart(vid, index)}
                                >
                                  <img src={vid} height="30px" width="30px" alt="" />
                                </span>
                              ))}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <hr />
                      <div className="track-list">
                        <ul>
                          {audioTracksList.map((audio, index) => (
                            <li
                              id={index}
                              key={index}
                              onDrop={(e) => onDrop(e)}
                              onDragOver={(e) => onDragOver(e)}
                            >
                              {audio?.files?.map((vid, index) => (
                                <span key={index}>
                                  <img
                                    src={
                                      "https://cdn.pixabay.com/photo/2020/01/31/19/26/vinyl-4808792_960_720.jpg"
                                    }
                                    height="30px"
                                    width="30px"
                                    alt=""
                                  />
                                </span>
                              ))}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div> */}{" "}
      </div>
    </div>
  );
};

export default VideoEditorTimeline;
