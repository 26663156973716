import React, { useEffect, useRef, useState } from "react";
import "./upperHalf.css";
import VideoEditorSideContainer from "../VideoEditorSideContainer/VideoEditorSideContainer";
import VideoEditorPreview from "../VideoEditorPreview/VideoEditorPreview";
import PlayArrowSharpIcon from '@material-ui/icons/PlayArrowSharp';
import PauseSharpIcon from '@material-ui/icons/PauseSharp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FastForwardIcon from '@material-ui/icons/FastForward';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import { Slider } from "@material-ui/core";
// import { useState } from "react";

const Upperhalf = ({
  setPreviewFile,
  previewFile,
  vidUrl,
  setVidUrl,
  imgUrl,
  setImgUrl,
  VideoEditorPreviewWidth,
  setVideoEditorPreviewWidth,
  VideoEditorPreviewHeight,
  setVideoEditorPreviewHeight,
  showDia,
  setShowDia,
}) => {

  const videoRef = useRef(null);
  const innerWidth = Math.max(window.innerWidth, 1200); // important minimum window width
  const widthPlayer = useRef(Math.floor(innerWidth * 0.4 + 0.5));
  const h = useRef(widthPlayer.current * (5 / 8));
  const [playing, setPlaying] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setplaybackRate] = useState(1);
  const [Muted, setMuted] = useState(false);
  const [VidVolume, setVidVolume] = useState(0.5);

  let mouseDown = false;
  let startX, prev;
  let slider = null,
    player = null,
    notplayer = null,
    dragbar = null;

  const startDragging = (e) => {
    mouseDown = true;
    startX = e.pageX;
    prev = 0;
  };

  const stopDragging = (e) => {
    console.log("mouseup");
    mouseDown = false;
  };

  const mouseMotion = (e) => {
    // e.preventDefault();
    if (!mouseDown || window.innerWidth < 1200) {
      return;
    }

    const scroll = e.pageX - startX;

    if (Math.abs(scroll) < 5) return;

    const moveBy = scroll - prev;

    // move left or right
    if (moveBy < 0) {
      if (widthPlayer.current - moveBy < Math.floor(innerWidth * 0.4 + 0.5)) {
        console.log("move left");
        widthPlayer.current -= moveBy;
        h.current = widthPlayer.current * (5 / 8);
        player.setAttribute(
          "style",
          `width : ${widthPlayer.current}px; height : ${h.current}px`
        );
        notplayer.setAttribute(
          "style",
          `width : ${innerWidth - 12 - widthPlayer.current}px ; height : ${h.current
          }px`
        );
        dragbar.setAttribute("style", `height : ${h.current}px`);
      }
    } else if (moveBy > 0) {
      if (widthPlayer.current - moveBy > Math.floor(innerWidth * 0.33 + 0.5)) {
        console.log("move right");
        widthPlayer.current -= moveBy;
        h.current = widthPlayer.current * (5 / 8);
        player.setAttribute(
          "style",
          `width : ${widthPlayer.current}px ; height : ${h.current}px`
        );
        notplayer.setAttribute(
          "style",
          `width : ${innerWidth - 12 - widthPlayer.current}px ; height : ${h.current
          }px`
        );
        dragbar.setAttribute("style", `height : ${h.current}px`);
      }
    }

    prev = scroll;
  };


  const get_percentage = (present, total) => {
    let calc_perc = (present / total) * 100;
    return parseFloat(calc_perc.toString());
  }

  const calc_duration = (dur) => {
    let second = parseInt(dur % 60);
    let minutes = parseInt((dur % 3600) / 60);
    let hours = parseInt(dur / 3600);
    return {
      hours: helper_pad(hours),
      minutes: helper_pad(minutes),
      second: helper_pad(second),
    }
  }
  const helper_pad = (num) => {
    if (num > -10 && num < 10) {
      return "0" + num;
    }
    else return num.toString();
  }


  useEffect(() => {
    // slider = document.querySelector(".middle");
    player = document.querySelector(".right");
    // notplayer = document.querySelector(".left");
    // dragbar = document.querySelector(".middle");

    document.addEventListener("mousemove", mouseMotion);
    // Add the event listeners
    // slider.addEventListener("mousedown", startDragging, false);
    document.addEventListener("mouseup", stopDragging, false);

    return () => {
      document.removeEventListener("mousemove", mouseMotion);
      // slider.removeEventListener("mousedown", startDragging, false);
      document.removeEventListener("mouseup", stopDragging, false);
    };
  }, []);

  // console.log("term video = ", VideoEditorPreviewWidth, VideoEditorPreviewHeight);

  useEffect(() => {
    if (vidUrl && videoRef !== null) {
      videoRef.current.playbackRate = playbackRate
      if (Muted) videoRef.current.volume = 0;
      else videoRef.current.volume = VidVolume;
      var len = videoRef.current.duration;
      setVideoTime(len);
    }
    return () => {
      // second
    }
  }, [playbackRate, videoTime, Muted, VidVolume])


  const videoHandler = (control) => {
    if (vidUrl && videoRef !== null) {
      if (control === "play") {
        videoRef.current.play();
        setPlaying(true);
        var len = videoRef.current.duration;
        setVideoTime(len);
      } else if (control === "pause") {
        videoRef.current.pause();
        setPlaying(false);
      }
    }
  };

  const Volume_handler = (control) => {
    if (vidUrl && videoRef !== null) {
      if (control === "mute") {
        setMuted(true);
        videoRef.current.volume = 0;
        setVidVolume(0);
      } else if (control === "unmute") {
        setMuted(false);
        videoRef.current.volume = VidVolume;
        setVidVolume(0.5);
      }
    }
  };

  window.setInterval(function () {
    if (vidUrl && videoRef !== null) {
      setCurrentTime(videoRef?.current?.currentTime);
    }
  }, 1000);

  const fastForward = () => {
    if (vidUrl && videoRef !== null) {
      videoRef.current.currentTime += 5;
    }
  };

  const revert = () => {
    if (vidUrl && videoRef !== null) {
      videoRef.current.currentTime -= 5;
    }
  };
  const handleRangeUpdate = (e) => {
    if (vidUrl && videoRef !== null) {
      setplaybackRate(e.target.value);
    }
  }
  const handleVloumeRangeUpdate = (e) => {
    if (vidUrl && videoRef !== null) {
      setVidVolume(e.target.value);
    }
  }

  return (
    <div className="upper">
      {/* <div
                    className="left"
                    style={{
                      height: `${h.current}px`,
                      width: `${innerWidth - 12 - widthPlayer.current}px`,
                    }}
                  >
                    <VideoEditorSideContainer setPreviewFile={setPreviewFile} setVidUrl = {setVidUrl} setImgUrl={setImgUrl}/>
                  </div>
                  <div className="middle" style={{ height: `${h.current}px` }}></div> */}{" "}
      <div
        className="right"
        style={{
          height: `${h.current * 1.35}px`,//aman multiplied by 1.35
          maxWidth: `100%`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {vidUrl ? (
          <video
            crossOrigin="anonymous"
            className="media-style"
            autoPlay
            ref={videoRef}
            playbackRate={playbackRate}
            style={{
              height: `${VideoEditorPreviewWidth}%`,
              width: `${VideoEditorPreviewWidth}%`,
              objectFit: "fill",
            }}
            src={vidUrl}
          />
        ) : imgUrl ? (
          <img
            alt="video"
            className="media-style"
            style={{
              objectFit: "fill",
              height: `${VideoEditorPreviewHeight}%`,
              width: `${VideoEditorPreviewWidth}%`,
            }}
            src={imgUrl}
          />
        ) : (
          <VideoEditorPreview
            previewFile={previewFile}
            VideoEditorPreviewWidth={VideoEditorPreviewWidth}
            setVideoEditorPreviewWidth={setVideoEditorPreviewWidth}
            VideoEditorPreviewHeight={VideoEditorPreviewHeight}
            setVideoEditorPreviewHeight={setVideoEditorPreviewHeight}
            showDia={showDia}
            setShowDia={setShowDia}
          />
        )}{" "}
      </div>{" "}
      <div className="video-controlls">
        <div className="Video-controlls-left">
          <div className="play_back_controll">
            {playing ? (
              <button
                onClick={() => videoHandler("pause")}
                className="controlsIcon--small"
              >
                <PlayArrowSharpIcon />
              </button>
            ) : (
              <button
                onClick={() => videoHandler("play")}
                className="controlsIcon--small">
                <PauseSharpIcon />
              </button>
            )}
          </div>
          <div className="play_timing_controll">
            <div className="play_timing_controll_inner">
              <button
                onClick={revert}
                className="controlsIcon"
              >
                <SkipPreviousRoundedIcon size="large" />
              </button>
              <div className="play_timing_controll_start_time">
                <span>{calc_duration(currentTime).hours}:{calc_duration(currentTime).minutes}:{calc_duration(currentTime).second}</span>
              </div>
              <div className="play_timing_controll_time_breaker">
                <span>|</span>
              </div>
              <div className="play_timing_controll_total_time">
                <span>{calc_duration(videoTime).hours}:{calc_duration(videoTime).minutes}:{calc_duration(videoTime).second}</span>
              </div>
              <button
                onClick={fastForward}
                className="controlsIcon"
              >
                <SkipNextRoundedIcon />
              </button>
            </div>
          </div>
        </div>
        <div className="Video-controlls-right">
          <div className="play_sound_controll">
            {Muted ? (
              <button
                onClick={() => Volume_handler("unmute")}
                className="controlsIcon--small"
              >
                <VolumeOffIcon />
              </button>
            ) : (
              <div>
                <button
                  onClick={() => Volume_handler("mute")}
                  className="controlsIcon--small">
                  <VolumeUpIcon />
                </button>
              </div>
            )}
            <input
              type="range"
              name="Volume"
              className="player__Volume"
              min="0"
              max="1"
              step="0.1"
              value={VidVolume}
              onChange={handleVloumeRangeUpdate}
            />
          </div>
          <div className="play_back_speed_controll">
            <div className="Playback_text">
              <span>
                playback speed :
              </span>
            </div>
            <input
              type="range"
              name="playbackRate"
              className="player__slider"
              min="0.5"
              max="2"
              step="0.1"
              value={playbackRate}
              onChange={handleRangeUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upperhalf;
