import { useEffect, useCallback, useState } from "react";

const useContextMenu = (outerRef) => {
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [menu, showMenu] = useState(false);
  const [id, setId] = useState(null);
  const [fileName, setFileName] = useState(null);

  const handleContextMenu = useCallback(
    (event) => {
      // outerRef && outerRef.current.contains(event.target);
      if (outerRef && outerRef.current.contains(event.target)) {
        event.preventDefault();
        setXPos(`${event.pageX}px`);
        setYPos(`${event.pageY}px`);
        // Do comment if you find any alternative for below line
        const target =
          event.target.closest("img") || event.target.closest("video");
        if (target) {
          showMenu(true);
          setId(target.id);
          setFileName(target.title);
        }
      } else {
        setId(false);
        showMenu(false);
      }
    },
    [showMenu, outerRef, setXPos, setYPos]
  );

  const handleClick = useCallback(() => {
    showMenu(false);
  }, [showMenu]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });

  return { xPos, yPos, menu, id, fileName };
};

export default useContextMenu;
