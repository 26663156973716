import React, { useState } from "react";
import Main from "./Main";
import "./assets/css/App.css";
import GlobalState from "./GlobalState/GlobalState";

function App() {
  const [globalState, setGlobalState] = useState({
    location: "home",
    sideBarOpen: 0,
  });
  return (
    <GlobalState.Provider value={[globalState, setGlobalState]}>
      <div>
        <Main />
      </div>
    </GlobalState.Provider>
  );
}

export default App;
