import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ImgDialog from './ImgDialog'
import getCroppedImg from './getCroppedImg'
import { styles } from './styles'
import { Modal } from '@material-ui/core'
import { Container } from '@material-ui/core'

const ImgCropper = ({Img, showDia, setShowDia, classes}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])  

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        Img,
        croppedAreaPixels,
        rotation
      )
      console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  const handleDiaOpen = () => {
    setShowDia(true);
  };

  const handleDiaClose = () => {
    setShowDia(false);
  };

  return (
    <div>
      <Modal
      open={showDia}
      onClose={handleDiaClose}>  
      <div>
      <Container fluid>  
      <div style={{marginTop:200}} className={classes.cropContainer}>
        <Cropper
          image={Img}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            styles={{ root: classes.sliderLabel }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            styles={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            styles={{ root: classes.sliderLabel }}
          >
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            styles={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
        <Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
          styles={{ root: classes.cropButton }}
        >
          Show Result
        </Button>
        <Button 
        variant="contained"
        color="grey"
        styles={{ root: classes.cropButton }}
        onClick={handleDiaClose}>Exit</Button>
      </div>
      <ImgDialog img={croppedImage} onClose={onClose} />
      </Container>
      </div>
      </Modal>
    </div>
  )
}

export default withStyles(styles)(ImgCropper)