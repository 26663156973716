import React, { useState, useEffect, useRef } from 'react'
import { Input, Paper } from "@material-ui/core";
// import axios from "axios";
// import { Button } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
import MainNavBarEdit from '../../components/MainNavBarEdit/MainNavBarEdit';
import Editor from '../../Utils/Editor/Editor';


const Menu = () => {

  const [options, setoptions] = useState("Main_navbar")
  const handleChange = (e) => {
    e.preventDefault();
    setoptions(e.target.value);
  };
  return (
    <div style={{ width: "100%" }}>
      <Paper style={{ paddingBottom: "10px", borderRadius: 0, paddingRight: 10, backgroundColor: "rgb(15,15,15)", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row", Content: "center" }}>
          <div style={{ fontSize: "15px", color: "wheat", paddingLeft: "20px", paddingTop: "10px", }}>
            Select a menu
          </div>
          <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
            <Select
              style={{ width: "132px", backgroundColor: "white", marginLeft: "2px", textAlign: "center", padingLeft: "10px", height: "30px", }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={options}
              onChange={handleChange}
            >
              <MenuItem value="Main_navbar">Main Navbar</MenuItem>
              <MenuItem value="side_navbar">Side navbar</MenuItem>
            </Select>
          </div>
        </div>
      </Paper>

      {options === 'Main_navbar' ? <MainNavBarEdit /> : (false)}
      {options === 'side_navbar' ? <Editor /> : (false)}
    </div>
  )
}

export default Menu