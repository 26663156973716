import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import "./VideoEditorSubNavbar.css";
import { Avatar, Button, Typography } from "@material-ui/core";
import ExportVideoDialog from "../ExportVideoDialog/ExportVideoDialog";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import GlobalState from "../../GlobalState/GlobalState";
import Logo from "../../assets/logo.gif";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  download: {
    margin: "4px",
    padding: 5,
    paddingInline: 10,
    borderRadius: 5,
    width: "fit-content",
    color: "#fff",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#2f5691",
      cursor: "pointer",
    },
  },
  toolButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    paddingInline: 10,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#f0f0f0",
      cursor: "pointer",
    },
  },
}));



const VideoEditorSubNavbar = ({
  setSideBarOpen,
  toggleMainSideBar,
  showMainSidebar,
  sideBarOpen,
  history,
  setRightsideBarOpen,
  rightsideBarOpen,
  showDia,
  setShowDia,
}) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [quality, setQuality] = useState("SD");
  const [value, setValue] = useState("Video");
  const [globalState, setGlobalState] = useContext(GlobalState);

  const handleDiaOpen = () => {
    setShowDia(true);
  };
  return (
    <>
      <ExportVideoDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        videoTitle={videoTitle}
        setVideoTitle={setVideoTitle}
        quality={quality}
        setQuality={setQuality}
        value={value}
        setValue={setValue}
      />
      <div
        className="VideoEditor-sub-navbar-container"
        style={{ paddingRight: 10, paddingLeft: 10, background: "rgb(15,15,15)" }}
      >
        <div className="left-container">
          <div id="sidebarbtn">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {" "}
              <div className={classes.toolButtons} style={{ color: "white" }}>
                {/* <MenuIcon
                  onClick={() => {
                    toggleMainSideBar(!showMainSidebar);
                  }}
                /> */}
                <ArrowBackIosIcon
                  onClick={() => {
                    history.push("/editor");
                    setGlobalState((state) => ({ ...state, location: "home" }));
                  }}
                />
              </div>
              <div>
                <img
                  src={Logo}
                  alt="logo"
                  style={{ height: "40px", marginRight: 10 }}
                />
              </div>
              <div>
                <span
                  to="/"
                  onClick={() => {
                    history.push("/editor");
                    setGlobalState((state) => ({ ...state, location: "home" }));
                    window.location.replace("/");
                  }}
                  className="VideoEditor-navbar-title"
                  style={{ color: "white" }}
                >
                  Lurph
                </span>
              </div>
            </div>
            {/* <span
              style={{
                color: "white",
                fontSize: "15px",
                padding: " 10px 10px 10px 10px",
                fontWeight: "600",
              }}
            >
              File
            </span>
            <span
              style={{
                color: "white",
                fontSize: "15px",
                padding: "10px",
                fontWeight: "600",
              }}
            >
              Resize
            </span>
            <span
              style={{
                color: "#f5f5f5",
                fontSize: "10px",
                padding: "10px",
                fontWeight: "600",
              }}
            >
              All Changes are Saved
            </span> */}
          </div>
        </div>
        <div className="right-container">
          {/* <span
            style={{
              color: "#f5f5f5",
              fontSize: "12px",
              padding: "10px",
              fontWeight: "600",
            }}
          >
            Untitled Design-1080p
          </span>
          <Button className={classes.cancelBtn}>Try Lurph Pro</Button>
          <Button className={classes.cancelBtn}>Share</Button>
          <Button className={classes.cancelBtn}>Play</Button> */}
        </div>
        <div id="tools-videoeditor-navbar">
          <div className={classes.toolButtons}>
              <EditIcon
                style={{ color: "white" }}
                onClick={handleDiaOpen}
              />
          </div>
          <div
            className={classes.toolButtons}
            style={{ color: "white", marginRight: 20 }}
          >
            <SaveAltIcon onClick={() => setShowDialog(true)} />
          </div>
          <div className={classes.toolButtons} style={{ color: "white" }}>
            <ArrowBackIosIcon
              onClick={() => {
                let number = rightsideBarOpen === 1 ? 0 : 1;
                setRightsideBarOpen(number);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default VideoEditorSubNavbar;
