import React from "react";
import { makeStyles } from "@material-ui/core";
import { AppBar } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "black",
    color: "black",
  },
  appBar: {
    background: "none",
    backgroundColor: "black",
  },
  homeTitle: {
    flexGrow: "1",
  },
  signInButton: {
    color: "white",
    padding: "10px",
    background: "none",
    borderColor:"white",
    fontFamily: "Roobert,sans-serif",
    border:"none",
    marginRight: "20px",
  },
  signUpButton: {
    backgroundColor: "#473ff4",
    color: "white",
    padding: "10px",
    background: "none",
    width: "100px",
    borderRadius: "10px",
  },
  mainHeading: {
    color: "white",
    fontSize: "25px",
    marginTop:"30px"
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar style={{ borderBottom: "1px solid grey" }}>
          <h1 style={{ color: "white", fontSize: "30px" }}>Lurph</h1>
          <h1 className={classes.homeTitle}></h1>
          <a href="/product">
            <button className={classes.signInButton}>Product</button>
          </a>
          <a href="/customers">
            <button className={classes.signInButton}>Customers</button>
          </a>
          <a href="/pricing">
            <button className={classes.signInButton}>Pricing</button>
          </a>
          <a href="/templates">
            <button className={classes.signInButton}>Templates</button>
          </a>
          <a href="/research">
            <button className={classes.signInButton}>Research</button>
          </a>
          <a href="/company">
            <button className={classes.signInButton}>Company</button>
          </a>
          <a href="/login">
            <button
              className={classes.signInButton}
              style={{
               border:"1px solid white",
                borderRadius: "10px",
                width: "100px",
              }}
            >
              LOG IN
            </button>
          </a>
          <a href="/sign-up">
            <button className={classes.signUpButton}>SIGN UP </button>
          </a>
        </Toolbar>
      </AppBar>
      <div className={classes.mainHeading}>
        <center>
          <h2
            style={{
              fontWeight: "bolder",
              fontSize: "64px",
              alignContent: "center",
            }}
          >
            All-in-one workspace
          </h2>
        </center>
        <center>
          <p style={{ color: "white" }}>
            One tool for you or your whole team. Design, and get
            organized.
          </p>{" "}
        </center>
        <div>
          <center>
            <a href="/sign-up">
              
              <button
                style={{
                  fontSize: "14px",
                  alignContent: "center",
                  backgroundColor: "#473ff4",
                  color: "white",
                  padding: "10px",
                }}
              >
                TRY LURPH FOR FREE{" "}
              </button>
              
              
            </a>
          </center>
          
        </div>
        
      </div>
{/*   <div style={{height:"300px",backgroundColor:"white",width:"100%"}}></div>
 */}    </div>
  );
};
export default Header;
