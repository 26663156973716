import React, { useState, useEffect, useRef } from "react";
import PublishIcon from "@material-ui/icons/Publish";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withFirebase } from "../../firebase/index";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  addAssets,
  getAssetList,
  deleteAsset,
  assetDownloadUrl,
} from "../../actions/asset";
import {
  ContextMenu,
  VideoEditorSideContainerSubHeader,
  AssetLoaderUI,
  VideoEditorSideContainerSubSidebar,
} from "../index";
import "./VideoEditorSideContainerMyMedia.css";
import { ellipsize } from "../../Utils/helpers";
import { getThumbnailURL, getVideoThumbnailURL } from "../../Utils/helpers";

const VideoEditorSideContainerMyMedia = ({
  setPreviewFile,
  firebase,
  history,
  dispatch,
  asset,
}) => {
  let req = useRef({ cancel: null });
  const outerRef = useRef(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  // const [curDirectory, setCurDirectory] = useState([]);

  /* const doubleClickFolder = (event) => {
    let cur = [...asset.curDirectory];
    cur.push(`${event.target.id}`);
    dispatch(getAssetList(user.id, token, cur, req.current));
  };

  const prevDirectory = (event) => {
    let cur = [...asset.curDirectory];
    if (cur.length !== 0) {
      cur.pop();
    }

    if (cur.length !== asset.curDirectory.length) {
      dispatch(getAssetList(user.id, token, cur, req.current));
    }
  };
 */

  useEffect(() => {

    /* for (var key in imgUrls) {
      console.log(key); // logs keys in myObject
      console.log(imgUrls[key]); // logs values in myObject
    } */
    let cancel = req.current;
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        history.push("/login");
      } else {
        userAuth.getIdToken(true).then(async (token) => {
          dispatch(
            getAssetList(userAuth.uid, token, asset.curDirectory, req.current)
          );
          setToken(token);
          localStorage.setItem("token", token)
        });

        setUser(userAuth);
      }
    });
    // return () => {
    //   cancel.cancel();
    // };
    // eslint-disable-next-line
  }, []);
  // console.log("userAuth ", user.uid);
  //If user Drag File Over DropArea
  const handleDragOver = (event) => {
    console.log("dsdsdsd");
    event.preventDefault(); //preventing from default behaviour
    let dropArea = document.getElementById("drag-area");
    let dragText = document.getElementById("headerText");
    dropArea?.classList.add("drag-active");
    if (dragText) {
      dragText.textContent = "Release to Upload File";
    }
  };
  //If user leave dragged File from DropArea
  const handleDragLeave = (e) => {
    console.log("dsdsdsd");
    console.log("user Id ", user.uid);
    let dropArea = document.getElementById("drag-area");
    let dragText = document.getElementById("headerText");

    if (dropArea) {
      dropArea.classList.remove("drag-active");
    }
    if (dragText) {
      dragText.textContent = "Drag & Drop to Upload File";
    }
  };

  //If user drop File on DropArea
  const handleDrop = (e) => {
    e.preventDefault(); //preventing from default behaviour
    alert("In HandleDrop function");
    // e.dataTransfer.files gets reset as events don't wait for async call to complete
    let files = [];
    for (let i = 0; i < e.dataTransfer.files["length"]; i++) {
      files.push(e.dataTransfer.files[i]);
    }
    // console.log(files);
    /* for (let i = 0; i < files.length; i++) {
      console.log(files[i]);
     /*  if (files[i].type.startsWith("video")) {
        if (files[i]) {
          dispatch(
            assetDownloadUrl(
              files[i].id,
              files[i].token,
              files[i].assetId,
              files[i].fileName
            )
          );
        }
      } 
    }
    alert(asset.downloadUrl) */

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.startsWith("image")) {
        console.log("Files ", files[i]);
        getThumbnailURL(files[i])
          .then((res) => {
            console.log("vaibhavi ", res);
            dispatch(
              addAssets(
                user.uid,
                token,
                files[i],
                files.getDownloadURL(),
                asset.curDirectory,
                {
                  url: res,
                },
                req.current
              )
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (files[i].type.startsWith("video")) {
        getVideoThumbnailURL(files[i])
          .then((res) => {
            dispatch(
              addAssets(
                user.uid,
                token,
                files[i],
                asset.curDirectory,
                res,
                req.current
              )
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (files[i].type.startsWith("audio")) {
        getVideoThumbnailURL(files[i]).then((res) => {
          dispatch(
            addAssets(
              user.uid,
              token,
              files[i],
              asset.curDirectory,
              {
                duration: res.duration,
              },
              req.current
            )
          );
        });
      }
    }
  };
  const handleFileUpload = () => {
    let input = document.getElementById("ipf");
    // let dropArea = document.getElementById("drag-area");
    input.click(); //if user click on the button then the input also clicked
    //console.log("imgurls " ,imgUrls);

    input.addEventListener("change", function (e) {
      e.preventDefault();
      // console.log("files", e.dataTransfer.files);
      console.log("video files = ", e.target.result);
      let files = [];
      for (let i = 0; i < e.target.files["length"]; i++) {
        files.push(e.target.files[i]);
      }
      console.log("files  =  ", files);
      for (let i = 0; i < files.length; i++) {
        console.log(files[i]);
        if (files[i].type.startsWith("image")) {
          getThumbnailURL(files[i]).then((res) => {
            dispatch(
              addAssets(
                user.uid,
                token,
                files[i],
                asset.curDirectory,
                {
                  url: res,
                },
                req.current
              )
            );
          });
        } else if (files[i].type.startsWith("video")) {
          getVideoThumbnailURL(files[i]).then((res) => {
            dispatch(
              addAssets(
                user.uid,
                token,
                files[i],
                asset.curDirectory,
                res,
                req.current
              )
            );
          });
        } else if (files[i].type.startsWith("audio")) {
          getVideoThumbnailURL(files[i])
            .then((res) => {
              dispatch(
                addAssets(
                  user.uid,
                  token,
                  files[i],
                  asset.curDirectory,
                  {
                    duration: res.duration,
                  },
                  req.current
                )
              );
            })
            .catch((err) => {
              console.log("ds", err);
            });
        }
      }
      //   dropArea.classList.add("drag-active");
    });
  };

  const handleVideoClick = (assetId, type, fileName, thumbnailUrl) => {
    setPreviewFile({
      id: user.uid,
      token,
      assetId,
      type,
      fileName,
      thumbnailUrl,
      cancel: req.current,
    });
  };

  const onDragStart = (e, assetId, type, fileName, thumbnailUrl, duration) => {
    // e.persist();
    //alert(thumbnailUrl);
    //console.log(key); // logs keys in myObject
    //console.log(JSON.stringify(imgUrls)); // logs values in myObject

    /*  let imgName = [];
      let imgUrls = []; */
    //console.log("userID", user.uid);

    /* let imgnames = [];
      imgName = asset.assetList;
      for (let i = 0; i < imgName.length; i++) {
        imgnames.push(imgName[i].id + "-" + imgName[i].fileName);
      } */
    /* for (let i = 0; i < imgnames.length; i++) {
        var storageRef = firebase.storage.ref(
          "videoEditor/users/yvo5VWu5NZfOtyBI5ZdtfoWuB222/myAssets/" +
            assetId +"-"+fileName
        );
        storageRef.getDownloadURL().then(function (url) {
          imgUrls.push(url);
        });
      } */

    let obj = {
      uid: user.uid,
      token: token,
      mimeType: type,
      assetId: assetId,
      fileName: fileName,
      thumbnailUrl: thumbnailUrl,
      duration: duration,
    };

    console.log(obj);
    e.dataTransfer.setData("data", JSON.stringify(obj));
    e.dataTransfer.setData("cancel", JSON.stringify(req.current));
    e.dataTransfer.effectAllowed = "move";
  };

  const handleVideoDelete = (assetId, fileName) => {
    dispatch(
      deleteAsset(
        user.uid,
        token,
        assetId,
        fileName,
        asset.curDirectory,
        req.current
      )
    );
  };

  return (
    <>
      <VideoEditorSideContainerSubHeader>
        <div className="lft">
          <Button
            style={style.btn}
            color="default"
            size="small"
            startIcon={<PublishIcon />}
            onClick={handleFileUpload}
          >
            Import
          </Button>
        </div>
        <div className="rgt">
          <input style={{
            outline: "none",
            border: "none",
            padding: "0 5px",
            backgroundColor: "#f0f0f0"
          }} type="search" placeholder="Search media" />
        </div>
      </VideoEditorSideContainerSubHeader>
      <div className="VideoEditor-side-all-my-media">
        <VideoEditorSideContainerSubSidebar>
          My Media
        </VideoEditorSideContainerSubSidebar>
        {asset.loading ? (
          <AssetLoaderUI />
        ) : (
          <div className="VideoEditor-side-container-my-media">
            <input id="ipf" type="file" hidden multiple />

            <div
              onDragOver={(e) => handleDragOver(e)}
              onDragLeave={(e) => handleDragLeave(e)}
              onDrop={(e) => handleDrop(e)}
              id="drag-area"
            >
              {asset.assetList.length === 0 ? (
                <>
                  <div className="icon">
                    <CloudUploadIcon />
                  </div>
                  <header id="headerText">Drag & Drop File</header>
                  <span>OR</span>
                  <Button
                    onClick={handleFileUpload}
                    variant="contained"
                    color="primary"
                  >
                    Browse File
                  </Button>
                </>
              ) : (
                <div className="image-gallery" ref={outerRef}>
                  <ContextMenu
                    handleVideoDelete={handleVideoDelete}
                    outerRef={outerRef}
                  />
                  {asset.assetList.map((file) => (
                    <div
                      key={file.id}
                      className="img-con"
                      draggable
                      onDragStart={(e) =>
                        onDragStart(
                          e,
                          file.id,
                          file.mimeType,
                          file.fileName,
                          file.thumbnailUrl,
                          file.duration
                        )
                      }
                    >
                      <img
                        src={file.thumbnailUrl}
                        alt={file.fileName}
                        title={file.fileName}
                        className="im"
                        id={file.id}
                        onClick={() =>
                          handleVideoClick(
                            file.id,
                            file.mimeType.includes("audio")
                              ? "audio"
                              : file.mimeType.includes("video")
                                ? "video"
                                : "image",
                            file.fileName,
                            file.thumbnailUrl
                          )
                        }
                      />
                      <span id="name">
                        {ellipsize(
                          file.fileName.split(".").slice(0, -1).join("."),
                          15
                        )}
                      </span>
                      <span id="duration">
                        {file.duration &&
                          new Date(file.duration * 1000)
                            .toISOString()
                            .substr(11, 8)}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  asset: state.asset,
});
export default connect(mapStateToProps)(
  withFirebase(VideoEditorSideContainerMyMedia)
);
const style = {
  btn: {
    fontSize: "1rem",
  },

};

// file.fileName.split(/[s. ]+/)[0];
