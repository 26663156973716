import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FormGroup, Input } from "reactstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: { width: "20vw" },
  dialogContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  dialogContainerItem: {
    border: "1px solid #000",
    borderRadius: "5px",
    padding: "10px",
    textAlign: "center",
    background: "rgba(0,0,0,0.2)",
    transition: "all 0.2s",
    cursor: "pointer",
    "&:hover": {
      background: "#fff",
      color: "#000",
    },
  },
  selectedTrack: {
    border: "1px solid blue",
    background: "darkcyan",
    color: "#fff",
  },
}));
const VideoTrackAddDialog = ({
  addTrackOpen,
  setAddTrackOpen,
  trackName,
  setTrackName,
  selectTrack,
  setSelectTrack,
  videoTracksList,
  audioTracksList,
  setAudioTracksList,
  setVideoTracksList,
}) => {
  const classes = useStyles();
  // const handleClickOpen = () => {
  //   setAddTrackOpen(true);
  // };

  const handleClose = () => {
    setAddTrackOpen(false);
  };
  const handleSelectTrack = (type) => {
    setSelectTrack(type);
    setTrackName(type);
  };

  const handleSubmit = () => {
    if (selectTrack === "Video") {
      const values = [...videoTracksList, { name: trackName, files: [] }];
      // values[index][trackName] = trackName;
      setVideoTracksList(values);
    } else {
      const values = [...audioTracksList, { name: trackName, files: [] }];
      // values[index][trackName] = trackName;
      setAudioTracksList(values);
    }
    setSelectTrack("Video");
    setTrackName("Video");
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={addTrackOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Add a Track"}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className={classes.dialogContainer}>
            <div
              onClick={() => handleSelectTrack("Video")}
              className={`${classes.dialogContainerItem} ${
                selectTrack === "Video" && classes.selectedTrack
              }`}
            >
              <h4>Video</h4>
              <span>Video,Photo</span>
            </div>
            <div
              onClick={() => handleSelectTrack("Audio")}
              className={`${classes.dialogContainerItem} ${
                selectTrack === "Audio" && classes.selectedTrack
              }`}
            >
              <h4>Audio</h4>
              <span>Music,Voice</span>
            </div>
          </div>
          <FormGroup>
            <Input
              id="name"
              type="text"
              name="name"
              placeholder="Enter track name..."
              value={trackName}
              onChange={(e) => setTrackName(e.target.value)}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Add Track
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default VideoTrackAddDialog;
