import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";
import { FormGroup, Input, Label } from "reactstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateNewVideoNameModel({
  open,
  setOpen,
  handleCreateNewVideoButton,
  projectName,
  errors,
  setProjectName,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Create New Project"}
        </DialogTitle>
        <DialogContent>
          {errors.length > 0 && (
            <Alert variant="filled" severity="error">
              {errors}
            </Alert>
          )}
          <FormGroup>
            <Label for="name">Enter Name</Label>
            <Input
              autoFocus
              id="name"
              type="text"
              name="name"
              placeholder="Enter Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Back
          </Button>
          <Button
            disabled={!projectName}
            onClick={handleCreateNewVideoButton}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
