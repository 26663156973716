const addFrames = {
  
  frames: {}
}

const framesReducer = (state = addFrames, framesUpdated) => {
 
  
          return {
              type:"SET_FRAMES",
              frames:framesUpdated
          }
     
      
  }


export default framesReducer