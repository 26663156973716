import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "../reducers";
let store;
let middleware = [thunk];
if (process.env.NODE_ENV === `development`) {
  middleware.push(logger);
}
export function configureStore() {
  store = createStore(rootReducer, compose(applyMiddleware(...middleware)));
  return store;
}
