import React from "react";
import useContextMenu from "../../Utils/Hooks/useContextMenu";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import "./ContextMenu.css";
const ContextMenu = ({ handleVideoDelete, outerRef }) => {
  const { xPos, yPos, menu, id, fileName } = useContextMenu(outerRef);
  const handleDeleteBtn = () => {
    handleVideoDelete(id, fileName);
  };
  const handleCreateFolderBtn = () => {
    console.log("create folder btn clicked!!!");
  };
  return (
    menu && (
      <ul className="context-menu" style={{ top: yPos, left: xPos }}>
        <li className="list" onClick={handleDeleteBtn}>
          <DeleteForeverIcon fontSize="small" /> Delete
        </li>
        <li className="list" onClick={handleCreateFolderBtn}>
          <CreateNewFolderIcon fontSize="small" /> Create Folder
        </li>
      </ul>
    )
  );
};

export default ContextMenu;
