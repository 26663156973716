const API_ROOT =
  process.env.NODE_ENV
    !== "development"
    ? "https://backend-lurph.web.app/api/v2/videoeditor/myAssets"
    : "http://localhost:5001/lurph-dev/us-central1/app/api/v2/videoeditor/myAssets";
export const APIUrls = {
  //   login: () => `${API_ROOT}/login/`,
  //   signup: () => `${API_ROOT}/create/`,
  //   // to send otp
  //   forgotPassword: () => `${API_ROOT}/forgot-password/`,
  //   // to resend otp
  //   forgotPasswordResend: () => `${API_ROOT}/forgot-password-resend/`,
  //   forgotPasswordCheck: () => `${API_ROOT}/forgot-password-check/`,
  //   // verification email
  //   emailVerification: () => `${API_ROOT}/verification/email-otp/`,
  //   emailVerificationCheck: () => `${API_ROOT}/verification/email-otp/check/`,
  //   forgotPasswordChangePassword: () => `${API_ROOT}/reset-password/`,
  //   // user change password with old password
  //   ChangePassword: () => `${API_ROOT}/change-password/`,
  //   // home
  //   home: () => `${API_ROOT}/home/`,
  //   // refresh token
  //   refresh: () => `${API_ROOT}/login/refresh/`,
  //   // refresh token
  //   logout: () => `${API_ROOT}/logout/`,

  //   //********************** accounts
  //   socialAccountsLogin: (platform) => `/api/user/accounts/login/${platform}`,

  //   //********************** posts

  /* 
      ALL THE PROJECT RELATED ROUTES
    */

  // Fetch projects with userId and Authtoken
  getProjectList: (id) => `${API_ROOT}/getProjects/${id}`,

  // fetch default project
  getDefaultProject: (id) => `${API_ROOT}/${id}`,

  // fetch particular project  NOTICE THIS HAS TWO PARAMETERS
  getProject: (id, projectId) => `${API_ROOT}/${id}/${projectId}`,

  // get project state
  getProjectState: (id) => `${API_ROOT}/getProjectState/${id}`,

  // create new project
  createNewProject: (id) => `${API_ROOT}/createNewProject/${id}`,

  // modify project name
  modifyProjectName: (id) => `${API_ROOT}/modifyProjectName/${id}`,

  // modify project state
  modifyProjectState: (id) => `${API_ROOT}/modifyProjectState/${id}`,

  // delete project
  deleteProject: (id) => `${API_ROOT}/deleteProject/${id}`,


  /* 
      ALL THE ASSET RELATED ROUTES
    */

  // get asset list of the user
  getAssetList: (id) => `${API_ROOT}/getAssetList/${id}`,

  getAssetUploadUrl: (id) => `${API_ROOT}/getAssetUploadUrl/${id}`,

  // add assets (multiple files allowed at backend)
  addAssets: (id) => `${API_ROOT}/addAsset/${id}`,

  // delete project
  deleteAsset: (id) => `${API_ROOT}/deleteAsset/${id}`,

  // download asset
  downloadUrl: (id) => `${API_ROOT}/downloadAsset/${id}`,



  createNewNavbarEdit: (id) => `${API_ROOT}/createNewNavbarEdit/${id}`,

  getNavbareditsGlobalList: (id) => `${API_ROOT}/getNavbareditsGlobalList/${id}`,

  DeleteDataFromNavbar: (id) => `${API_ROOT}/DeleteDataFromNavbar/${id}`,

  ModifyDatatoNavbar: (id) => `${API_ROOT}/ModifyDatatoNavbar/${id}`,



  NavbarMenuUpload: (id) => `${API_ROOT}/NavbarMenuUpload/${id}`,

  getNavbarMenu: (id) => `${API_ROOT}/getNavbarMenu/${id}`,

  //export the user
  exportList: () => `exportVideo`,

  //   // create post
  //   createPost: () => `${API_ROOT}/post/create/`,
  //   // edit post
  //   editPost: (id) => `${API_ROOT}/post/update/${id}`,

  //   //********************** profile

  //   // update user profile
  //   updateProfile: (id) => `${API_ROOT}/profile-update/${id}`,
  //   // create user profile
  //   createProfile: () => `${API_ROOT}/profile/`,

  //   //********************** templates

  //   // create user template
  //   postUserTemplate: () => `${API_ROOT}/template/create/`,
  //   // fetch user template
  //   fetchUserTemplate: (title) => `${API_ROOT}/template/fetch/?search=${title}`,

  //   fetchGccTemplate: (category, sub_category, title) =>
  //     `${API_ROOT}/template/public-fetch/?category=${category}&sub_category=${sub_category}&search=${title}`,
  //   // ************

  //   // Contact Us
  //   postContactUs: () => `${API_ROOT}/query/`,
  //   // *********
};
