import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, AppBar, Typography } from "@material-ui/core/";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import WallpaperIcon from "@material-ui/icons/Wallpaper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    textTransform: "capitalize",
    color: "#000",
    fontWeight: "700",
  },
}));

// drawer item list
const Headercomponents = {
  PhotoLibraryIcon: PhotoLibraryIcon,
  PermMediaIcon: PermMediaIcon,
  TextFieldsIcon: TextFieldsIcon,
  AudiotrackIcon: AudiotrackIcon,
  ViewCarouselIcon: ViewCarouselIcon,
  WallpaperIcon: WallpaperIcon,
};
const HeaderItems = [
  { name: "Stock Media", icon: "PhotoLibraryIcon" },
  { name: "My Media", icon: "PermMediaIcon" },
  { name: "Text", icon: "TextFieldsIcon" },
  { name: "Audio", icon: "AudiotrackIcon" },
  { name: "Transitions", icon: "ViewCarouselIcon" },
  { name: "Backgrounds", icon: "WallpaperIcon" },
];
export default function VideoEditorSideContainerHeader({
  selectTab,
  setSelectTab,
}) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };
  const createComponent = (icon) => {
    let Component = icon;
    return <Component />;
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={selectTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {HeaderItems.map(({ name, icon }) => (
            <Tab
              key={name}
              label={
                <Typography className={classes.tabs}>
                  <span>{createComponent(Headercomponents[icon])}</span>
                  &nbsp;{name}
                </Typography>
              }
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
