import "./AssetLoaderUI.css";
const AssetLoaderUI = ({ style }) => {
  return (
    <div id="AssetLoaderUI" style={{ height: style && "100vh" }}>
      <div className="loader">
        <div className="upperb ball"></div>
        <div className="rightt ball"></div>
        <div className="lowerr ball"></div>
        <div className="leftt ball"></div>
      </div>
    </div>
  );
};

export default AssetLoaderUI;
