import {
  // UPDATE_PROJECTS_SUCCESS,
  // UDATE_PROJECTS_START,
  PROJECT_META_SUCCESS,
  LOADING_START,
  LOADING_ERROR,
  PROJECT_LIST_SUCCESS,
  PROJECT_STATE_SUCCESS,
  PROJECT_CREATE_SUCCESS,
  PROJECT_NAME_MODIFY_SUCCESS,
  PROJECT_STATE_MODIFY_SUCCESS,
  PROJECT_DELETE_SUCCESS,
  CLEAR_PROJECT_STATE,
  //   UPDATE_DRAFTS,
  //   UPDATE_SCHEDULE,
  //   // ADD_POST,
  //   POST_CREATE_SUCCESS,
  //   POST_UPDATE_SUCCESS,
  //   CLEAR_POST_STATE,
  //   // ADD_COMMENT,
  //   // UPDATE_POST_LIKE,
} from "../actions/actionTypes";

const initialProject = {
  projectMeta: null,
  success: false,
  projectListSuccess: false,
  projectStateSuccess: false,
  error: false,
  loading: false,
  projectList : [],
  projectState : {},
};

// cannot delete opened project
// every getProjectMeta call will be followed by getProjectState call 

export default function currentProject(state = initialProject, action) {
  switch (action.type) {
    case CLEAR_PROJECT_STATE: 
    return {
      ...state,
      loading: false,
      success:false,
      projectListSuccess:false,
      projectStateSuccess:false,
    };

    case LOADING_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_META_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error : false,
        projectMeta: action.projectMeta,
      };

    case LOADING_ERROR:
      return {
        ...state,
        success : false,
        error : true,
        loading: false,
      };

    case PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectListSuccess: true,
        loading: false,
        error : false,
        projectList : action.projectList,
      }

    case PROJECT_STATE_SUCCESS:
      return {
        ...state,
        projectStateSuccess: true,
        loading: false,
        error : false,
        projectState : action.projectState,
      }
    
    case PROJECT_CREATE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error : false,
        projectMeta: action.projectMeta,
      };
    
    case PROJECT_NAME_MODIFY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error : false,
        projectMeta : {
          ...state.projectMeta,
          name : action.newName,
        }
      };

    case PROJECT_STATE_MODIFY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error : false,
        projectState : {
          ...state.projectState,
          timeline : action.timeline,
        }
      };

    case PROJECT_DELETE_SUCCESS:
      let updatedProjectList = state.projectList;
      if(state.projectList.length !== 0) {  // filter the deleted projectId
        updatedProjectList = 
          updatedProjectList.filter((project) => {
            return project.id !== action.projectId;
          });
      }

      return (updatedProjectList.length === 0) ? {  // that would mean current project has been deleted
        ...state,
        projectList : updatedProjectList,
        projectMeta : null,
        projectState : {},
        success: true,
        loading: false,
        error : false,
      } : {
        ...state,
        projectList : updatedProjectList,
        success: true,
        loading: false,
        error : false,
      }
    // case UPDATE_DRAFTS:
    //   return {
    //     ...state,
    //     success: null,
    //     drafts: action.posts,
    //   };
    // case UPDATE_SCHEDULE:
    //   return {
    //     ...state,
    //     success: null,
    //     schedules: action.posts,
    //   };

    // case POST_CREATE_SUCCESS:
    // case POST_UPDATE_SUCCESS:
    //   return {
    //     ...state,
    //     success: action.success,
    //   };
    // case ADD_POST:
    //   return [action.post, ...state];
    // case ADD_COMMENT:
    // const newPosts = state.map((post) => {
    //   if (post._id === action.postId) {
    //     return {
    //       ...post,
    //       comments: [action.comment, ...post.comments],
    //     };
    //   }

    //   return post;
    // });
    // return newPosts;
    // case UPDATE_POST_LIKE:
    //   const updatedPosts = state.map((post) => {
    //     if (post._id === action.postId) {
    //       return {
    //         ...post,
    //         likes: [...post.likes, action.userId],
    //       };
    //     }

    //     return post;
    //   });
    //   return updatedPosts;
    default:
      return state;
  }
}
