import React from "react";
import "./VideoEditorTimelineFrame.css";
import Draggable from "react-draggable";
const VideoEditorTimelineFrame = ({
  trackIndex,
  index,
  frame,
  layerKey,
  dragEvent,

}) => {
  const handleMouseClick = (e) => {
    dragEvent(e, layerKey, trackIndex, index);

  };
  function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  console.log("frame = ", frame);
  var randomColor = generateRandomColor();
  return (
    <Draggable axis="x">
      <span
        className="timeline-editor__frames-layer__item"
        style={{
          width: `${frame.duration * 2.875 * 2}px`,
          backgroundColor: randomColor,
          borderRadius: "7px",
        }}
        onMouseDown={(e) => handleMouseClick(e)}
        draggable
      >
        {/* 
        <div height="100%" width="100%" alt="" >
          {/* {frame.thumbnailUrl}
        </div> */}
      </span>
    </Draggable>
  );
};
export default VideoEditorTimelineFrame;
