import React, { useState, useEffect, useRef } from 'react';
import { GridList, GridListTile } from '@material-ui/core';
import { Card, CardMedia, CardActionArea } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { withFirebase } from '../../firebase';
import { withRouter } from 'react-router';
import { FavoriteBorderOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { Container } from 'react-bootstrap';
import { ellipsize } from "../../Utils/helpers";
import './VideoEditorStockMediaResults.css'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    margin: 5,
    backgroundColor: 'rgb(15,15,15)',
    borderRadius: 2,
  },
});


const VideoEditorStockMediaResults = ({ images, setPreviewFile, setImgUrl, setVidUrl, firebase, history }) =>
{
  const classes = useStyles();
  const [user, setUser] = useState(null);

  const userId = useRef("");
  useEffect(() =>
  {
    //let cancel = req.current;
    firebase.auth.onAuthStateChanged(async (userAuth) =>
    {
      if (!userAuth)
      {
        history.push("/login");
      } else
      {
        userId.current = userAuth.uid;
        setUser(userAuth);
      }
    });
  }, []);

  const onDragStart = (e, type, tags, url) =>
  {
    e.persist();
    // console.log("abcdsdzvc");
    e.preventDefault();
    let obj = {
      uid: user.uid,
      mimeType: type,
      fileName: tags,
      url
    };
    //console.log(obj)
    //   e.dataTransfer.setData("text", obj);
    localStorage.setItem("imagekey", JSON.stringify(obj))
    //e.dataTransfer.setData("cancel", JSON.stringify(req.current));
    e.dataTransfer.effectAllowed = "move";
  };

  const handleClick = (Url) =>
  {
    setVidUrl(null);
    setPreviewFile(null);
    setImgUrl(Url);
  };

  console.log(images);
  if (images)
  {
    return (
      <div style={{ width: '100%', backgroundColor: "rgb(15,15,15)" }}>
        <GridList style={{ height: '100%', width: '100%' }} cols={5}>
          {images.map(img => (
            <div className='container' style={{ margin: 10, height: 90, width: 150 }} onClick={() => handleClick(img.largeImageURL)}>
              <div className='vid' style={{ width: 150, height: 80, justifyContent: 'center' }}>
                <img onDrag={(e) => { onDragStart(e, img.type, img.tags, img.largeImageURL) }}
                  style={{ width: '100%', height: '100%' }}
                  src={img.largeImageURL}
                  alt=""
                />
              </div>
              <div className='text'>
                <IconButton style={{ height: 20, width: 20 }}><FavoriteBorderOutlined style={{ height: 15, width: 15 }} /></IconButton>
              </div>
              <div className='text' style={{ backgroundColor: 'black', width: '100%', height: 20, opacity: 0.7, bottom: 8, textAlign: 'center' }}>
                <p style={{ color: 'white', fontSize: 11, }}>
                  {ellipsize(img.tags.split(",").slice(0, -1).join(","), 30)}
                </p>
              </div>
            </div>

          ))}
        </GridList>
      </div>
    );
  } else
  {
    return (
      <div>No Results</div>
    );
  }
}
const Component = withFirebase(VideoEditorStockMediaResults);
export default withRouter(Component);