import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "./Dropdown.css"

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: "rgb(245,245,245)",
    color: "black",
    position: "absolute",
    // top: "100%",
    // left: 0,
    // width:"1000px",
    // height: "400px",
    zIndex: "100",
    // borderRadius: "10px",
    // boxShadow: "2px 2px 2px 2px #888888"
    // transform:"translateY(-3em)",
    // transition:"all 0.3s ease 0s linear 0.3s zIndex 0s linear 0.1s",
    borderBottomLeftRadius: "7px",
    borderBottomRightRadius: "7px",
    animation: "downOut 300ms ease-in-out forwards",
    transformOrigin: "center center",
    // transform: "translateY(3em)",
    // transform: "translateX(3em)",
    // transition: "all 0.3s ease 0s linear 0.3s z-index 0s linear 0.1s",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const DropdownMenu = ({ NavbarDslmenu }) => {
  const classes = useStyles();
  // console.log(NavbarDslmenu);

  useEffect(() => {
    return () => {
    }
  }, [])

  return (
    <>
      <div className="MenuRoot">
        <div
          style={{
            display: "flex",
            flexDirection: 'row',
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
            // paddingTop: "30px"
            margin: "10px",
          }}
        >
          {NavbarDslmenu.map((item, index) => (
            <>
              <div key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // paddingLeft: "30px",
                  // paddingTop: "15px",
                  // paddingRight: "30px",
                  // paddingBottom: "20px",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  // backgroundColor: "grey",
                }}
              >
                <div style={{
                  fontSize: "14px",
                  fontWeight: 550,
                  color: "black",
                  width: "170px",
                  padding: "10px",
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  // color: "rgb(35,35,35)",
                  // fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  // fontWeight: 500;
                  lineHeight: "1.2876em",
                  letterSpacing: "0.009em",
                }}
                  key={index}>
                  {item.name.Name}
                </div>
                {item.subsubmenuslist.map((items, index) => (
                  <div key={index}
                    style={{
                      // paddingTop: "10px"
                    }}>
                    <Link
                      className="dropdown-link"
                      to={items.name.Link}
                      key={index}
                    >
                      <div className='NavBarSubSubMenuName'>
                        {items.name.Name}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
      {/* // </>
    //   <div className={classes.root}>
    //     <div style={{ display: "flex", flexDirection: "row", paddingLeft: "20px", paddingTop: "30px" }}>
    //       <>
    //         <div style={{ display: "flex", flexDirection: "column", paddingLeft: "30px", paddingTop: "15px", paddingRight: "10px", paddingBottom: "20px" }}>
    //           {NavbarDslmenu.map((item, index) => (
    //             <button
    //               // className={item.cName}
    //               to={item.link}
    //               key={index}
    //               style={{ right: "0", fontSize: "1rem", border: "none", backgroundColor: "white", paddingBottom: "15px" }}
    //             >
    //               <div style={{ right: "0", fontSize: "1rem" }}>
    //                 {item.Name}
    //               </div>
    //             </button>
    //           ))}
    //         </div>
    //       </>
    //     </div>
    //   </div>
    // </>
    // <>
    //   {NavbarDslmenu.map((item) => (
    //     <>
    //     <button
    //       // className={item.cName}
    //       to={item.link}
    //       style={{ right: "0", fontSize: "1rem", border: "none", backgroundColor: "white", paddingBottom: "15px" }}
    //     >
    //       <div style={{ right: "0", fontSize: "1rem" }}>
    //         {item.Name}
    //       </div>
    //     </button>
    //     </>
    //   ))} */}
    </>
  );
}

export default DropdownMenu