import {
  ASSET_DELETE_SUCCESS,
  ASSET_LIST_SUCCESS,
  ASSET_UPLOAD_SUCCESS,
  DOWNLOAD_ASSET_SUCCESS,
  LOADING_ERROR,
  LOADING_START,
} from "../actions/actionTypes";

const initialAsset = {
  success: false,
  error: false,
  loading: false,
  assetList: [],
  downloadUrl: null,
  curDirectory: [],
};

export default function allAsset(state = initialAsset, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };

    case LOADING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    case ASSET_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        assetList: action.assetList,
        curDirectory : action.directory,
      };

    case ASSET_DELETE_SUCCESS:
      let newAssetList = state.assetList;

      newAssetList = newAssetList.filter((asset) => {
        return asset.id !== action.assetId;
      });

      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        downloadUrl: newAssetList.length===0 && null,
        assetList: newAssetList,
      };

    case ASSET_UPLOAD_SUCCESS:

      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        assetList: [...action.assetList, ...state.assetList],
      };

    case DOWNLOAD_ASSET_SUCCESS:
      return {
        ...state,
        // loading: false,
        // success: true,
        // error: false,
        downloadUrl: action.url,
      };
    default:
      return state;
  }
}
