import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// import axios from 'axios';
import { withFirebase } from "../../firebase/index";
import { connect } from "react-redux";
import {
  getDefaultProject,
  getProjectState,
  clearProjectState,
} from "../../actions/project";
import {
  VideoEditorNavbar,
  VideoEditorSubNavbar,
  VideoEditorSidebar,
  VideoEditorSideContainer,
  VideoEditorPreview,
  VideoEditorTimeline,
  Upperhalf,
  LowerHalf,
} from "../../components";

import "./VideoEditorHome.css";
import VideoEditorSidebar2 from "../../components/VideoEditorSidebar2/VideoEditorSidebar2";
import GlobalState from "../../GlobalState/GlobalState";
import zIndex from "@material-ui/core/styles/zIndex";
import RightSideBar from "../../components/Rightsidebar/RightSideBar.js"

const VideoEditorHome = ({
  project,
  dispatch,
  firebase,
  history,
  toggleMainSideBar,
  showMainSidebar,
}) => {
  const projectId = useParams().projectId;

  const [sideBarOpen, setSideBarOpen] = useState(1);
  const [rightsideBarOpen, setRightsideBarOpen] = useState(0);
  const [previewFile, setPreviewFile] = useState(null);
  const [vidUrl, setVidUrl] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [VideoEditorPreviewWidth, setVideoEditorPreviewWidth] = useState(100);
  const [VideoEditorPreviewHeight, setVideoEditorPreviewHeight] = useState(100);
  const [showDia, setShowDia] = useState(false);

  let userId = useRef(null);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        history.push("/login");
      } else {
        userId.current = userAuth.uid;
        userAuth.getIdToken(true).then(async (token) => {
          // call for ProjectState
          dispatch(getProjectState(userId.current, token, projectId));
          setToken(token);
        });
        setUser(userAuth);
      }
    });
  }, []);

  // useEffect(() => {
  //   if(token !== null) {
  // console.log(token);
  // makeRequest();
  // dispatch(getProjects(userId.current, token))
  // dispatch(getDefaultProject(userId.current, token));
  //   }
  // });
  return (
    user && (
      <>
        <div id="editor-page-container">
          <VideoEditorSubNavbar
            setSideBarOpen={setSideBarOpen}
            sideBarOpen={sideBarOpen}
            toggleMainSideBar={toggleMainSideBar}
            showMainSidebar={showMainSidebar}
            history={history}
            setRightsideBarOpen={setRightsideBarOpen}
            rightsideBarOpen={rightsideBarOpen}
            showDia={showDia}
            setShowDia={setShowDia}
          />

          <div className="grid-container-all">
            <VideoEditorSidebar
              sideBarOpen={sideBarOpen}
              setSideBarOpen={setSideBarOpen}
              setPreviewFile={setPreviewFile}
              setVidUrl={setVidUrl}
              setImgUrl={setImgUrl}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width:
                    sideBarOpen === 1 ?
                      (rightsideBarOpen === 0 ? window.innerWidth - 64 : window.innerWidth - 64 - 300) :
                      (rightsideBarOpen === 0 ? window.innerWidth - (64 + 380) : window.innerWidth - (64 + 380) - 300),
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                  backgroundColor: "rgb(55, 55, 55)",
                }}
                className="grid-container-right-side"
              >
                <Upperhalf
                  setPreviewFile={setPreviewFile}
                  previewFile={previewFile}
                  vidUrl={vidUrl}
                  setVidUrl={setVidUrl}
                  imgUrl={imgUrl}
                  setImgUrl={setImgUrl}
                  VideoEditorPreviewWidth={VideoEditorPreviewWidth}
                  setVideoEditorPreviewWidth={setVideoEditorPreviewWidth}
                  VideoEditorPreviewHeight={VideoEditorPreviewHeight}
                  setVideoEditorPreviewHeight={setVideoEditorPreviewHeight}
                  showDia={showDia}
                  setShowDia={setShowDia}
                />
                <LowerHalf sideBarOpen={sideBarOpen}>
                  <VideoEditorTimeline />
                </LowerHalf>

              </div>

            </div>
            <div
              style={{
                display: rightsideBarOpen === 0 ? "none" : "inline-block",
                // width: "300px",
                // height: "100vh",
                // zIndex: "100",
                // right: "0",
                backgroundColor: "rgb(15,15,15)",
                // // position: "fixed",
                // transform: "translate(100%,-50%)",
              }}
            >
              <RightSideBar
                VideoEditorPreviewWidth={VideoEditorPreviewWidth}
                setVideoEditorPreviewWidth={setVideoEditorPreviewWidth}
                VideoEditorPreviewHeight={VideoEditorPreviewHeight}
                setVideoEditorPreviewHeight={setVideoEditorPreviewHeight}
              />
            </div>
          </div>
        </div>
        {/* <div className="sidebarGrid1"> */}

        {/* <VideoEditorSideContainer setPreviewFile={setPreviewFile} /> */}
        {/* </div> */}
        {/* <div className="previewGrid2"> */}
        {/* <VideoEditorPreview previewFile={previewFile} /> */}
        {/* </div> */}
        {/* <div className="timelineGrid3"> */}
        {/* </div> */}
        {/* </div> */}
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
});
export default connect(mapStateToProps)(withFirebase(VideoEditorHome));
