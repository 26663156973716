import React from 'react';

export class Page404 extends React.Component{
  render() {
    return (
      <div>
        <h1>Page is not found</h1>
      </div>
    );
  };
};

export default Page404;