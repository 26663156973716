import React from "react";
import { makeStyles } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";
import Header from "./Header";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: "black",
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
    </div>
  );
};
export default Home;
