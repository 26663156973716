import {
  NAVBAR_MENU_UPLOAD_SUCCESS,
  NAVBAR_DISPLAY_SUCCESS,
  NAVMENU_LOADING_START,
  NAVMENU_LOADING_ERROR,
} from "./actionTypes";
import { APIUrls } from "../Utils/urls";
import axios from "axios";

// export function getNavbarEditsList() {
//   return (dispatch) => {
//     dispatch(loadingStart());
//     dispatch(getEditsList())
//   };
// }

// export function DeleteDataFromNavbar(id, NavbareditsId) {
//   console.log('id = ', id);
//   console.log("navbarid  =  ", NavbareditsId)
//   return (dispatch) => {
//     const url = APIUrls.DeleteDataFromNavbar(id);
//     dispatch(loadingStart());
//     let data = {
//       NavbareditsId: NavbareditsId,
//     }
//     axios.delete(url, {
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       }, data,
//     })
//       .then((res) => {
//         dispatch(DeleteData(NavbareditsId));
//       })
//       .catch((error) => {
//         console.log(error);
//         dispatch(loadingError());
//       });
//   }
// }


export function getNavbarMenu(id) {
  // console.log("calling display =  ",id);
  return (dispatch) => {
    const url = APIUrls.getNavbarMenu(id);
    dispatch(loadingStart());
    axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        dispatch(NavbarMenuList(res.data.data))
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadingError());
      });
  }
}

// export function AddNewDatatoNavbar(id, temp) {
//   console.log(temp);
//   return (dispatch) => {
//     dispatch(loadingStart());
//     const url = APIUrls.createNewNavbarEdit(id);
//     console.log(url);
//     let body = {
//       Id: temp.Id,
//       Name: temp.Name,
//       Link: temp.Link,
//       checkId:temp.checkId,
//       level:temp.level,
//       indexid:temp.indexid,
//     }
//     axios.post(url, body, {
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       },
//     })
//       .then((res) => {
//         dispatch(AddNewData(res.data.data));
//       })
//       .catch((error) => {
//         console.log(error);
//         dispatch(loadingError());
//       });
//   }
// }

export function NavbarMenuUpload(id, newdata) {
  console.log("info navbar menu", id, newdata);
  return (dispatch) => {
    let data = {
      navdata: newdata,
    }
    const url = APIUrls.NavbarMenuUpload(id);
    dispatch(loadingStart());
    axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((res) => {
        // console.log("recieved Data=",res.data);
        dispatch(ModifyMenu(res.data.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadingError());
      });
  }
};


// export function getEditsList(editsList) {
//   return {
//     type: NAVBAR_EDIT_LIST_SUCCESS,
//     editsList,
//   };
// }



export function ModifyMenu(newdata) {
  return {
    type: NAVBAR_MENU_UPLOAD_SUCCESS,
    newdata,
  };
}

export function NavbarMenuList(data) {
  return {
    type: NAVBAR_DISPLAY_SUCCESS,
    data,
  }
}

export function loadingStart() {
  return {
    type: NAVMENU_LOADING_START,
  };
}

export function loadingError() {
  return {
    type: NAVMENU_LOADING_ERROR,
  };
}
