import { Avatar, Button, Paper } from "@material-ui/core";
import "./VideoEditorSidebar.css";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import { useEffect, useState } from "react";
import VideoEditorSidebar2 from "../VideoEditorSidebar2/VideoEditorSidebar2";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

// const darkTheme = createTheme({ palette: { mode: 'dark' } });
const createComponent = (icon) => {
  let Component = icon;
  return <Component />;
};

const HeaderItems = [
  // { name: "Stock Media", icon: "PhotoLibraryIcon" },
  { name: "Media", icon: "PhotoLibraryIcon" },
  // { name: "My Media", icon: "PermMediaIcon" },
  { name: "Uploads", icon: "PermMediaIcon" },
  { name: "Text", icon: "TextFieldsIcon" },
  { name: "More" , icon: "MoreHorizIcon" },
  // { name: "Audio", icon: "AudiotrackIcon" },
  // { name: "Transitions", icon: "ViewCarouselIcon" },
  // { name: "Backgrounds", icon: "WallpaperIcon" },
];
const Headercomponents = {
  PhotoLibraryIcon: PhotoLibraryIcon,
  PermMediaIcon: PermMediaIcon,
  TextFieldsIcon: TextFieldsIcon,
  AudiotrackIcon: AudiotrackIcon,
  ViewCarouselIcon: ViewCarouselIcon,
  WallpaperIcon: WallpaperIcon,
  MoreHorizIcon: MoreHorizIcon,
};

const VideoEditorSidebar = ({
  setSideBarOpen,
  sideBarOpen,
  setPreviewFile,
  setVidUrl,
  setImgUrl,
}) => {
  const [sideBarOpen2, setSideBarOpen2] = useState([false, ""]);

  useEffect(() => {
    if (sideBarOpen === 1) {
      setSideBarOpen2([false, ""]);
    }
  }, [sideBarOpen]);

  return sideBarOpen ? (
    <div
      id="sidebarContainerMain"
      style={{ display: "flex", flexDirection: "row" , backgroundColor: "rgb(15, 15, 15)"}}

    >
      {/* <ThemeProvider theme={theme}> */}
      <Paper className="VideoEditor-sidebar-container" style={{backgroundColor: "rgb(15, 15, 15)"}}>
        {/* <ul>
            <Button>
              
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <Avatar />
                </div>
                <div style={{ fontWeight: "600" }}>Stock Media</div>
              </li>
            </Button>
            <Button>
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <Avatar />
                </div>
                <div style={{ fontWeight: "600" }}>My Media</div>
              </li>
            </Button>
            <Button>
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <Avatar />
                </div>
                <div style={{ fontWeight: "600" }}>Text</div>
              </li>
            </Button>
            <Button>
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <Avatar />
                </div>
                <div style={{ fontWeight: "600" }}>Auto</div>
              </li>
            </Button>
            <Button>
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <Avatar />
                </div>
                <div style={{ fontWeight: "600" }}>View</div>
              </li>
            </Button>
            <Button>
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <Avatar />
                </div>
                <div style={{ fontWeight: "600" }}>Back ground</div>
              </li>
            </Button>
          </ul> */}
        {HeaderItems.map(({ name, icon }, index) => (
          <Button
            key={index}
            id="sidebar-button"
            onClick={() => {
              if (sideBarOpen2[1] === name) {
                setSideBarOpen(1);
                setSideBarOpen2([false, ""]);
              } else {
                setSideBarOpen(2);
                setSideBarOpen2([true, name]);
              }
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                paddingTop: "20px",
              }}
            >
              <span>{createComponent(Headercomponents[icon])}</span>{" "}
              <div style={{ fontWeight: "600", fontSize: 7 }}>{name}</div>
            </div>
          </Button>
        ))}
      </Paper>
      
      {/* </ThemeProvider> */}
      <VideoEditorSidebar2
        sideBar2Open={sideBarOpen2}
        setPreviewFile={setPreviewFile}
        setVidUrl={setVidUrl}
        setImgUrl={setImgUrl}
      />
      <button onClick={() => {
              if (sideBarOpen2[0] === false) {
                setSideBarOpen(2);
                setSideBarOpen2([true, "Media"]);
              } else {
                setSideBarOpen(1);
                setSideBarOpen2([false, ""]);
              }
            }} 
            className="toggle_menu_btn"
            style={{
                    width:sideBarOpen === 1? "0px"  : "30px",
                    // borderRadiusRight : "20px",
                  }
            }
            >
          {sideBarOpen === 1 ? (
            <div/>
          ) : (
            <ArrowBackIosIcon/>
          )}
        </button>
    </div>
  ) : (
    false
  );
};
export default VideoEditorSidebar;