import {
  ASSET_DELETE_SUCCESS,
  ASSET_LIST_SUCCESS,
  ASSET_UPLOAD_SUCCESS,
  DOWNLOAD_ASSET_SUCCESS,
  LOADING_ERROR,
  LOADING_START,
} from "./actionTypes";

import { APIUrls } from "../Utils/urls";
// import { getBase64 } from "../Utils/helpers";
 import { thumbnailURL } from "../Utils/helpers";
import axios from "axios";

// get asset list meta data of user
export function getAssetList(id, token, directory) {
  console.log(token);
  return (dispatch) => {
    const url = APIUrls.getAssetList(id);
    console.log("url ", url);
    // sets loading state to true
    dispatch(loadingStart());

    let body = {
      directory: directory,
    };

    axios
      .post(url, body, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*",
          Authtoken: token,
        },
        // add cancel token later
      })
      .then((res) => {
        // sets assetListstate to res.data.data
        console.log("From assets " ,res.data.data);
        dispatch(assetMetaList(res.data.data, directory));
      })
      .catch((error) => {
        console.log(error); // maybe dispatch an error action

        // set error state to true
        dispatch(loadingError());
      });
  };
}

// add assets
/* export function addAssets(id, token, file, directory, ob = null) {
  return (dispatch) => {
    const url = APIUrls.getAssetUploadUrl(id);

    // sets loading state to true
    dispatch(loadingStart());
    console.log("File " ,file);
    const fileMeta = {
      fileName: file.name,
      mimeType: file.type,
    };

    axios // to avoid issue in url size, have made a POST request instead of GET
      .post(url, fileMeta, {
        // second arguement must be null or something
        headers: {
          "Content-Type": "application/json",
          Authtoken: token,
        },
      })
      .then((res) => {
        // backend expects an array of asset, do sending single asset as an array
        console.log("Add Assets Response ", res.data);
        let filesMeta = [
          {
            docId: res.data.docId,
            fileName: file.name,
            mimeType: file.type,
            fileSize: file.size,
            //url:res.data.url
          },
        ];

        if (thumbnail && thumbnail.url)
         filesMeta[0].thumbnailUrl = thumbnail.url;
        if (thumbnail && thumbnail.duration)
          filesMeta[0].duration = thumbnail.duration;

        axios // upload asset on the signed url
          .put(res.data.url, file, {
            headers: {
              "Content-Type": `${file.type}`,
            },
          })
          .then((res) => {
            const postUrl = APIUrls.addAssets(id);

            axios // add asset meta data in the firestore database
              .post(
                postUrl,
                {
                  files: filesMeta,
                  directory: directory,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authtoken: token,
                  },
                }
              )
              .then((res) => {
                // change asset Meta list state
                dispatch(newAssetsMeta(res.data.data));
              })
              .catch((err) => {
                console.log(err);

                dispatch(loadingError());
              });
          })
          .catch((error) => {
            console.log(error); // maybe dispatch an error action

            // set error state to true
            dispatch(loadingError());
          });
      })
      .catch((error) => {
        console.log(error); // maybe dispatch an error action

        // set error state to true
        dispatch(loadingError());
      });
  };
} */
// add assets
export function addAssets(
  id,
  token,
  file,
  directory,
  thumbnail = null,
  cancel
) {
  return (dispatch) => {
    const url = APIUrls.getAssetUploadUrl(id);
    console.log(url);
    // sets loading state to true
    dispatch(loadingStart());

    const fileMeta = {
      fileName: file.name,
      mimeType: file.type,
    };
    console.log("fileMetAData",file,directory);

    axios // to avoid issue in url size, have made a POST request instead of GET
      .post(url, fileMeta, {
        // second arguement must be null or something
        headers: {
          "Content-Type": "application/json",
          // Authtoken: token,
          "Access-Control-Allow-Headers":"*",
        },
        // cancelToken: axios.CancelToken((c) => (cancel.cancel = c)),
      })
      .then((res) => {
        console.log("Response from add assets",res);
        // backend expects an array of asset, do sending single asset as an array
        let filesMeta = [
          {
            docId: res.data.docId,
            fileName: file.name,
            mimeType: file.type,
            fileSize: file.size,
          },
        ];

        if (thumbnail && thumbnail.url)
          filesMeta[0].thumbnailUrl = thumbnail.url;
        if (thumbnail && thumbnail.duration)
          filesMeta[0].duration = thumbnail.duration;
        console.log("fileee", file);
        console.log("res,data,url ",res.data.url);
        axios // upload asset on the signed url
          .post(res.data.url, file, {
            headers: {
             
             "Content-Type": "application/json",
              Authtoken: token,
              "Access-Control-Allow-Headers":"authtoken",
              "Access-Control-Allow-Origin":"http://localhost:3000/"
            },
          })
          .then((result) => {
            console.log("upload asset on the signed url",result);
            const postUrl = APIUrls.addAssets(id);
            console.log("postUrl", postUrl);
            axios // add asset meta data in the firestore database
              .post(
                postUrl,
                {
                  files: filesMeta,
                  directory: directory,
                  Authtoken:token
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authtoken: token,
                    "Access-Control-Allow-Headers":"*",
                    "Access-Control-Allow-Origin":"*"
                  },
                }
              )
              .then((res) => {
                console.log("upload asset on the signed url",res);
                // change asset Meta list state
                
                dispatch(newAssetsMeta(res.data.data));
              })
              .catch((err) => {
                console.log(err);

                dispatch(loadingError());
              });
          })
          .catch((error) => {
            console.log(error); // maybe dispatch an error action

            // set error state to true
            dispatch(loadingError());
          });
      })
      .catch((error) => {
        console.log(error); // maybe dispatch an error action

        // set error state to true
        dispatch(loadingError());
      });
  };
}
export function deleteAsset(id, token, assetId, fileName, directory) {
  return (dispatch) => {
    const url = APIUrls.deleteAsset(id);

    // sets loading state to true
    dispatch(loadingStart());

    axios // made delete request into post request to avoid url size issue
      .post(
        url,
        {
          directory: directory,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authtoken: token,
          },
          params: {
            assetId: assetId,
            fileName: fileName,
          },
          // add cancel token later
        }
      )
      .then((res) => {
        // remove asset with id === assetId from AssetList
        dispatch(removeAsset(assetId));
      })
      .catch((error) => {
        console.log(error); // maybe dispatch an error action

        // set error state to true
        dispatch(loadingError());
      });
  };
}

// get download url of asset
export function assetDownloadUrl(id, token, assetId, fileName) {
  return (dispatch) => {
    const url = APIUrls.downloadUrl(id);
    console.log(url);
   
    // sets loading state to true
    // dispatch(loadingStart());

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authtoken: token,
        },
        params: {
          assetId: assetId,
          fileName: fileName,
        },
        // add cancel token later
      })
      .then((res) => {
        // get download url of asset
        dispatch(downloadURL(res.data.url));
      })
      .catch((error) => {
        console.log(error); // maybe dispatch an error action

        // set error state to true
        dispatch(loadingError());
      });
  };
}

export function removeAsset(assetId) {
  // remove asset from assetList
  return {
    type: ASSET_DELETE_SUCCESS,
    assetId,
  };
}

export function assetMetaList(assetList, directory) {
  return {
    type: ASSET_LIST_SUCCESS,
    assetList,
    directory,
  };
}

export function newAssetsMeta(assetList) {
  // backend returns an array of asset meta uploaded
  return {
    type: ASSET_UPLOAD_SUCCESS,
    assetList,
  };
}

export function downloadURL(url) {
  // backend returns an array of asset meta uploaded
  return {
    type: DOWNLOAD_ASSET_SUCCESS,
    url,
  };
}

export function loadingStart() {
  return {
    type: LOADING_START,
  };
}

export function loadingError() {
  return {
    type: LOADING_ERROR,
  };
}
