import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import { VideoEditorSideContainerMyMedia } from "../index";
import VideoEditorSideContainerMore from "../VideoEditorSideContainerMore/VideoEditorSideContainerMore";
import VideoEditorSideContainerStockMedia from "../VideoEditorSideContainerStockMedia/VideoEditorSideContainerStockMedia";
import "./VideoEditorSidebar2.css";

const VideoEditorSidebar2 = ({
  sideBar2Open,
  setPreviewFile,
  setVidUrl,
  setImgUrl,
}) => {
  useEffect(() => {
    console.log("sideBar2Open", sideBar2Open);
  });
  return (
    sideBar2Open[0] && (
     
      <Paper className="VideoEditor-sidebar2-container" style={{backgroundColor: "rgb(15, 15, 15)",}}>
        {sideBar2Open[1] === "Media" && (
          <VideoEditorSideContainerStockMedia
            setPreviewFile={setPreviewFile}
            setVidUrl={setVidUrl}
            setImgUrl={setImgUrl}
          />
        )}
        {sideBar2Open[1] === "Uploads" && (
          <VideoEditorSideContainerMyMedia
            setPreviewFile={setPreviewFile}
            setVidUrl={setVidUrl}
            setImgUrl={setImgUrl}
          />
        )}
        {sideBar2Open[1] === "More" && (
          <VideoEditorSideContainerMore/>
        )}
      </Paper>
    )
  );
};
export default VideoEditorSidebar2;
