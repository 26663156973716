import React from 'react'
import { Avatar, Button, Paper } from "@material-ui/core";
// import "./VideoEditorSidebar.css";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
// import { useEffect, useState } from "react";
// import VideoEditorSidebar2 from "../VideoEditorSidebar2/VideoEditorSidebar2";
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const createComponent = (icon) => {
  let Component = icon;
  return <Component />;
};

const HeaderItems = [
  { name: "Text", icon: "TextFieldsIcon" },
  { name: "Audio", icon: "AudiotrackIcon" },
  { name: "Transitions", icon: "ViewCarouselIcon" },
  { name: "Backgrounds", icon: "WallpaperIcon" },
  { name: "Text", icon: "TextFieldsIcon" },
  { name: "Audio", icon: "AudiotrackIcon" },
  { name: "Transitions", icon: "ViewCarouselIcon" },
  { name: "Backgrounds", icon: "WallpaperIcon" },
  { name: "Text", icon: "TextFieldsIcon" },
  { name: "Audio", icon: "AudiotrackIcon" },
  { name: "Transitions", icon: "ViewCarouselIcon" },
  { name: "Backgrounds", icon: "WallpaperIcon" },
];
const Headercomponents = {
  PhotoLibraryIcon: PhotoLibraryIcon,
  PermMediaIcon: PermMediaIcon,
  TextFieldsIcon: TextFieldsIcon,
  AudiotrackIcon: AudiotrackIcon,
  ViewCarouselIcon: ViewCarouselIcon,
  WallpaperIcon: WallpaperIcon,
  MoreHorizIcon: MoreHorizIcon,
};


const VideoEditorSideContainerMore = () => {
  return (
    <Paper className="VideoEditor-sidebar-container-more" style={{ backgroundColor: "rgb(15, 15, 15)", display: "flex", flexDirection: "column", paddingTop: "10px", }}>
      <div
        style={{
          color: "white",
          fontWeight: "600",
          fontSize: 20,
        }}
      >
        More from Lurph
      </div>
      <div
        style={{
          color: "white",
          fontWeight: "600",
          fontSize: 12,
        }}
      >
        access even more content
      </div>
      <ul
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, 70px)",
          gap: "20px",
          paddingTop:"10px",
        }}
      >
        {HeaderItems.map(({ name, icon }, index) => (
          // <li>
          <Button
            key={index}
            id="sidebar-button"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor:"rgb(25,25,25)",
            }}
          // onClick={() => {
          //   if (sideBarOpen2[1] === name) {
          //     setSideBarOpen(1);
          //     setSideBarOpen2([false, ""]);
          //   } else {
          //     setSideBarOpen(2);
          //     setSideBarOpen2([true, name]);
          //   }
          // }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                paddingTop: "20px",
              }}
            >
              <span>{createComponent(Headercomponents[icon])}</span>{" "}
              <div style={{ fontWeight: "600", fontSize: 7 }}>{name}</div>
            </div>
          </Button>
          // </li>
        ))}
      </ul>
    </Paper>
  )
}

export default VideoEditorSideContainerMore