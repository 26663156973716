import React, { useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import { Button } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PermMediaIcon from "@material-ui/icons/PermMedia";
// import ExtensionIcon from "@material-ui/icons/Extension";
import SaveIcon from "@material-ui/icons/Save";
// import DragableTrees from "./DragableTree/DragableTree";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { BorderTop } from "@material-ui/icons";
// import GlobalState from "../../GlobalState/GlobalState";


const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    height: "calc(100vh - 3.4rem)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#151515",
    borderRight: "solid 0.5px rgba(255, 255, 255, 0.57)"
  },
  options: {
    color: "white",
    display: "flex",
    fontSize: 14,
    flexDirection: "row",
    paddingInline: 20,
    paddingTop: 15,
    paddingBottom: 5,
    background: "#151515",
    "&:hover": {
      backgroundColor: "rgb(29, 30, 35)",
      cursor: "pointer",
    },
  },
  icon: {
    marginRight: "10px",
  },
  mainContainer: {
    width: "17%",
    height: "calc(100vh - 3rem)",
    backgroundColor: "#151515",
    color: "rgb(255, 255, 255)",
    borderRight: "solid 1.5px white",
    // borderTop:"solid 0.1rem white",
  },
}));

const AdminPanelSideBar = () => {
  const classes = useStyles();
  // const [globalState, setGlobalState] = useContext(GlobalState);
  const [AddFeildsOpen, setAddFeildsOpen] = useState(false);
  const HandlesubMenus = (e) =>{
    e.preventDefault();
    setAddFeildsOpen(!AddFeildsOpen);
  }
  return (
    <div className={classes.mainContainer}>
      <div style={{
        border: "none", backgroundColor: "rgb(50,50,50)", color: "white", width: "87%", height: "5.5%",
        display: "flex", flexDirection: "row",
        justifyContent: "space-between", alignItems: "center",
        margin: "10px",
        padding: "6px"
      }}>
        <div style={{ padding: "5px" }}>Appearence</div>
        <button style={{ border: "none", backgroundColor: "rgb(50,50,50)", color: "white" }} onClick = {HandlesubMenus}>{AddFeildsOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}</button>
      </div>
      {
        AddFeildsOpen &&
        <div style={{
          border: "none", backgroundColor: "rgb(50,50,50)", color: "white", width: "84%", height: "5.5%",
          display: "flex", flexDirection: "row",
          justifyContent: "space-between", alignItems: "center",
          margin: "10px",
          padding: "8px"
        }}>
          <div style={{ padding: "5px" }}>Menus</div>
          {/* <button style={{ border: "none", backgroundColor: "rgb(50,50,50)", color: "white" }}>{AddFeildsOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}</button> */}
        </div>
      }
      {/* <div id="create-list-btn">
        <Button
          variant="contained"
          color="primary"
          endIcon={<KeyboardArrowDownIcon />}
        >
          Create new
        </Button>
        <div id="creator-list-btn-item">
          <span onClick={handleCreateNewVideo} id="item">
            <VideoCallIcon />
            Video
            <ChevronRightIcon />
          </span>
        </div>
      </div>
      <hr />
       */}
      {/* <Slide direction="right" in={showSideBar} mountOnEnter unmountOnExit>
        <Paper elevation={0} className={classes.paper}>
         */}
      {/* <div>
          <div id="create-list-btn">
        <Button
          variant="contained"
          color="primary"
          style={{marginTop:"10px"}}
          endIcon={<KeyboardArrowDownIcon /> }
        >
          Create new
        </Button>
        <div id="creator-list-btn-item">
          <span onClick={handleCreateNewVideo} id="item" >
            <VideoCallIcon />
            Video
            <ChevronRightIcon />
          </span>
        </div>
      </div>
      <hr />
            <Typography
              className={classes.options}
              variant="body1"
              onClick={() => {
                setGlobalState((state) => ({ ...state, location: "home" }));
              }}
            >
              <VideoCallIcon className={classes.icon} />
              Home
            </Typography>
            <Typography className={classes.options} variant="body1">
              <ExtensionIcon className={classes.icon} />
              Templates
            </Typography>
            <Typography className={classes.options} variant="body1">
              {" "}
              <PermMediaIcon className={classes.icon} />
              Media
            </Typography>
            <Typography className={classes.options} variant="body1">
              <SaveIcon className={classes.icon} />
              Exports
            </Typography>
            <Typography
              className={classes.options}
              variant="body1"
              onClick={() => {
                setGlobalState((state) => ({ ...state, location: "editor" }));
              }}
            >
              <ListAltIcon className={classes.icon} />
              Editor
            </Typography>
            {/* projects tree lies below */}
      {/* <DragableTrees project={project} history={history} />
          </div> */}

      {/* </Paper>
      </Slide> */}
    </div>
  )
}

export default AdminPanelSideBar;
