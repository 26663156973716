/* 
    Loading route action type is redundant, so it is removed
*/

export const PROJECT_META_SUCCESS = "PROJECT_META_SUCCESS";
export const LOADING_START = "PROJECT_LOADING_START";
// export const PROJECT_LOADING_END = "PROJECT_LOADING_END";
export const LOADING_ERROR = "LOADING_ERROR";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_STATE_SUCCESS = "PROJECT_STATE_SUCCESS";
export const PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS";
export const PROJECT_NAME_MODIFY_SUCCESS = "PROJECT_NAME_MODIFY_SUCCESS";
export const PROJECT_STATE_MODIFY_SUCCESS = "PROJECT_STATE_MODIFY_SUCCESS";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const CLEAR_PROJECT_STATE = "CLEAR_PROJECT_STATE";


/* 
    Loading route action type is redundant, so it is removed
*/

export const ASSET_UPLOAD_SUCCESS = "ASSET_UPLOAD_SUCCESS";
export const ASSET_LIST_SUCCESS = "ASSET_LIST_SUCCESS";
export const ASSET_DELETE_SUCCESS = "ASSET_DELETE_SUCCESS";
export const DOWNLOAD_ASSET_SUCCESS = "DOWNLOAD_ASSET_SUCCESS";


// for navbar edits 
export const NAVEDIT_LOADING_START = "NAVEDIT_LOADING_START";
export const NAVEDIT_LOADING_ERROR = "NAVEDIT_LOADING_ERROR";
export const NAVBAR_EDIT_UPLOAD_SUCCESS = "NAVBAR_EDIT_UPLOAD_SUCCESS";
export const NAVBAR_EDIT_LIST_SUCCESS = "NAVBAR_EDIT__LIST_SUCCESS";
export const NAVBAR_EDIT_DELETE_SUCCESS = "NAVBAR_EDIT_DELETE_SUCCESS";
export const NAVBAR_EDIT_MODIFY_SUCCESS = "NAVBAR_EDIT_MODIFY_SUCCESS";
export const GET_NAVBAREDITS_LIST = "GET_NAVBAREDITS_LIST";




// for navbar menu :
export const NAVMENU_LOADING_START = "NAVMENU_LOADING_START";
export const NAVMENU_LOADING_ERROR = "NAVMENU_LOADING_ERROR";
export const NAVBAR_MENU_UPLOAD_SUCCESS = "NAVBAR_MENU_UPLOAD_SUCCESS";
export const NAVBAR_DISPLAY_SUCCESS = "NAVBAR_DISPLAY_SUCCESS";

// export const LOGIN_FAILED = "LOGIN_FAILED";

// export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
// export const LOG_OUT = "LOG_OUT";

// // signup
// export const SIGNUP_START = "SIGNUP_START";
// export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
// export const SIGNUP_FAILED = "SIGNUP_FAILED";
// export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";

// export const EDIT_USER_SUCCESSFUL = "EDIT_USER_SUCCESSFUL";
// export const EDIT_USER_FAILED = "EDIT_USER_FAILED";

// export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
// export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";
// export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
// export const CLEAR_PROFILE_STATE = "CLEAR_PROFILE_STATE";

// // SIGN UP email verification otp
// export const SEND_EMAIL_VERIFICATION_OTP_START =
//   "SEND_EMAIL_VERIFICATION_OTP_START";
// export const SEND_EMAIL_VERIFICATION_OTP_FAILED =
//   "SEND_EMAIL_VERIFICATION_OTP_FAILED";
// export const SEND_EMAIL_VERIFICATION_OTP_SUCCESSFULLY =
//   "SEND_EMAIL_VERIFICATION_OTP_SUCCESSFULLY";
// // SIGN UP email verification otp
// export const SEND_EMAIL_VERIFICATION_CHECK_OTP_START =
//   "SEND_EMAIL_VERIFICATION_CHECK_OTP_START";
// export const SEND_EMAIL_VERIFICATION_CHECK_OTP_FAILED =
//   "SEND_EMAIL_VERIFICATION_CHECK_OTP_FAILED";
// export const SEND_EMAIL_VERIFICATION_CHECK_OTP_SUCCESSFULLY =
//   "SEND_EMAIL_VERIFICATION_CHECK_OTP_SUCCESSFULLY";

// // forgot password OTP SENT
// export const SEND_OTP_START = "SEND_OTP_START";
// export const SEND_OTP_FAILED = "SEND_OTP_FAILED";
// export const SEND_OTP_SUCCESSFULLY = "SEND_OTP_SUCCESSFULLY";
// // forgot password OTP RESENT
// export const SEND_OTP_RESEND_START = "SEND_OTP_RESEND_START";
// export const SEND_OTP_RESEND_FAILED = "SEND_OTP_RESEND_FAILED";
// export const SEND_OTP_RESEND_SUCCESSFULLY = "SEND_OTP_RESEND_SUCCESSFULLY";

// // forgot password OTP VERIFY
// export const OTP_VERIFY_START = "OTP_VERIFY_START";
// export const OTP_VERIFY_FAILED = "OTP_VERIFY_FAILED";
// export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
// // forgot password OTP VERIFY
// export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
// export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
// export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

// // change password
// export const PASSWORD_CHANGE_START = "PASSWORD_CHANGE_START";
// export const PASSWORD_CHANGE_FAILED = "PASSWORD_CHANGE_FAILED";
// export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";

// // update user profile
// export const UPDATE_USER_PROFILE_START = "UPDATE_USER_PROFILE_START";
// export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
// export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";

// // USER TEMPLATES

// export const USER_TEMPLATES_UPDATE = "USER_TEMPLATES_UPDATE";
// export const USER_TEMPLATE_CREATE_SUCCESS = "USER_TEMPLATE_CREATE_SUCCESS";
// export const CLEAR_TEMPLATE_STATE = "CLEAR_TEMPLATE_STATE";
// export const GCC_TEMPLATES_UPDATE = "GCC_TEMPLATES_UPDATE";
