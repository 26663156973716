import {
  NAVBAR_EDIT_UPLOAD_SUCCESS,
  NAVBAR_EDIT_LIST_SUCCESS,
  NAVBAR_EDIT_DELETE_SUCCESS,
  NAVBAR_EDIT_MODIFY_SUCCESS,
  GET_NAVBAREDITS_LIST,
  NAVEDIT_LOADING_START,
  NAVEDIT_LOADING_ERROR,
} from "../actions/actionTypes";

const initialAsset = {
  success: false,
  error: false,
  loading: false,
  editsList: [],
};

export default function alledits(state = initialAsset, action) {
  switch (action.type) {
    case NAVEDIT_LOADING_START:
      return {
        ...state,
        loading: true,
        success:false,
      };

    case NAVEDIT_LOADING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    case NAVBAR_EDIT_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        editstList: action.editstList,
      };

    case NAVBAR_EDIT_DELETE_SUCCESS:
      let neweditsList = state.editsList;
      if (state.editsList.length !== 0) {
        neweditsList =
          neweditsList.filter((project) => {
            return project.id !== action.editsid;
          });
      }
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        editsList: neweditsList,
      };
    case NAVBAR_EDIT_UPLOAD_SUCCESS:
      let newAssetList = state.editsList;
      newAssetList.push(action.payload);
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        editsList: newAssetList,
      };
    case NAVBAR_EDIT_MODIFY_SUCCESS:
      let newArray = [...state.editsList];
      const newInputFields = newArray.map(i => {
        if (action.editsid === i.id) {
          i = action.newdata
        }
        return i;
      })
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        editsList: newInputFields,
      }
    case GET_NAVBAREDITS_LIST:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        editsList: action.data,
      }
    default:
      return state;
  }
}
