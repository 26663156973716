import {
  NAVBAR_MENU_UPLOAD_SUCCESS,
  NAVBAR_DISPLAY_SUCCESS,
  NAVMENU_LOADING_START,
  NAVMENU_LOADING_ERROR,
} from "../actions/actionTypes";

const initialAsset = {
  success: false,
  error: false,
  loading: false,
  menuSet:false,
  MainNavbarMenuList: [],
};

export default function MainNavBarMenu(state = initialAsset, action) {

  switch (action.type) {

    case NAVMENU_LOADING_START:
      return {
        ...state,
        loading: true,
        success:false,
        menuSet:false,
      };

    case NAVMENU_LOADING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        menuSet:false,
      };

    case NAVBAR_MENU_UPLOAD_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        menuSet:true,
        MainNavbarMenuList: action.newdata,
      }

    case NAVBAR_DISPLAY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        menuSet:false,
        MainNavbarMenuList: action.data,
      }

    default:
      return state;
  }
}
