import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import NotificationsIcon from "@material-ui/icons/Notifications";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import { withFirebase } from "../../firebase/index";
import { connect } from "react-redux";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import "./VideoEditorNavbar.css";
import { Avatar, Button } from "@material-ui/core";
import ExportVideoDialog from "../ExportVideoDialog/ExportVideoDialog";
import Logo from "../../assets/logo.gif";
import GlobalState from "../../GlobalState/GlobalState";
import { Link } from 'react-router-dom';
import DropdownMenu from "./DropdownMenu";
import { getNavbarEditsGobalList, } from "../../actions/NavbarEdits";
import {
  NavbarMenuUpload,
  getNavbarMenu,
} from "../../actions/MainNavBarMenu"

const VideoEditorNavbar = ({ NavbarEdits, MainNavBarMenu, dispatch, toggleSideBar, showSidebar, firebase }) => {
  let history = useHistory();
  let userId = useRef(null);
  const [globalState, setGlobalState] = useContext(GlobalState);

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropIndex, setdropIndex] = useState(-1);
  const [sentitems, setsentitems] = useState([]);
  const [user, setUser] = useState(null);
  const [MenuList, setMenuList] = useState([]);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  // console.log(dropdown);

  useEffect(() => {
    console.log("call", MainNavBarMenu.menuSet);
    if (MainNavBarMenu.menuSet) {
      firebase.auth.onAuthStateChanged(async (userAuth) => {
        if (!userAuth) {
          history.push("/login");
        } else {
          userId.current = userAuth.uid;
          setUser(userAuth);
          dispatch(getNavbarMenu(userId.current));
          setMenuList(MainNavBarMenu.MainNavbarMenuList);
          console.log("menu List video editor = ", MainNavBarMenu.MainNavbarMenuList, MenuList);
        }
      });
    }
  }, [MainNavBarMenu.menuSet]);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        history.push("/login");
      } else {
        userId.current = userAuth.uid;
        setUser(userAuth);
        dispatch(getNavbarMenu(userId.current));
        setMenuList(MainNavBarMenu.MainNavbarMenuList);
        console.log("menu List video editor = ", MainNavBarMenu.MainNavbarMenuList, MenuList);
      }
    });
  }, []);


  // useEffect(() => {
  //   console.log(dropIndex );
  //   if (dropIndex !== -1) {
  //     setDropdown(true);
  //     let red = [];
  //     MainNavBarMenu.MainNavbarMenuList[0]?.name?.map((item,index)=>{
  //       if(index===dropIndex){
  //         red = item.submenuslist;
  //       }
  //     })
  //     setsentitems(red);
  //   }
  //   else {
  //     setDropdown(false);
  //     setsentitems([]);
  //   }
  //   return () => {
  //   }
  // }, [dropIndex])

  const onMouseEnter = (e, id) => {
    console.log(window.innerWidth);
    if (window.innerWidth < 837) {
      setDropdown(false);
      setsentitems([]);
      setdropIndex(-1);
    } else {
      // e && console.log(e);

      setDropdown(true);
      setsentitems(e);
      setdropIndex(id);
      // console.log(dropIndex)
    }
  };



  const onMouseLeave = () => {
    setDropdown(false);
    setsentitems([]);
    setdropIndex(-1);
  };

  console.log("func out ", MainNavBarMenu);

  return (
    <>
      {globalState.location === "home" ? (
        <div className="VideoEditor-navbar-container">
          <div className="left-container">
            <MenuIcon
              onClick={() => {
                toggleSideBar(!showSidebar);
              }}
              style={{ color: "white", marginRight: 20 }}
            />
            <div>
              <img src={Logo} alt="logo" style={{ height: "40px" }} />
            </div>
            <div>
              <span
                to="/"
                onClick={() => {
                  history.push("/editor");
                }}
                className="VideoEditor-navbar-title"
                style={{
                  color: "white"
                }}
              >
                lurph {dropdown}
              </span>
            </div>



            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              {
                MainNavBarMenu?.MainNavbarMenuList[0]?.name?.map((items, index) => (
                  <>
                    <li
                      className="nav-item"
                      key={index}
                    >
                      <div className="nav-links"
                        onMouseEnter={() => {
                          // setdropIndex(index);
                          onMouseEnter(items?.submenuslist, index)
                        }
                        }
                        onMouseLeave={onMouseLeave}
                        onClick={closeMobileMenu}>
                        <div className="NavBarMenuContent">
                          <div className="NavBarMenuContentName">{items?.name?.Name}</div>
                          <div className="NavBarMenuContentIcon"><KeyboardArrowDownIcon /></div>
                        </div>
                        {/* <div
                          style={{
                            display: (dropdown) ? "flex" : "none",
                            width: "70%",
                            height: "60%",
                            zIndex: "100",
                            top: "7%",
                            right: "14%",
                            backgroundColor: "white",
                            border: "2px solid black",
                            position: "fixed",
                            borderRadius: "10px",
                            transition: "height 0.5s ease-in-out",
                            willChange: "height",
                          }}
                        > */}
                        {(dropdown && dropIndex === index && sentitems?.length > 0) && <DropdownMenu NavbarDslmenu={sentitems} />}
                        {/* </div> */}
                      </div>
                    </li>
                  </>
                ))
              }
              {/* <li
                className="nav-item"
                onMouseEnter={onMouseEnter.bind(this, "DesignSpotLight")}
                onMouseLeave={onMouseLeave.bind(this, "DesignSpotLight")}
              >
                <div className="nav-links" onClick={closeMobileMenu}>
                  Design spotlight <i className="fas fa-caret-down" />
                </div>
                <div
                  style={{
                    display: (dropdown.DesignSpotLight) ? "flex" : "none",
                    width: "80%",
                    height: "60%",
                    zIndex: "100",
                    top: "5%",
                    right: "10%",
                    backgroundColor: "white",
                    position: "fixed",
                    borderRadius: "10px",
                    transition: "height 0.5s ease-in-out",
                    willChange: "height",
                  }}
                >
                  {dropdown.DesignSpotLight && <NavbarPagesDesignSpotlight />}
                </div>
              </li>
              <li
                className="nav-item"
                onMouseEnter={onMouseEnter.bind(this, 'billing')}
                onMouseLeave={onMouseLeave.bind(this, 'billing')}
              >
                <div className="nav-links" onClick={closeMobileMenu}>
                  Business <i className="fas fa-caret-down" />
                </div>
                <div
                  style={{
                    display: (dropdown.billing) ? "flex" : "none",
                    width: "80%",
                    height: "60%",
                    zIndex: "100",
                    top: "5%",
                    right: "10%",
                    backgroundColor: "white",
                    position: "fixed",
                    borderRadius: "10px",
                    transition: "height 0.5s ease-in-out",
                    willChange: "height",
                  }}
                >
                  {dropdown.billing && <Dropdown />}
                </div>
              </li>
              <li
                className="nav-item"
                onMouseEnter={onMouseEnter.bind(this, 'payments')}
                onMouseLeave={onMouseLeave.bind(this, 'payments')}
              >
                <div className="nav-links" onClick={closeMobileMenu}>
                  Education <i className="fas fa-caret-down" />
                </div>
                <div
                  style={{
                    display: (dropdown.payments) ? "flex" : "none",
                    width: "80%",
                    height: "60%",
                    zIndex: "100",
                    top: "5%",
                    right: "10%",
                    backgroundColor: "white",
                    position: "fixed",
                    borderRadius: "10px",
                    transition: "height 0.5s ease-in-out",
                    willChange: "height",
                  }}
                >
                  {dropdown.payments && <Dropdown />}
                </div>
              </li>
              <li
                className="nav-item"
                onMouseEnter={onMouseEnter.bind(this, 'payments')}
                onMouseLeave={onMouseLeave.bind(this, 'payments')}
              >
                <div className="nav-links" onClick={closeMobileMenu}>
                  Plans and pricing <i className="fas fa-caret-down" />
                </div>
                <div
                  style={{
                    display: (dropdown.payments) ? "flex" : "none",
                    width: "80%",
                    height: "60%",
                    zIndex: "100",
                    top: "5%",
                    right: "10%",
                    backgroundColor: "white",
                    position: "fixed",
                    borderRadius: "10px",
                    transition: "height 0.5s ease-in-out",
                    willChange: "height",
                  }}
                >
                  {dropdown.payments && <Dropdown />}
                </div>
              </li>
              <li
                className="nav-item"
                onMouseEnter={onMouseEnter.bind(this, 'payments')}
                onMouseLeave={onMouseLeave.bind(this, 'payments')}
              >
                <div className="nav-links" onClick={closeMobileMenu}>
                  Learning <i className="fas fa-caret-down" />
                </div>
                <div
                  style={{
                    display: (dropdown.payments) ? "flex" : "none",
                    width: "80%",
                    height: "60%",
                    zIndex: "100",
                    top: "5%",
                    right: "10%",
                    backgroundColor: "white",
                    position: "fixed",
                    borderRadius: "10px",
                    transition: "height 0.5s ease-in-out",
                    willChange: "height",
                  }}
                >
                  {dropdown.payments && <Dropdown />}
                </div>
              </li> */}
            </ul>

          </div>
          <div className="right-container">
            <a
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "1rem",
                fontWeight: "bolder",
                marginRight: "1rem",
              }}
              href="https://explified.com/services/"
            >
              For Business
            </a>

            <div id="avatar">
              <Avatar
                style={{
                  width: "25px",
                  height: "25px",
                }}
              />
              <KeyboardArrowDownIcon />
              <div id="user-tools">
                <Button
                  onClick={() => {
                    firebase.signOutUser();
                    history.push("/login");
                  }}
                  title="Logout"
                  variant="contained"
                  color="secondary"
                >
                  Logout
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  MainNavBarMenu: state.MainNavBarMenu,
});
export default connect(mapStateToProps)(withFirebase(VideoEditorNavbar));

