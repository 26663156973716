import React, { useState, useEffect, useRef } from 'react';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import './Editor.css';

const ffmpeg = createFFmpeg({
    // corePath: "./node_modules/@ffmpeg/core/dist/ffmpeg-core.js",
    log: true,
});

import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: 300 + theme.spacing(3) * 2,
//     },
//     margin: {
//         height: theme.spacing(3),
//     },
// }));

// function ValueLabelComponent(props) {
//     const { children, open, value } = props;

//     return (
//         <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
//             {children}
//         </Tooltip>
//     );
// }

// ValueLabelComponent.propTypes = {
//     children: PropTypes.element.isRequired,
//     open: PropTypes.bool.isRequired,
//     value: PropTypes.number.isRequired,
// };

// const AirbnbSlider = withStyles({
//     root: {
//         color: '#3a8589',
//         height: 3,
//         width:"400px",
//         padding: '13px 0',
//     },
//     thumb: {
//         height: 27,
//         width: 15,
//         borderRadius: 0,
//         backgroundColor: 'rgb(15,15,15)',
//         border: '1px solid currentColor',
//         marginTop: 0,
//         marginLeft: 0,
//         boxShadow: '#ebebeb 0 2px 2px',
//         '&:focus, &:hover, &$active': {
//             boxShadow: '#ccc 0 2px 3px 1px',
//         },
//         '& .bar': {
//             // display: inline-block !important;
//             height: 9,
//             width: 1,
//             backgroundColor: 'currentColor',
//             marginLeft: 1,
//             marginRight: 1,
//         },
//     },
//     active: {},
//     track: {
//         height: 27,
//     },
//     rail: {
//         color: '#d8d8d8',
//         opacity: 1,
//         height: 27,
//     },
// })(Slider);

// function AirbnbThumbComponent(props) {
//     return (
//         <span {...props}>
//             <span className="bar" />
//             <span className="bar" />
//             <span className="bar" />
//         </span>
//     );
// }

const Editor = () => {
    const videoEl = useRef(null);

    const [ready, setReady] = useState(false);
    const [video, setVideo] = useState();
    const [gif, setGif] = useState();
    const [timeline, setTimeline] = useState();
    const [TimeLineVal, setTimeLineVal] = useState([0, 100]);
    const [VidLen, setVidLen] = useState(0);

    // const load = async () => {
    //     await ffmpeg.load();
    //     setReady(true);
    // }

    // useEffect(() => {
    //     load();
    // }, [])

    const doTranscode = async () => {
        // setMessage('Loading ffmpeg-core.js');
        if (!ffmpeg.isLoaded()) await ffmpeg.load();
        // setMessage('Start transcoding');
        ffmpeg.FS('writeFile', 'test.avi', await fetchFile(video));
        await ffmpeg.run('-i', 'test.avi', 'test.mp4');
        // setMessage('Complete transcoding');
        const data = ffmpeg.FS('readFile', 'test.mp4');
        setVideo(URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' })));
    };

    const convertToGif = async () => {
        // Write the file to memory 
        if (!ffmpeg.isLoaded()) await ffmpeg.load();

        ffmpeg.FS('writeFile', 'test.mp4', await fetchFile(video));
        // await ffmpeg.run("-i", "test.mp4", "-c:v", "libx265", "-crf", "0", "-c:a", "copy", "test.mp4");
        // Run the FFMpeg command
        // await ffmpeg.run('-i', 'test.mp4', '-t', '2.5', '-ss', '2.0', '-f', 'gif', 'out.gif');
        // await ffmpeg.run("-i", "test.mp4", "2>&1", "|", " grep", "Duration", "|", "cut", "-d", "' '", "-f", " 4", "|", "sed", "s/,//", "|", "sed", "'s@\..*@@g'", "|", "awk", '{ split($1, A, ":"); split(A[3], B, "."); print 3600*A[1] + 60*A[2] + B[1] }');
        var start = (TimeLineVal[0] * VidLen) / 100;
        var dur = ((TimeLineVal[1] - TimeLineVal[0]) * VidLen) / 100;
        console.log(start);
        console.log(dur);
        await ffmpeg.run('-i', 'test.mp4', '-ss', `${start}`, '-t', `${dur}`, 'out.mp4');
        // ffmpeg -i input.mp4 '%04d.png'
        // await ffmpeg.run('-i', 'test.mp4', '-vf', 'fps=1', 'out%d.jpg');
        // Read the result
        // var xx = 1;
        // var red = [];
        // while (xx < 31) {
        //     const data1 = ffmpeg.FS('readFile', `out${xx}.jpg`);
        //     const url = URL.createObjectURL(new Blob([data1.buffer], { type: 'image/jpg' }));
        //     console.log(url);
        //     red.push(url);
        //     xx++;
        // }
        // setTimeline(red);
        // console.log(red);
        // console.log(timeline);

        // const data1 = ffmpeg.FS('readFile', '%d.jpg');
        // console.log("data1",data1);

        // Create a URL
        const data = ffmpeg.FS('readFile', 'out.mp4');
        const url = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
        setGif(url)
    }

    // useEffect(() => {
    //     // load the ffmpeg binary files from CDN 
    //     transCodeReady();
    // }, []);
    // const classes = useStyles();

    const TimeLineValHandler = (e, data) => {
        e.preventDefault();
        setTimeLineVal(data);
    }


    const HandleInputFile = (e) => {
        e.preventDefault();
        // console.log(e.target.value);
        const val = e.target.value;
        if(val!==""){
            setVideo(val);
        }
        // setVideo(URL.createObjectURL(e.target.files?.item(0)));
    }

    const handleLoadedMetadata = () => {
        const video = videoEl.current;
        if (!video) return;
        console.log(`The video is ${video.duration} seconds long.`);
        var len = video.duration;
        setVidLen(len);
    };



    return true ? (


        <div className="App">
            <input style={{width:"700px",height:"30px",display:"flex",margin:"auto",marginTop:"5px"}} type="text" onChange={HandleInputFile} />
            {/* <div className={classes.root}>
                <Typography gutterBottom>Timeline</Typography>
                <AirbnbSlider
                    ThumbComponent={AirbnbThumbComponent}
                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                    defaultValue={[0, 100]}
                />
            </div> */}
            {true &&
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px",
                }}>
                    <video
                        crossOrigin="anonymous"
                        controls
                        width="400"
                        src={video}
                        ref={videoEl}
                        onLoadedMetadata={handleLoadedMetadata}
                    >
                    </video>
                </div>
            }
            <div style={{ width: "80%", height: 10, margin: 30 }}>
                <Slider
                    value={TimeLineVal}
                    onChange={TimeLineValHandler}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <button style={{ width: "200px", height: "40px", margin: "8px" }} onClick={convertToGif}>Trim</button>
                {/* <h3 style={{ margin: "8px" }} >Result</h3> */}
            </div>
            {true &&
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px",
                }}>
                    <video
                        controls
                        width="350" src={gif} />
                </div>
            }
            {/* {
                timeline.map((e) => {
                    <img alt="out" width="400px" src={e} />
                })
            } */}

        </div>
    )
        :
        (
            <p>Loading...</p>
        );
};

export default Editor;