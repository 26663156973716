import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import { Container, Row, Col } from "reactstrap";
import { sizeHeight, width } from "@material-ui/system";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import VideoEditorRendering from "../VideoEditorRendering/VideoEditorRendering";
import { useDispatch, useSelector } from "react-redux";
import { setFrames } from "../../reducers/frames";
import axios from "axios";
import { Input } from "@material-ui/core";
// import './style.css'
const ffmpegInstance = createFFmpeg({
  log: true,
});
let ffmpegLoadingPromise = ffmpegInstance.load()
async function getFFmpeg()
{
  if (ffmpegLoadingPromise)
  {
    await ffmpegLoadingPromise
    ffmpegLoadingPromise = undefined
  }
  return ffmpegInstance
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref)
{
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoEditorFinish({
  showDia,
  setShowDia,
  videoTitle,
  setVideoTitle,
  quality,
  setQuality,
  value,
  setValue,
})
{
  const [viewDialog, setViewDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videourl, setVideoUrl] = useState("");
  const classes = useStyles();

  const handleClickOpen = () =>
  {
    setShowDia(true);
  };

  const handleClose = () =>
  {
    setShowDia(false);
  };

  const handleChange = (event) =>
  {
    if (event.target.value == "Audio")
    {
      setQuality("SD");
    }
    setValue(event.target.value);
    console.log(event);
  };

  const handleVideoTitle = (e) =>
  {
    e.preventDefault();
    setVideoTitle(e.target.value);
  };

  const handleQuality = (event, newQuality) =>
  {
    if (newQuality != null)
    {
      setQuality(newQuality);
    }
  };

  const exportVideo = () =>
  {
    let url;
    console.log("in export function");
    let timelineFrames = {};
    let token = localStorage.getItem("token");
    console.log(token);
    timelineFrames = localStorage.getItem("timelineFrames");
    console.log(timelineFrames);
    timelineFrames = JSON.parse(timelineFrames);
    console.log(timelineFrames);
    axios
      .post(
        "http://localhost:5001/lurph-dev/us-central1/app/api/v2/videoeditor/myAssets/exportVideo",
        timelineFrames[0].files,
        { headers: { authtoken: token } }
      )
      .then(function (response)
      {
        // handle success
        console.log(response.data);
        setVideoUrl(response.data.url);
      });
    console.log(timelineFrames[0].files);
  };
  return (
    <>
      <VideoEditorRendering
        viewDialog={viewDialog}
        setViewDialog={setViewDialog}
        quality={quality}
        setQuality={setQuality}
        value={value}
        setValue={setValue}
      />
      <div>
        <Dialog
          fullScreen
          open={showDia}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <img
                  src="https://cdn.pixabay.com/photo/2013/07/13/11/42/back-158491_960_720.png"
                  width="40"
                  height="40"
                  alt=""
                />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Back To Editor
              </Typography>
            </Toolbar>
          </AppBar>
          <Container
            fluid
            style={{ backgroundColor: "lightblue", height: "100%" }}
          >
            <Row style={{ marginTop: "20px" }}>
              {/* <Col style={{ padding: "10" }}></Col> */}
              <Col>
                <Input
                  style={{
                    width: "500px",
                    marginTop: "10px",
                    height: "40px",
                    backgroundColor: "azure",
                    borderRadius: "5px",
                    border: "none"
                  }}
                  id="filled-basic"
                  value={videoTitle}
                  variant="filled"
                  onChange={handleVideoTitle}
                  placeholder="Enter your video name...."
                />
                {/* <div style={{ height: "50px" }} /> */}
                <div style={{ marginTop: "10px", fontSize: 30 }}>Export</div>
                <FormControl style={{ margin: "12px" }} component="fieldset">
                  <RadioGroup
                    aria-label="Type"
                    name="type1"
                    value={value}
                    onChange={handleChange}
                  >
                    <div>
                      <FormControlLabel
                        value="Video"
                        name="format"
                        control={<Radio />}
                        label={
                          <Typography
                            style={{ fontSize: 20 }}
                            variant="h5"
                            color="black"
                          >
                            Video
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Audio"
                        name="format"
                        control={<Radio />}
                        label={
                          <Typography
                            style={{ fontSize: 20 }}
                            variant="h5"
                            color="black"
                          >
                            Audio
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="GIF"
                        name="format"
                        control={<Radio />}
                        label={
                          <Typography
                            style={{ fontSize: 20 }}
                            variant="h5"
                            color="black"
                          >
                            GIF
                          </Typography>
                        }
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
                <div style={{ height: "50px" }} />
                <div style={{ fontSize: 40 }}>Resolution</div>
                <ToggleButtonGroup
                  value={quality}
                  exclusive
                  onChange={handleQuality}
                  aria-label="render quality"
                >
                  <ToggleButton
                    style={{ width: 200, height: 90, fontSize: 40 }}
                    value="SD"
                  >
                    SD
                  </ToggleButton>
                  <ToggleButton
                    style={{ width: 200, height: 90, fontSize: 40 }}
                    value="HD"
                    disabled={value == "Audio"}
                  >
                    HD/4K
                  </ToggleButton>
                  <ToggleButton
                    style={{ width: 200, height: 90, fontSize: 40 }}
                    value="FULL HD"
                    disabled={value == "Audio"}
                  >
                    FULL HD
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
              <Col>

                {videourl ? (
                  <video controls height="400" width="400">
                    <source src={videourl}></source>
                  </video>
                ) : (
                  <p></p>
                )}
              </Col>
            </Row>
            <Row>

              <Col
                style={{
                  margin: "20px",
                }}
              >
                <Button
                  style={{ width: 150, height: 50, margin: "20px" }}
                  variant="contained"
                  size="large"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{ width: 150, height: 50, margin: "20px" }}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={exportVideo}
                >
                  Export
                </Button>
              </Col>
              {/* <Col>
                {videourl ? (<a href={videourl} download type="video/mp4">download video</a>) : (<p></p>)}
                {/* {videourl ? (
              <video controls height="400" width="400">
                <source src={videourl}></source>
              </video>
            ) : (
              <p></p>
            )} */}
              {/* </Col>  */}
            </Row>


          </Container>
        </Dialog>
      </div>
    </>
  );
}
