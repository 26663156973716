import { combineReducers } from "redux";
import project from "./project";
import asset from "./asset";
import auth from "./auth";
import frames from "./frames";
import NavbarEdits from "./NavbarEdits"
import MainNavBarMenu from "./MainNavBarMenu"
const rootReducer = combineReducers({
  auth,
  project,
  asset,
  frames,
  NavbarEdits,
  MainNavBarMenu,
});

export default rootReducer;
