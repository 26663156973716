import React, { useEffect, useRef, useState } from 'react'
import { Input, Paper } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import { v4 as uuidv4 } from 'uuid';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from "../../firebase/index";
import { connect } from "react-redux";
// import Draggable, { DraggableCore } from 'react-draggable';
import {
  getNavbarEditsGobalList,
  getNavbarEditsList,
  AddNewDatatoNavbar,
  DeleteDataFromNavbar,
  ModifyDatatoNavbar,
} from "../../actions/NavbarEdits";
import {
  NavbarMenuUpload,
  getNavbarMenu,
} from "../../actions/MainNavBarMenu"
import VideoEditorNavbar from '../VideoEditorNavbar/VideoEditorNavbar';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import "./MainNavBarEdit.css"
import MainNavBarLeftCheckBox from './MainNavBarLeftCheckBox';
import MainBarSubmenu from './MainBarSubmenu';
import { FormMenu } from './NavbarFormMenu';
import AssetLoaderUI from '../AssetLoaderUI/AssetLoaderUI';



function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    height: "400px",
    width: "700px",
    transform: `translate(-${top}%, -${left}%)`,
    overflowY: "scroll",
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    position: 'absolute',
    width: "700px",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))


const MainNavBarEdit = ({ NavbarEdits, dispatch, history, firebase }) => {
  const classes = useStyles()
  const [InputHeaderName, setInputHeaderName] = useState("");
  // const [InputHeaderId, setInputHeaderID] = useState(uuidv4());
  const [InputHeaderLink, setInputHeaderLink] = useState("");
  const [CurrentEditId, setCurrentEditId] = useState("");
  const [CurEditId, setCurEditId] = useState("");
  const [inputFields, setInputFields] = useState([]);

  const [editopen, setEditOpen] = React.useState(false);
  const [DeleteId, setDeleteId] = React.useState("");
  const [user, setUser] = useState(null);
  const [MainMenu, setMainMenu] = useState([]);
  const [Ter, setTer] = useState([])
  const [FMClick, setFMClick] = useState(false);
  let userId = useRef(null);


  useEffect(() => {
    // console.log("calling useeffect0");
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        history.push("/login");
      } else {
        userId.current = userAuth.uid;
        setUser(userAuth);
        dispatch(getNavbarEditsGobalList(userId.current));
        let res = NavbarEdits?.editsList;
        if (res.length !== 0) {
          res = res.filter((project) => {
            return project.name.checkId;
          });
        }
        res.sort((a, b) => {
          return a.name.indexid - b.name.indexid;
        });
        setInputFields(res);
      }
    });
    return () => {
      // second
    }
  }, []);

  useEffect(() => {
    // console.log("calling useeffect1",NavbarEdits.success);
    if (NavbarEdits.success) {
      let res = NavbarEdits?.editsList;
      if (res.length !== 0) {
        res = res.filter((project) => {
          return project.name.checkId;
        });
      }
      res.sort((a, b) => {
        return a.name.indexid - b.name.indexid;
      });
      setInputFields(res);
    }
    return () => {
      // second
    }
  }, [NavbarEdits.success]);



  useEffect(() => {
    console.log("calling useeffect2");
    if (FMClick === true) {
      const r = inputFields;
      const red = FormMenu(r);
      console.log(red);
      setTer(red);
      if (Ter.length === 0 && inputFields.length !== 0) {
        // alert("some problem in order of menus");
      }
      else {
        setMainMenu(Ter);
        dispatch(NavbarMenuUpload(userId.current, MainMenu));
      }
    }
    return () => {
      setFMClick(false);
    }
  }, [FMClick])

  console.log("out Ter", Ter);
  console.log("out mainmaneu", NavbarEdits.success)

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setInputHeaderID(uuidv4());
  //   setOpen(false);
  //   // console.log(InputHeaderName);
  //   // console.log(InputHeaderLink);
  //   // console.log("InputFields", inputFields);
  //   // console.log(InputHeaderId);
  //   if (InputHeaderName !== "" && InputHeaderLink !== "") {
  //     dispatch(
  //       AddNewDatatoNavbar(userId.current, {
  //         Id: InputHeaderId,
  //         Name: InputHeaderName,
  //         Link: InputHeaderLink,
  //         checkId: false,
  //         level: 0,
  //         indexid: NavbarEdits?.editsList.length + 1,
  //       }
  //       )
  //     )
  //   }
  //   setInputHeaderName("");
  //   setInputHeaderLink("");
  //   setOpen(false)
  //   setEditOpen(false);
  // };

  // const OnHandleHeaderNameChange = (e) => {
  //   setInputHeaderName(e.target.value);
  // }
  // const OnHandleHeaderLinkChange = (e) => {
  //   setInputHeaderLink(e.target.value);
  // }
  const [modalStyle] = React.useState(getModalStyle);


  const HandleFormsubmit = (e) => {
    e.preventDefault();
    setFMClick(true);
  }


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DragDropContext onDragEnd={(param) => {
        const srcI = param.source.index;
        const desI = param.destination?.index;
        let list = inputFields;
        if (desI >= 0) {
          list.splice(desI, 0, list.splice(srcI, 1)[0]);
          setInputFields(list);
          inputFields.map((id, index) => {
            let res = id;
            res.name.indexid = index;
            dispatch(
              ModifyDatatoNavbar(
                userId.current,
                id.id,
                res
              )
            )
          })
        }
      }}>
        <Paper style={{ paddingBottom: "2px", borderRadius: 0, paddingRight: "10px", backgroundColor: "rgb(15,15,15)", display: "flex", marginTop: "5px" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", minHeight: "40px", minWidth: "90%", margin: "auto" }}>
            <div style={{ fontSize: "15px", color: "wheat" }}>
              Main Navbar settings
            </div>
            <div>
              {/* <button onClick={handleOpen} className="addfeildButton">Add feilds</button> */}
              {/* <button onClick={HandleFormsubmit} className="addfeildButton">submit changes</button> */}
            </div>
          </div>
        </Paper>


        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <form className={classes.root} onSubmit={handleSubmit}>
              <h1>Add New Field</h1>
              <TextField
                name="Name"
                label="Name"
                variant="filled"
                value={InputHeaderName}
                onChange={OnHandleHeaderNameChange}
              />
              <TextField
                name="link"
                label="Link"
                variant="filled"
                value={InputHeaderLink}
                onChange={OnHandleHeaderLinkChange}
              />

              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                endIcon={<Icon>send</Icon>}
                onClick={handleSubmit}
              >ADD FEILD</Button>
            </form>
          </div>
        </Modal> */}


        {(NavbarEdits.loading || FMClick) && <div className='loaderUI'><AssetLoaderUI /></div>}
        <div className="Navbaredits_leftsidebar">
          <MainNavBarLeftCheckBox userid={userId.current} />
          <div className="Navbaredits_rightsidebar">
            <div className = "MenuStructHeader"> 
              <div className='MenuStructName'>MENU STRUCTURE</div>
              <button onClick={HandleFormsubmit} className="addfeildButton">submit changes</button>
            </div>
            <div className='MenuScrollable'>
              <Droppable droppableId="droppable-1">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} >
                    {
                      inputFields?.map((items, index) => (
                        <>
                          <Draggable key={items.id} draggableId={"draggable-" + items.id} index={index}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="Navbaredits_rightsidebar_content">
                                {/* <div style={{ display: "flex", flexDirection: "column", color: "white" }}> */}
                                <MainBarSubmenu
                                  sublevel={items}
                                  inputFields={inputFields}
                                  setInputFields={setInputFields}
                                  currid={items.id}
                                  userid={userId.current}
                                />
                              </div>
                            )}
                          </Draggable>
                        </>
                      ))
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </DragDropContext>
    </div>
  )
}




const mapStateToProps = (state) => ({
  NavbarEdits: state.NavbarEdits,
});
export default connect(mapStateToProps)(withFirebase(MainNavBarEdit));
