import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GridList, GridListTile } from "@material-ui/core";
import { Card, CardMedia, CardActionArea } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { FavoriteBorderOutlined } from "@material-ui/icons";
import { withFirebase } from "../../firebase";
import { ellipsize } from "../../Utils/helpers";
import { withRouter } from "react-router";
import { getAssetList } from "../../actions/asset";
import "./VideoEditorStockMediaResultsVids.css";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    margin: 5,
    backgroundColor: "rgb(15,15,15)",
    borderRadius: 2,
  },
});

const VideoEditorStockMediaResultsVids = ({
  videos,
  setPreviewFile,
  setVidUrl,
  setImgUrl,
  firebase,
  history,
  dispatch,
  asset,
}) =>
{
  const classes = useStyles();
  let req = useRef({ cancel: null });
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() =>
  {
    //let cancel = req.current;
    firebase.auth.onAuthStateChanged(async (userAuth) =>
    {
      if (!userAuth)
      {
        history.push("/login");
      } else
      {
        setUser(userAuth);
      }
    });
  }, []);

  const handleVideoClick = (Url) =>
  {
    setImgUrl(Url);
    setPreviewFile(null);
    setVidUrl(Url);
  };

  const onDragStart = (e, url, tags, thumbnail, duration,) =>
  {
    e.persist();
    console.log("abcdsdzvc");
    e.preventDefault();
    let data = {
      uid: user.uid,
      type: "video",
      fileName: tags,
      url,
      thumbnail,
      duration,
    };
    //console.log(data);
    // e.dataTransfer.setData('text/plain',data.url);
    localStorage.setItem("videokey", JSON.stringify(data));
    //e.dataTransfer.setData("cancel", JSON.stringify(req.current));
    e.dataTransfer.effectAllowed = "move";
  };

  function truncateToDecimals(num, dec = 0)
  {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
  }

  console.log(videos);
  if (videos)
  {
    return (
      <div style={{ width: "100%", backgroundColor: "rgb(15,15,15)" }}>
        <GridList style={{ height: "100%", width: "100%" }} cols={5}>
          {videos.map((vid) => (
            <div
              className="container"
              onClick={() => handleVideoClick(vid.videos.large.url)}
              style={{ margin: 10, height: 90, width: 150 }}
            >
              <div
                className="vid"
                style={{ width: 150, height: 80, justifyContent: "center" }}
              >
                <video
                  draggable
                  onDrag={(e) =>
                  {
                    console.log(vid);
                    onDragStart(
                      e,
                      vid.videos.tiny.url,
                      vid.tags,
                      vid.userImageURL,
                      vid.duration
                    );
                  }}
                  style={{ width: "100%" }}
                  src={vid.videos.tiny.url}
                  alt=""
                //
                />
              </div>
              <div
                style={{ display: "inline-flex", width: "100%", height: 30 }}
              >
                <IconButton className="text" style={{ height: 20, width: 20 }}>
                  <FavoriteBorderOutlined style={{ height: 15, width: 15 }} />
                </IconButton>
                <p
                  style={{
                    marginLeft: 75,
                    height: 18,
                    color: "white",
                    backgroundColor: "black",
                    opacity: 0.6,
                    borderRadius: 100,
                    paddingLeft: 8,
                    paddingRight: 8,
                    fontSize: 12,
                    marginTop: 5,
                  }}
                >
                  {"0" +
                    Number.parseFloat(
                      truncateToDecimals(vid.duration / 60)
                    ).toFixed(0) +
                    ":" +
                    (vid.duration % 60 > 9
                      ? Number.parseFloat(
                        truncateToDecimals(vid.duration % 60)
                      ).toFixed(0)
                      : "0" +
                      Number.parseFloat(
                        truncateToDecimals(vid.duration % 60)
                      ).toFixed(0))}
                </p>
              </div>

              <div
                className="text"
                style={{
                  backgroundColor: "black",
                  width: "100%",
                  height: 20,
                  textAlign: "center",
                  opacity: 0.7,
                  bottom: 3,
                }}
              >
                <p style={{ color: "white", fontSize: 11 }}>
                  {ellipsize(vid.tags.split(",").slice(0, -1).join(","), 30)}
                </p>
              </div>
            </div>
          ))}
        </GridList>
      </div>
    );
  } else
  {
    return <div>No Results</div>;
  }
};
const Component = withFirebase(VideoEditorStockMediaResultsVids);
export default withRouter(Component);
