import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { ViewDay } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function VideoEditorRendering({viewDialog, setViewDialog, quality, setQuality, value, setValue}) {
  const classes = useStyles();

  const handleClickOpen = () => {
    setViewDialog(true);
  };

  const handleClose = () => {
    setViewDialog(false);
  };

  return (
    <div>
      <Dialog fullScreen open={viewDialog} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <img src="https://cdn.pixabay.com/photo/2013/07/13/11/42/back-158491_960_720.png" width="40" height="40"/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Back To Export
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <Typography variant="h6">
            {value} Rendering in {quality} Quality
          </Typography>
        </div>
      </Dialog>
    </div>
  );
}
