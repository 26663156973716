import React, { useState, useRef, useEffect } from 'react';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { Input } from '@material-ui/core';
import { withRouter } from 'react-router';
import { withFirebase } from '../firebase';

const Watermark = (firebase,history) => {
  const [videoSrc, setVideoSrc] = useState('');
  const [vid, setVid] = useState(null);
  const [user, setUser] = useState(false);
  const [message, setMessage] = useState('Click Start to transcode');
  const ffmpeg = createFFmpeg({
    log: true,
  });

  // const userId = useRef("");

  // useEffect(() => {
  //   firebase.auth.onAuthStateChanged(async (userAuth) => {
  //     if (!userAuth) {
  //       history.push("/login");
  //     } else {
  //       userId.current = userAuth.uid;

  //       setUser(userAuth);
  //     }
  //   });
  // }, []);

  const doTranscode = async () => {
    setMessage('Loading ffmpeg-core.js');
    await ffmpeg.load();
    setMessage('Start transcoding');
    ffmpeg.FS('writeFile', 'test.mp4', await fetchFile(vid));
    await ffmpeg.run('-i', 'test.mp4', 'output.mp4');
    setMessage('Complete transcoding');
    const data = ffmpeg.FS('readFile', 'output.mp4');
    setVideoSrc(URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' })));
  };
  return (
    <div className="App">
      <p/>
      <Input type="file" onChange={(e)=>{setVid(e.target.files[0])}}></Input>
      <video src={videoSrc} controls></video><br/>
      <button onClick={doTranscode}>Start</button>
      <p>{message}</p>
    </div>
  );
}
const Component = withFirebase(Watermark);
export default withRouter(Component);