import React, { useState, useEffect, useRef, useContext } from "react";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Flickity from "react-flickity-component";
import ProjectCard from "../../components/ProjectCard/ProjectCard";

import { withFirebase } from "../../firebase/index";
import {
  clearProjectState,
  createNewProject,
  getProjectList,
} from "../../actions/project";
import MainSideBar from "../../components/MainSideBar/main-side-bar";

// import firebase from 'firebase';

// firebase.firestore.Timestamp
// import MainSideBar from "../../components/MainSideBar/main-side-bar";
import {
  VideoEditorNavbar,
  VideoEditorMainSidebar,
  CreateNewVideoNameModel,
  AssetLoaderUI,
} from "../../components";
import "./VideoEditorMain.css";
import { Avatar, Typography } from "@material-ui/core";
import VideoEditorHome from "../VideoEditorHome/VideoEditorHome";
import GlobalState from "../../GlobalState/GlobalState";
import { Button } from "react-bootstrap";

const flickityOptions = {
  // initialIndex: 0,
  cellAlign: "left",
  contain: true,
  pageDots: false,
  selectedAttraction: 0.2,
  friction: 0.8,
};

const VideoEditorMain = ({ project, dispatch, history, firebase }) => {
  const [openNewVideoModel, setOpenNewVideoModel] = useState(false);
  const [createContentIndex, setCreateContentIndex] = useState(3);
  const [projectName, setProjectName] = useState("");
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState("");
  const [showSidebar, setShowsidebar] = useState(false);
  const [globalState, setGlobalState] = useContext(GlobalState);
  const [userName, setuserName] = useState("");
  const [photoUrl, setphotoUrl] = useState("");
  let userId = useRef(null);

  const handleCreateNewVideoModel = () => {
    setOpenNewVideoModel(true);
  };
  const handleCreateNewVideoButton = () => {
    if (projectName === "") return setErrors("Project name is required.");
    setOpenNewVideoModel(false);
    if (token) {
      dispatch(createNewProject(user.uid, token, projectName));
    }
  };

  useEffect(() => {
    dispatch(clearProjectState());
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        history.push("/login");
      } else {
        console.log("user = ", userAuth.providerData[0]);
        setuserName(userAuth.providerData[0].displayName);
        setphotoUrl(userAuth.providerData[0].photoURL);
        userId.current = userAuth.uid;
        userAuth.getIdToken(true).then(async (token) => {
          setToken(token);

          // fetch project List
          dispatch(getProjectList(userId.current, token));
        });
        setUser(userAuth);
      }
    });
  }, []);


  useEffect(() => {
    if (project.success) {
      history.push(`/main/${project.projectMeta.id}`);
      dispatch(clearProjectState());
      setErrors("");
    }
    if (project.error) {
      //   do something later
    }
    return () => {
      //   cleanup
    };
  }, [project.success]);

  // useEffect(() => {
  //   setGlobalState((state) => ({ ...state, showMainSidebar: showSidebar }));
  // }, [showSidebar]);

  // useEffect(() => {
  //   if (project.error) {
  //     //  do something
  //   }

  //   return () => {
  //     //   cleanup
  //   };
  // }, [project.projectListSuccess]);

  const handleCreateBtn = (index) => {
    setCreateContentIndex(index);
  };
  return (
    user && (
      <>
        <CreateNewVideoNameModel
          open={openNewVideoModel}
          setOpen={setOpenNewVideoModel}
          setProjectName={setProjectName}
          projectName={projectName}
          errors={errors}
          handleCreateNewVideoButton={handleCreateNewVideoButton}
        />
        <VideoEditorNavbar
          firebase={firebase}
          toggleSideBar={setShowsidebar}
          showSidebar={showSidebar}
        />
        {/* <MainSideBar
          handleCreateNewVideo={handleCreateNewVideoModel}
        /> */}
        {/* <MainSideBar
          handleCreateNewVideo={handleCreateNewVideoModel}
        /> */}
        <div id="VideoEditorMain">
          {/* <MainSideBar showSideBar/>  */}
          {globalState?.location === "home" ? (
            <>
              {/* <MainSideBar showSideBar /> */}
              <MainSideBar
                showSideBar
                handleCreateNewVideo={handleCreateNewVideoModel}
              />
              <div className="content">

                <div className="home-header">
                  {/* <div style{{display: inline-flex;
    flex-shrink: 1;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;}}></div> */}
                  <div style = {{display: "flex",alignItems: "center" ,overflow: "hidden",gap: "8px"}}>
                    <Avatar
                      alt="user"
                      src={photoUrl}
                      style={{ width: "30px", height: '30px', minWidth: '30px', minHeight: '30px', fontSize: '.7em' }}
                    />
                    <Typography style={{ color: "rgb(160, 174, 192)", fontSize: "16px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                      <>{userName}'s</>
                    </Typography>
                  </div>
                  <Typography variant="h3" style = {{fontSize: "40px",lineHeight: "72px",fontWeight: "400",color: "rgb(255, 255, 255)",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                    <b>Projects</b>
                    {/* <Button className="startbutton" size="sm" >New Project</Button> */}
                  </Typography>
                  
                </div>
                <div>
                  {project ? (
                    <div className="all-project-container">
                      {project.loading && <AssetLoaderUI />}
                      <Flickity
                        className={"carousel"} // default ''
                        elementType={"div"} // default 'div'
                        options={flickityOptions} // takes flickity options {}
                      // disableImagesLoaded={false} // default false
                      // reloadOnUpdate // default false/
                      // static // default false
                      >
                        {project?.projectList.map((pro) => {
                          let t = new Date(
                            firebase
                              .fromSecondsToTimestamp(
                                pro.timestamp._seconds + pro.timestamp._nanoseconds / 1000000000
                              )
                              .toDate()
                          ).toString();
                          return (
                            <ProjectCard
                              key={pro.id}
                              id={pro.id}
                              name={pro.name}
                              timestamp={t}
                              firebase={firebase}
                              history={history}
                            />
                          );
                        })}
                      </Flickity>
                    </div>
                  ) : (
                    <div className="createProject">
                      <div style={{ marginTop: "10%" }}>
                        <p style={{ color: "white", textAlign: "center" }}>
                          Start by creating a new project
                        </p>
                        <Button
                          style={{
                            color: "white",
                            textAlign: "center",
                            marginLeft: "43%",
                            padding: "8px",
                          }}
                          size="sm"
                        >
                          Create New Project
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </>

          ) : globalState?.location === "editor" ? (
            <VideoEditorHome
              toggleMainSideBar={setShowsidebar}
              showMainSidebar={false}
            />
          ) : (
            //         <div></div>
            //     )
            //       // </>

            // //        ) : globalState?.location === "editor" ? (
            // //          <VideoEditorHome
            // //            toggleMainSideBar={setShowsidebar}
            // //            showMainSidebar={showSidebar}
            // //          />
            // //        ) 
            // //      : (
            false
          )}
        </div>
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
});
export default connect(mapStateToProps)(withFirebase(VideoEditorMain));