export function FormMenu(input) {
  let finalarray = [];
  var res = input.slice().reverse();
  let obj = [];
  let newres = [];
  newres = res.map((inp) => {
    if (inp.name.level == 2) {
      obj.push(inp);
      return;
    }
    else if (inp.name.level == 1) {
      var tem = obj.slice().reverse();
      let ret = {
        id: inp.id,
        name: inp.name,
        subsubmenuslist: tem,
      }
      obj = [];
      return ret;
    }
    else {
      return inp;
    }
  })
  if (obj.length !== 0) {
    console.log("err1");
    console.log(obj);
    return [];
  }
  newres = newres.filter((e) => {
    return e !== undefined;
  })
  obj = [];
  // console.log(newres);
  res = [];
  res = newres.map((inp) => {
    if (inp.name.level == 1) {
      obj.push(inp);
    }
    else if (inp.name.level == 0) {
      var tem = obj.slice().reverse();
      let ret = {
        id: inp.id,
        name: inp.name,
        submenuslist: tem,
      }
      obj = [];
      return ret;
    }
  })
  if (obj.length !== 0) {
    console.log("err2");
    console.log(obj);
    return [];
  }
  res = res.filter((e) => {
    return e !== undefined;
  })
  res.reverse();
  // console.log(res);
  finalarray = res;
  // console.log("input3 = ", input)
  return finalarray
}