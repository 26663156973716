import React, { useEffect, useRef, useState } from 'react'
import { Input, Paper } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import { v4 as uuidv4 } from 'uuid';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from "../../firebase/index";
import { connect } from "react-redux";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {
  getNavbarEditsGobalList,
  getNavbarEditsList,
  AddNewDatatoNavbar,
  DeleteDataFromNavbar,
  ModifyDatatoNavbar,
} from "../../actions/NavbarEdits";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import "./MainBarSubmenu.css"



const MainBarSubmenu = ({ sublevel, inputFields, setInputFields, currid, userid, dispatch, history, firebase }) => {
  const func = () => {
    let res = [];
    if (sublevel.name.level == 0) {
      res.push({
        isdummy: false,
        idd: "0",
        cname: "real_submenu",
        data: sublevel,
      })
      res.push({
        isdummy: true,
        idd: "1",
        cname: "dummy_submenu"
      })
      res.push({
        isdummy: true,
        idd: "2",
        cname: "dummy_submenu"
      })
    }
    else if (sublevel.name.level == 1) {

      res.push({
        isdummy: true,
        idd: "1",
        cname: "dummy_submenu"
      })
      res.push({
        isdummy: false,
        idd: "0",
        cname: "real_submenu",
        data: sublevel,
      })
      res.push({
        isdummy: true,
        idd: "2",
        cname: "dummy_submenu"
      })
    }
    else {
      res.push({
        isdummy: true,
        idd: "1",
        cname: "dummy_submenu"
      })
      res.push({
        isdummy: true,
        idd: "2",
        cname: "dummy_submenu"
      })
      res.push({
        isdummy: false,
        idd: "0",
        cname: "real_submenu",
        data: sublevel,
      })
    }
    return res;
  }
  const [Inputarray, setInputarray] = useState(func());
  const [lvl,setlvl] = useState(sublevel.name.level);
  return (
    <div>
      <DragDropContext onDragEnd={(param) => {
        const srcI = param.source.index;
        const desI = param.destination?.index;
        let list = Inputarray;
        if (desI >= 0) {
          list.splice(desI, 0, list.splice(srcI, 1)[0]);
          setInputarray(list);
        }
        let levl = 0;
        Inputarray.map((id, index) => {
          if (id.isdummy === false) {
            levl = index;
          }
        });
        let red = inputFields;
        let res = red.map((e) => {
          if (e.id == currid) {
            let re = e;
            re.name.level = levl;
            return re;
          }
          else return e;
        })
        
        setInputFields(res);
        dispatch(
          ModifyDatatoNavbar(
            userid,
            currid,
            {
              id: currid,
              name: {
                Id: sublevel.name.Id,
                Link: sublevel.name.Link,
                Name: sublevel.name.Name,
                checkId: sublevel.name.checkId,
                level: levl,
                indexid: sublevel.name.indexid,
              }
            }
          )
        )
      }}>
        <Droppable droppableId="droppable-2" direction='horizontal' type='level'>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="Navbarsublevels">
              {
                Inputarray.map((item, ind) => (
                  <>
                    <Draggable key={item.idd} draggableId={"draggable-level-" + item.idd} index={ind}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} className={item.cname}>
                          {(item.isdummy === false) ?
                            <>
                              <div className='Navbaredits_rightsidebar_opt1'>
                                <div {...provided.dragHandleProps} ><DragHandleIcon /></div>
                                <div >{item.data.name.Name} </div>
                              </div>
                            </>
                            :
                            <div className='Navbaredits_rightsidebar_opt2'>
                              <div {...provided.dragHandleProps} ></div>
                            </div>
                          }
                        </div>
                      )}
                    </Draggable>
                  </>
                ))
              }
              {/* <div> link : {sublevel?.name.Link}</div> */}
              {/* <div> id : {sublevel?.id}</div> */}
              {/* </div> */}
              {/* <div><span>sub menus :</span></div>}
                            {
                              sublevel?.name?.submenus?.map(item => (
                                <div style={{ display: "flex", flexDirection: "column", color: "white", paddingLeft: "20px" }}>
                                  <div>sub mneu-title : {item.Name}</div>
                                  <div>sub menu-link : {item.link}</div>
                                </div>
                              ))
                            }*/}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

const mapStateToProps = (state) => ({
  NavbarEdits: state.NavbarEdits,
});
export default connect(mapStateToProps)(withFirebase(MainBarSubmenu));
