export const getThumbnailURL = (file) => {
  let src = URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      let ratio = img.naturalWidth / img.naturalHeight;
      canvas.height = 140;
      canvas.width = ratio * 140;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      dataURL = canvas.toDataURL("image/jpeg", 0.48);
      resolve(dataURL);
    };

    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  });
};

export const getVideoThumbnailURL = (file) => {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.onerror = (ex) => {
      reject("error when loading video file", ex);
    };

    // load metadata of the video to get video duration and dimensions
    videoPlayer.onloadedmetadata = () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration <= 0.0) {
        reject("video is too short.");
        return;
      }

      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = videoPlayer.duration / 2;
      }, 100);

      // extract video thumbnail once seeking is complete
      videoPlayer.onseeked = () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        let ratio = videoPlayer.videoWidth / videoPlayer.videoHeight;
        canvas.height = 140;
        canvas.width = ratio * 140;

        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

        // return the canvas image as base64 url
        let dataUrl = canvas.toDataURL("image/jpeg", 0.48);
        resolve({ url: dataUrl, duration: videoPlayer.duration });
      };
    };
  });
};

export const ellipsize = (str, limit) => {
  if (str.length > limit) {
    return `${str.slice(0, limit)}...`;
  }
  return str;
};
