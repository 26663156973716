import React, { useState, useEffect } from 'react'
import { withFirebase } from "../../firebase/index";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from "react-redux";
import {
  getNavbarEditsGobalList,
  getNavbarEditsList,
  AddNewDatatoNavbar,
  DeleteDataFromNavbar,
  ModifyDatatoNavbar,
} from "../../actions/NavbarEdits";
import { Input, Paper } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import { v4 as uuidv4 } from 'uuid';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import "./MainNavBarLeftCheckBox.css"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    // top: `${top}%`,
    // left: `${left}%`,
    // height: "400px",
    // width: "700px",
    // transform: `translate(-${top}%, -${left}%)`,
    // overflow: "scroll",
    // backgroundColor : "rgb(50,50,50)",
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      // margin: theme.spacing(1),
      // backgroundColor : "rgb(50,50,50)",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    // position: 'absolute',
    // width: "700px",
    // backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
}))


const MainNavBarLeftCheckBox = ({ NavbarEdits, dispatch, history, firebase, userid }) => {

  const classes = useStyles()
  const [check, setcheck] = useState(false);
  const [Level, setLevel] = useState(0);
  const [Index, setIndex] = useState(0);
  const [CurEditId, setCurEditId] = useState("");
  const [InputHeaderName, setInputHeaderName] = useState("");
  const [InputHeaderLink, setInputHeaderLink] = useState("");
  const [EditId, setEditId] = useState("");
  const [DeleteId, setDeleteId] = React.useState("");
  const [editopen, setEditOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [CurrentEditId, setCurrentEditId] = useState("");
  const [tera, settera] = useState(false);
  const [ListOpen, setListOpen] = useState(true);
  const [AddFeildsOpen, setAddFeildsOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [InputHeaderId, setInputHeaderID] = useState(uuidv4());





  useEffect(() => {
    if (DeleteId !== "") {
      console.log(DeleteId)
      dispatch(DeleteDataFromNavbar(userid, DeleteId));
      setDeleteId("");
    }
    return () => {
      // second
    }
  }, [DeleteId])


  useEffect(() => {

    if (tera) {
      dispatch(
        ModifyDatatoNavbar(
          userid,
          EditId,
          {
            id: EditId,
            name: {
              Id: CurEditId,
              Link: InputHeaderLink,
              Name: InputHeaderName,
              checkId: check,
              level: Level,
              indexid: Index,
            }
          }
        )
      )
    }
    return () => {
      settera(false);
    }
  }, [tera])

  const handleCheckBoxChange = (e) => {
    console.log("claaing change")
    const tempList = NavbarEdits.editsList;
    const result = tempList.filter(r => r.id === e);
    setIndex(result[0].name.indexid);
    setLevel(result[0].name.level);
    setCurEditId(result[0].name.Id);
    setInputHeaderName(result[0].name.Name);
    setInputHeaderLink(result[0].name.Link);
    setEditId(e);
    setcheck((result[0].name.checkId === true) ? false : true);
    settera(true);
  }

  const handleRemovesubmenu = id => {
    setDeleteId(id);
  }



  const handleEditSubmit = (e) => {
    e.preventDefault();
    const tempList = NavbarEdits.editsList;
    const result = tempList.filter(r => r.id === CurrentEditId);
    console.log(result);
    dispatch(
      ModifyDatatoNavbar(
        userid,
        CurrentEditId,
        {
          id: CurrentEditId,
          name: {
            Id: result[0].name.Id,
            Link: InputHeaderLink,
            Name: InputHeaderName,
            checkId: result[0].name.checkId,
            level: result[0].name.level,
            indexid: result[0].name.indexid,
          }
        }
      )
    )
    setEditOpen(false);
    // setOpen(false);
    setInputHeaderName("");
    setInputHeaderLink("");
  }

  const handleEditClose = () => {
    setEditOpen(false);
  }

  const handleEditOpen = () => {
    setEditOpen(true);
  };


  const handleEditsubmenu = (e) => {
    const tempList = NavbarEdits.editsList;
    const result = tempList.filter(r => r.id === e);
    setInputHeaderName(result[0].name.Name);
    setInputHeaderLink(result[0].name.Link);
    setEditOpen(!editopen);
    setCurrentEditId(e);
  }

  const OnHandleHeaderNameChange = (e) => {
    setInputHeaderName(e.target.value);
  }
  const OnHandleHeaderLinkChange = (e) => {
    setInputHeaderLink(e.target.value);
  }

  const HandletListOpen = (e) => {
    e.preventDefault();
    setListOpen(!ListOpen);
  }

  const HandletAddFeildsOpen = (e) => {
    e.preventDefault();
    setAddFeildsOpen(!AddFeildsOpen);
  }



  const handleSubmit = (e) => {
    e.preventDefault();
    setInputHeaderID(uuidv4());
    setOpen(false);
    // console.log(InputHeaderName);
    // console.log(InputHeaderLink);
    // console.log("InputFields", inputFields);
    // console.log(InputHeaderId);
    if (InputHeaderName !== "" && InputHeaderLink !== "") {
      dispatch(
        AddNewDatatoNavbar(userid, {
          Id: InputHeaderId,
          Name: InputHeaderName,
          Link: InputHeaderLink,
          checkId: false,
          level: 0,
          indexid: NavbarEdits?.editsList.length + 1,
        }
        )
      )
    }
    setInputHeaderName("");
    setInputHeaderLink("");
    setOpen(false)
    setEditOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };




  return (
    <>
      <div className="Navbaredits_leftsidebar_content">
        <div style={{ padding: "5px", color: "white", width: "100%", height: "7%" }}>FEILDS</div>
        <div style={{
          border: "none", backgroundColor: "rgb(50,50,50)", color: "white", width: "100%", height: "7%",
          display: "flex", flexDirection: "row",
          justifyContent: "space-between", alignItems: "center",
          margin: "2px",
          padding: "2px"
        }}>
          <div style={{ padding: "5px" }}>ADD FEILDS</div>
          <button style={{ border: "none", backgroundColor: "rgb(50,50,50)", color: "white" }} onClick={HandletAddFeildsOpen}>{AddFeildsOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}</button>
        </div>
        {AddFeildsOpen &&
          <div style={modalStyle} className={classes.paper}>
            <form className={classes.root} onSubmit={handleSubmit}>
              <TextField
                name="Name"
                label="Name"
                variant="filled"
                value={InputHeaderName}
                onChange={OnHandleHeaderNameChange}
                className="ModifyFeildInput"
              />
              <TextField
                name="link"
                label="Link"
                variant="filled"
                value={InputHeaderLink}
                onChange={OnHandleHeaderLinkChange}
                className="ModifyFeildInput"
              />

              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                endIcon={<Icon>send</Icon>}
                onClick={handleSubmit}
              >ADD FEILD</Button>
            </form>
          </div>}

        <div style={{
          border: "none", backgroundColor: "rgb(50,50,50)", color: "white", width: "100%", height: "7%",
          display: "flex", flexDirection: "row",
          justifyContent: "space-between", alignItems: "center", margin: "2px",
          padding: "2px"
        }}>
          <div style={{ padding: "5px" }} >FEILD</div>
          <button style={{ border: "none", backgroundColor: "rgb(50,50,50)", color: "white" }} onClick={HandletListOpen}>{ListOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}</button>
        </div>
        {
          ListOpen && <div className='FeildsListName'>
            {
              ListOpen && NavbarEdits?.editsList?.map((items, i) => (
                <div className="NavBarEditsName">
                  <div className='Navbaredits_leftsidebar_name' >
                    <div className="form-check" key={i}>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name={items?.name.Name}
                        checked={items?.name?.checkId}
                        onChange={() => handleCheckBoxChange(items.id)}
                      />
                      <label className="form-check-label ms-2">{items?.name.Name}</label>
                    </div>
                    <div>
                      <button style={{ border: "none", backgroundColor: "rgb(50,50,50)", color: "white" }} onClick={() => handleEditsubmenu(items.id)}><EditIcon /></button>
                      <button style={{ border: "none", backgroundColor: "rgb(50,50,50)", color: "white" }} onClick={() => handleRemovesubmenu(items.id)}><DeleteIcon /></button>
                    </div>
                  </div>
                  {editopen && CurrentEditId === items.id && <div style={modalStyle} className={classes.paper}>
                    <form className={classes.root} onSubmit={handleEditSubmit}>
                      <TextField
                        name="Name"
                        label="Name"
                        variant="filled"
                        value={InputHeaderName}
                        onChange={OnHandleHeaderNameChange}
                        className="ModifyFeildInput"
                      />
                      <TextField
                        name="link"
                        label="Link"
                        variant="filled"
                        value={InputHeaderLink}
                        onChange={OnHandleHeaderLinkChange}
                        className="ModifyFeildInput"
                      />
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={<Icon>send</Icon>}
                        onClick={handleEditSubmit}
                      >MODIFY FEILD</Button>
                    </form>
                  </div>}

                </div>
              ))
            }
          </div>
        }
      </div>

      {/* <Modal
        open={editopen}
        onClose={handleEditClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >

      </Modal> */}
    </>
  )
}
const mapStateToProps = (state) => ({
  NavbarEdits: state.NavbarEdits,
});
export default connect(mapStateToProps)(withFirebase(MainNavBarLeftCheckBox));