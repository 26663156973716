import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./Utils/PrivateRoute/PrivateRoute";
import Page404 from "./Pages/Page404";
import {
  Home,
  UserSignIn,
  UserSignUp,
  ForgotPassword,
  VideoEditorHome,
  VideoEditorMain,
  Editor,
  Admin,
} from "./Pages";
import Watermark from "./components/Watermark";

const Main = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={(props) => <UserSignIn {...props} />} />
        <PrivateRoute
          exact
          path="/editor"
          component={(props) => <VideoEditorMain {...props} />}
        />
        <PrivateRoute
          exact
          path="/admin"
          component={(props) => <Admin {...props} />}
        />
        <PrivateRoute
          exact
          path="/main/:projectId"
          component={(props) => <VideoEditorHome {...props} />}
        />

        <PrivateRoute
          exact
          path="/editor"
          component={(props) => <Editor {...props} />}
        />

        <Route
          path="/watermark"
          component={(props) => <Watermark {...props} />}
        />

        <Route
          path="/sign-up"
          component={(props) => <UserSignUp {...props} />}
        />
        <Route
          path="/forgot-password"
          component={(props) => <ForgotPassword {...props} />}
        />
        <Route path="/" component={(props) => <Home />} />
        <Route component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Main;
