import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { VideoEditorTimelineFrame, VideoEditorTimelineRuler } from "../";
import { assetDownloadUrl } from "../../actions/asset";
import { SECONDS_LENGTH } from "../../Utils/seconds-sizes";
import { withFirebase } from "../../firebase/index";
import Draggable from "react-draggable";
import "./VideoEditorTimelineFrames.css";
const VideoEditorTimelineFrames = ({
  audioTracksList,
  setAudioTracksList,
  videoTracksList,
  setVideoTracksList,
  seconds,
  frames,
  firebase,
  updateFrames,
  dispatch,
  asset,
}) =>
{
  const [draggable, setDraggable] = useState(false);
  const [space, setSpace] = useState(false);
  const [timelineImageUrls, setTimelineImageUrls] = useState([]);

  var XPos = 0;
  const timer = (ms) => new Promise((res) => setTimeout(res, ms));
  const handleFrameDragStart = (e, layerKey, frameIndex, fileIndex) =>
  {
    const clientX = e.clientX;
    setDraggable({
      currentLayer: layerKey,
      currentElementIndex: frameIndex,
      currentFileIndex: fileIndex,
      startX: clientX,
    });
  };

  const handleFrameDraggin = (e) =>
  {
    if (!draggable) return;
    const currentClientX = e.clientX;
    const moveMouseX = currentClientX - draggable.startX;
    //TODO: move elements by 10?
    // if ((Math.abs(moveMouseX)%10)!==0) return;
    const index = draggable.currentElementIndex;
    const fileIndex = draggable.currentFileIndex;
    const layerKey = draggable.currentLayer;
    var framesl = videoTracksList;

    // bug:  if same media occur twice
    framesl[index]?.files.map((file) =>
    {
      if (Object.values(file).includes(layerKey))
      {
        file.second = file.second + moveMouseX;
      }
    });
    // console.log(layerKey);
    // framesl[index].files[fileIndex].second =
    //   framesl[index].files[fileIndex].second + moveMouseX;
    // framesl[layerKey][index].second =
    //   framesl[layerKey][index].second + moveMouseX;
    setVideoTracksList(framesl);
    setDraggable({
      currentLayer: layerKey,
      currentElementIndex: index,
      startX: currentClientX,
    });
    updateFrames(framesl);
  };

  const handleFrameDragEnd = () =>
  {
    setDraggable(draggable);
  };

  // Handling videoDrop
  const onDrop = async (e) =>
  {
    e.persist();
    e.preventDefault();
    e.stopPropagation();

    let stockImage = JSON.parse(localStorage.getItem("imagekey"));
    // localStorage.removeItem("imagekey");
    let stockVid = JSON.parse(localStorage.getItem("videokey"));
    // localStorage.removeItem("videokey");
    // console.log("StockVideo", stockVid);
    if (stockImage)
    {
      let fileObj = {
        mimeType: "image",
        thumbnailUrl: stockImage.url,
        second: e.clientX - 200,
        duration: 100,
      };
      console.log("Image");
      let newV = [...videoTracksList];
      await newV.map((track, index) =>
      {
        return track.files.push(fileObj);
      });
      await setVideoTracksList(newV);
    } else 
    {
      console.log(stockVid, "hello");
      if (stockVid)
      {
        let fileObj = {
          mimeType: "video",
          thumbnailUrl: stockVid.thumbnail,
          second: e.clientX - 700,
          duration: stockVid.duration,
          url: stockVid.url,
        };
        console.log(fileObj);
        let newV = [...videoTracksList];
        console.log(newV);
        await newV.map((track, index) =>
        {
          // if (index === parseInt(e.target.id))
          return track.files.push(fileObj);
        });
        await setVideoTracksList(newV);
      }
    }

    // else
    // {
    //   let data = JSON.parse(e.dataTransfer.getData("data"));
    //   console.log(data);
    //   const TimelineUrl = () =>
    //     dispatch(async () =>
    //       assetDownloadUrl(
    //         data.uid,
    //         data.token,
    //         data.assetId,
    //         data.fileName,
    //         data.cancel
    //       )
    //     );
    //   TimelineUrl().then(
    //     setTimelineImageUrls([...timelineImageUrls, asset.downloadUrl])
    //   );

    //   //console.log(e.dataTransfer.getData("data"))
    //   // let cancelToken = JSON.parse(e.dataTransfer.getData("cancel"));

    //   // await dispatch(
    //   //   assetDownloadUrl(
    //   //     data.uid,
    //   //     data.token,
    //   //     data.assetId,
    //   //     data.fileName,
    //   //     data.thumbnailUrl,
    //   //     cancelToken
    //   //   )
    //   // );
    //   // const onDragOver = (e) => {
    //   //   e.preventDefault();
    //   //   e.dataTransfer.dropEffect = "move";
    //   // };
    //   console.log("data");
    //   if (data)
    //   {
    //     console.log("url", asset.downloadUrl);
    //     /* setTimelineImageUrls([...timelineImageUrls, asset.downloadUrl ]) */
    //     console.log("dataaa ", data);
    //     console.log("urlsss ", timelineImageUrls);

    //     localStorage.setItem("urls ", timelineImageUrls);
    //     localStorage.setItem("data", JSON.stringify(data));
    //     let fileObject = {
    //       // assetURL: asset.downloadUrl,
    //       mimeType: data.mimeType,
    //       assetId: data.assetId,
    //       thumbnailUrl: data.thumbnailUrl,
    //       second: e.clientX - 200, //subtracting sidebar width
    //       duration: data.duration || 160,
    //       url: asset.downloadUrl,
    //     };

    //     if (
    //       data.mimeType.includes("video") ||
    //       data.mimeType.includes("image")
    //     )
    //     {
    //       let newV = [...videoTracksList];
    //       await newV.map((track, index) =>
    //       {
    //         return track.files.push(fileObject);
    //       });
    //       await setVideoTracksList(newV);
    //     } else
    //     {
    //       let newV = [...audioTracksList];
    //       await newV.map((track, index) =>
    //       {
    //         if (index === parseInt(e.target.id))
    //           return track.files.push(fileObject);
    //       });
    //       await setAudioTracksList(newV);
    //     }
    //   }
    //   // let data = JSON.parse(e.dataTransfer.getData("data"));
    //   // console.log(data)
    //   //console.log(e.dataTransfer.getData("data"))
    //   // let cancelToken = JSON.parse(e.dataTransfer.getData("cancel"));
    //   // await dispatch(
    //   //   assetDownloadUrl(
    //   //     data.uid,
    //   //     data.token,
    //   //     data.assetId,
    //   //     data.fileName,
    //   //     data.thumbnailUrl,
    //   //     cancelToken
    //   //   )
    //   // );
    //   // if (data) {
    //   //   let fileObject = {
    //   //     // assetURL: asset.downloadUrl,
    //   //     mimeType: data.mimeType,
    //   //     assetId: data.assetId,
    //   //     thumbnailUrl: data.thumbnailUrl,
    //   //     second: e.clientX - 200, //subtracting sidebar width
    //   //     duration: data.duration || 160,
    //   //   };
    //   //   if (
    //   //     data.mimeType.includes("video") ||
    //   //     data.mimeType.includes("image")
    //   //   ) {
    //   //     let newV = [...videoTracksList];
    //   //     await newV.map((track, index) => {
    //   //       return track.files.push(fileObject);
    //   //     });
    //   //     await setVideoTracksList(newV);
    //   //   } else {
    //   //     let newV = [...audioTracksList];
    //   //     await newV.map((track, index) => {
    //   //       if (index === parseInt(e.target.id))
    //   //         return track.files.push(fileObject);
    //   //     });
    //   //     await setAudioTracksList(newV);
    //   //   }
    //   // }
    // }

  };
  // const onDrop = async (e) =>
  // {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   let media = e.dataTransfer.getData("media");
  //   let mediaType = e.dataTransfer.getData("mediaType");
  //   if (mediaType.includes("video") || mediaType.includes("image"))
  //   {
  //     let newV = [...videoTracksList];
  //     await newV.map((track, index) =>
  //     {
  //       if (index === parseInt(e.target.id)) return track.files.push(media);
  //     });
  //     await setVideoTracksList(newV);
  //   } else
  //   {
  //     let newV = [...audioTracksList];
  //     await newV.map((track, index) =>
  //     {
  //       if (index === parseInt(e.target.id)) return track.files.push(media);
  //     });
  //     await setAudioTracksList(newV);
  //   }
  // };
  const onDragOver = (e) =>
  {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  // const handlePlay = () => {
  //   document.querySelector(".timeline-editor__frames").addEventListener("keypress", (e) => {
  //     if (e.keyCode === 32 || e.keyCode === ' ') {
  //       if (space === false){
  //         setSpace(true);
  //       }
  //       else
  //         setSpace(false);
  //       }
  //       console.log(space);
  //   });
  // };

  const handlePlay = () =>
  {
    var pointer = document.querySelector(".pointer-div");
    var timeline = document.querySelector(".timeline-editor__frames-ruler");
    timeline.addEventListener("click", getClickedPosition, false);

    function getClickedPosition(e)
    {
      XPos = e.clientX - 110;
      let translateValue = "translate(" + XPos + "px)";
      pointer.style.transform = translateValue;
    }
    window.addEventListener("keydown", async function (event)
    {
      var code = event.keyCode || event.which;
      if (code === 32)
      {
        if (space === false)
        {
          console.log(space);
          var check = 0;
          for (let i = 1; i >= 0; i++)
          {
            let pos = XPos + 2;
            let translateValue = "translate(" + pos + "px)";
            pointer.style.transform = translateValue;
            XPos = pos;
            await timer(100);
            window.addEventListener("keydown", async function (event)
            {
              if (event.keyCode === 77) check = 1;
            });
            if (check === 1) break;
          }
        }
      }
      if (event.shiftKey && event.keyCode == 37)
      {
        let translateValue = "translate(" + 0 + "px)";
        pointer.style.transform = translateValue;
        XPos = 0;
      }
    });
  };

  return (
    <div
      className="timeline-editor__frames"
      onMouseUp={() => handleFrameDragEnd()}
      onLoad={handlePlay}
    >

      {" "}
      {/* <div className="pointer-div">
        <img
          alt="dragged"
          style={{ width: 20, height: 20, marginTop: -5 }}
          src="https://cdn2.iconfinder.com/data/icons/30-round-media-remote-control-buttons-black/100/Channel_Down-512.png"
        />
        <div className="pointer" style={{ marginTop: -5 }}></div>
      </div> */}

      <div className="timeline">

        <VideoEditorTimelineRuler seconds={seconds} />

        {videoTracksList.map((layer, index) => (

          <div className="timeline-editor__frames-container" key={index}>

            <div
              className="timeline-editor__frames-layer"
              style={{ width: seconds * SECONDS_LENGTH }}
              key={index}
              id={index}
              onMouseMove={(e) => handleFrameDraggin(e)}
              onDrop={(e) => onDrop(e)}
              onDragOver={(e) => onDragOver(e)}
            >


              {layer?.files.map((frame, index) => (

                <VideoEditorTimelineFrame
                  trackIndex={index}
                  key={index}
                  index={index}
                  frame={frame}
                  layerKey={frame.assetId}
                  dragEvent={handleFrameDragStart}

                />

              ))}

            </div>
          </div>

        ))}

        {audioTracksList.map((layer, ind) => (
          <div className="timeline-editor__frames-container" key={ind}>
            <div
              className="timeline-editor__frames-layer"
              style={{ width: seconds * SECONDS_LENGTH }}
              onMouseMove={(e) => handleFrameDraggin(e)}
            >
              {layer?.files.map((frame, index) => (
                <VideoEditorTimelineFrame
                  key={index}
                  index={index}
                  frame={frame}
                  layerKey={frame.assetId}
                  dragEvent={handleFrameDragStart}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div >
  );
};
const mapStateToProps = (state) => ({
  asset: state.asset,
  url: state.asset.downloadUrl,
});
export default connect(mapStateToProps)(VideoEditorTimelineFrames);
