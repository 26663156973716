import React, { useState, useEffect, useRef, useContext } from "react";
import { VideoEditorNavbar, AdminPanelSideBar } from '../../components/index.js'
import Menu from "../Menu/Menu.js"
import { connect } from "react-redux";
import { withFirebase } from "../../firebase/index";


// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
// const ffmpeg = createFFmpeg({ log: true });


const Admin = ({ dispatch, history, firebase }) => {
  const [displayAdmin, setdisplayAdmin] = useState(false);
  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        history.push("/login");
      } else {
        console.log("user = ", userAuth.providerData[0]);
        if (!(userAuth.providerData[0].email === "tech@explified.com"
          || userAuth.providerData[0].email === "jasoria.kushal@gmail.com"
        )) {
          alert("access denied!");
          history.push("/login");
          setdisplayAdmin(false);
        }
        else {
          setdisplayAdmin(true);
        }
      }
    });
  }, []);

  // const [ready, setReady] = useState(false);
  // const [video, setVideo] = useState();
  // const [gif, setGif] = useState();

  // const load = async () => {
  //   await ffmpeg.load();
  //   setReady(true);
  // }

  // useEffect(() => {
  //   load();
  // }, [])

  // const convertToGif = async () => {
  //   ffmpeg.FS('writeFile', 'test.mp4', await fetchFile(video));
  //   await ffmpeg.run('-i', 'test.mp4', '-t', '2.5', '-ss', '2.0', '-f', 'gif', 'out.gif');
  //   const data = ffmpeg.FS('readFile', 'out.gif');
  //   const url = URL.createObjectURL(new Blob([data.buffer], { type: 'image/gif' }));
  //   setGif(url)
  // }


  return (
    <>
      {displayAdmin &&
        <div style={{ height: "100%", width: "100%" }}>
          <VideoEditorNavbar
            firebase={firebase}
            toggleSideBar={false}
            showSidebar={false}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <AdminPanelSideBar />
            <Menu />
          </div>
        </div>
      }
      {/* ready ?
      (<div className="App">
        {video && <video
          controls
          width="250"
          src={URL.createObjectURL(video)}>

        </video>}


        <input type="file" onChange={(e) => setVideo(e.target.files?.item(0))} />

        <h3>Result</h3>

        <button onClick={convertToGif}>Convert</button>

        {gif && <img src={gif} width="250" />}

      </div>)
      :(<p>Loading...</p>) */}
    </>
  )
}

export default withFirebase(Admin);