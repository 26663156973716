import React, { useRef } from "react";
import "./LowerHalf.css";

const LowerHalf = ({ children, sideBarOpen }) => {
  return (
    <div
      className="lower"
      style={
        sideBarOpen
          ? {
              // width:
              //   sideBarOpen === 1
              //     ? window.innerWidth - 64
              //     : window.innerWidth - (64 + 380),
            }
          : {}
      }
    >
      {children}
    </div>
  );
};

export default LowerHalf;
